import axios, { AxiosInstance } from 'axios';
import { EventManager } from '../Plugin';
import { EventType } from '../PluginTypes';
import { CloudIntegrationLogicMapper } from './logic-mapper.service';

/* eslint-disable camelcase */
export enum CLOUD_INTEGRATIONS {
  ENODE = 3,
  SHELLY = 4,
}
export interface CiDeviceContainerCreate {
  project_id: string;
  name: string;
  description: string;
  cint_type: CLOUD_INTEGRATIONS;
}

export interface CiDeviceCollectionList {
  project_id: string;
  limit: number;
  skip: number;
}

export interface CiDeviceContainerCreateResponse {
  result: {
    cint_status: number;
    cint_type: number;
    created_at: string;
    description: string;
    id: string;
    name: string;
    project_id: string;
    updated_at: string;
  };
  status: string;
}

export interface CiDeviceContainerInfoResponse {
  result: {
    id: string;
    created_by: any;
    project_id: string;
    device_cluster: {
      clusters?: {
        [key: string]: number;
      };
    };
    name: string;
    description: string;
    cint_type: string;
    cint_status: number;
    created_at: string;
    updated_at: string;
    ext_username: string;
  };
  status: string;
}

export interface CiDeviceContainer {
  cint_status: number;
  cint_type: string;
  created_at: string;
  created_by: string;
  description: string;
  device_cluster: unknown;
  id: string;
  name: string;
  project_id: string;
  updated_at: string;
}

export interface CiDeviceCollectionListResponse {
  result: {
    limit: number;
    resultCount: number;
    skip: number;
    result: CiDeviceContainer[];
  };
  status: string;
}

export interface CiDevice {
  cat: string;
  cint_coll: string;
  cint_device_id: string;
  cint_type: CLOUD_INTEGRATIONS;
  created_at: string;
  dc_name: string;
  device_status: number;
  device_type: string;
  id: string;
  name: string;
  project_id: string;
  settings: any;
  sub_cat: string;
  system_tags: string[];
  tags: string[];
  updated_at: string;
}

export interface CiGetDeviceCollectionDeviceResponse {
  result: {
    devices: CiDevice[];
  };
  status: string;
}

export interface LocationEnergyMonitoringInfo {
  _id: string;
  project_id: string;
  supplier_code: string;
  supplier_type: string;
  daily_fixed_price: number;
  peak_price: number;
  off_peak_price: number;
  night_price: number;
  start_at: number;
  __v: number;
}

export interface LocationEnergyStatResponose {
  status: string;
  result: {
    inputs: {
      start_date: number;
      end_date: number;
      resolution: string;
      state_field: string;
    };
    result: {
      total_consumption: number;
      total_consumption_cost: number;
      total_consumption_carbon: number;
      total_stat: {
        [key: string]: {
          consumption: number;
          cost: number;
          carbon_v1: number;
        };
      };
      used_days: number;
      fixed_charge_for_used_days: number;
      reading_devices: {
        consumption: number;
        cost: number;
        device_id: string;
        info: {
          cat: string;
          cint_coll: string;
          cint_type: string;
          device_type: string;
          id: string;
          name: string;
          sub_cat: string;
        };
        fields: {
          [channel: string]: {
            state_field: string;
            consumption: number;
            cost: number;
            pop: {
              [range: string]: {
                consumption: number;
                cost: number;
              };
            };
          };
        };
        pop: {
          [range: string]: {
            consumption: number;
            cost: number;
          };
        };
      }[];
      tota_consumption_graph: {
        x: number;
        y: {
          [range: string]: {
            cost: number;
            value: number;
            carbon_v1: number;
          };
        };
      }[];
      pricing: {
        daily_fixed_price: number;
        night_price: number;
        off_peak_price: number;
        peak_price: number;
        project_timezone: string;
        supplier_code: string;
        supplier_type: string;
      };
    };
  };
}


export interface LocationEnergyDiffStatResponose {
  status: string;
  result: {
    total_consumption: {
      a: number,
      b: number,
      value: number,
    };
    total_consumption_cost: {
      a: number,
      b: number,
      value: number,
    };
    total_stat: {
      [key: string]: {
        consumption: {
          a: number,
          b: number,
          value: number,
        };
        cost: {
          a: number,
          b: number,
          value: number,
        };
      };
    };
    used_days: number;
    fixed_charge_for_used_days: number;
    reading_devices: {
      consumption: number;
      cost: number;
      device_id: string;
      info: {
        cat: string;
        cint_coll: string;
        cint_type: string;
        device_type: string;
        id: string;
        name: string;
        sub_cat: string;
      };
      fields: {
        [channel: string]: {
          state_field: string;
          consumption: number;
          cost: number;
          pop: {
            [range: string]: {
              consumption: number;
              cost: number;
            };
          };
        };
      };
      pop: {
        [range: string]: {
          consumption: number;
          cost: number;
        };
      };
    }[];
    tota_consumption_graph: {
      x: number;
      y: {
        [range: string]: {
          cost: number;
          value: number;
          values: {
            a: number,
            b: number,
          }
        };
      };
    }[];
  };
}


export interface LocationAllDevicesEnergyStatResponose {
  status: string;
  result: {
    inputs: {
      start_date: number;
      end_date: number;
      resolution: string;
      state_field: string;
    };
    result: {
      device_vis_usage: {
        consumption: number;
        cost: number;
        device_id: string;
        info: {
          cat: string;
          cint_coll: string;
          cint_type: string;
          device_type: string;
          id: string;
          name: string;
          sub_cat: string;
        };
        fields: {
          [channel: string]: {
            state_field: string;
            consumption: number;
            cost: number;
            pop: {
              [range: string]: {
                consumption: number;
                cost: number;
              };
            };
          };
        };
        pop: {
          [range: string]: {
            consumption: number;
            cost: number;
          };
        };
      }[];
    };
  };
}

export interface CloudDeviceState {
  status: string
  result?: {
    state: {
      cat: string
      sub_cat: string
      name: string
      cint_device_id: string
      device_id: string
      mapped: {
        [channelKey: string]: string | number | boolean | null
      }
      state_from_device: any
    }
  }
};

export interface EnergyGroup {
  _id: string;
  project_id: string;
  name: string;
  description: string;
  group_type: string;
  updated_at: number;
  created_at: number;
  __v: number;
}

export interface ChannelInEnergyGroup {
  name: string;
  device_type: string;
  cat: string;
  sub_cat: string;
  device_id: string;
  state_field: string;
  created_at: number;
}

export interface EnergyGroupResponse {
  status: 'string';
  result: {
    group: EnergyGroup;
    channels: ChannelInEnergyGroup[];
  };
}
export interface EnergyGroupListResponse {
  status: 'string';
  result: EnergyGroup[];
}

export interface EneryGroupAddChannelToGroupResponse {
  result: {
    project_id: string;
    group_id: string;
    device_id: string;
    state_field: string;
    _id: string;
    created_at: number;
    __v: number;
  };
  status: string;
}

const r = new FinalizationRegistry((cleanup: () => void) => {
  cleanup();
});

class CloudIntegration {
  axiosClient: AxiosInstance;

  protected logicMapper: CloudIntegrationLogicMapper = new CloudIntegrationLogicMapper();

  constructor(client: AxiosInstance, eventManager: EventManager) {
    this.axiosClient = client;

    const unsubscribe = eventManager.subscribe(EventType.ON_NEW_AUTH_TOKEN, (token) => {
      this.axiosClient = axios.create({
        baseURL: `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]}/rest/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    });

    // Unsubscribe when garbage collected
    r.register(this, unsubscribe);
  }

  public get LogicMapper() {
    return this.logicMapper;
  }

  public async createDeviceCollection(payload: CiDeviceContainerCreate) {
    const res = await this.axiosClient.post('/cloud-int/dc/create', payload);
    return res.data as CiDeviceContainerCreateResponse;
  }

  public async updateDeviceCollection(
    dcId: string,
    payload: {
      name: string;
      description: string;
      cint_status: number;
    },
  ) {
    const res = await this.axiosClient.post('/cloud-int/dc/update', {
      dc_id: dcId,
      name: payload.name,
      description: payload.description,
      cint_status: payload.cint_status,
    });
    return res.data;
  }

  public async getCloudDeviceCollections(payload: CiDeviceCollectionList) {
    const res = await this.axiosClient.post('/cloud-int/dc/list', payload);
    return res.data as CiDeviceCollectionListResponse;
  }

  public async getCloudDeviceCollection(dcId: string) {
    const res = await this.axiosClient.post('/cloud-int/dc/info', {
      dc_id: dcId,
    });
    return res.data as CiDeviceContainerInfoResponse;
  }

  public async getCloudDeviceCollectionDevices(dcId: string) {
    const res = await this.axiosClient.post('/cloud-int/dc/list-devices', {
      dc_id: dcId,
      with_external_data: false,
    });
    return res.data as CiGetDeviceCollectionDeviceResponse;
  }

  public async getCloudDevice(deviceId: string) {
    const res = await this.axiosClient.post('/cloud-int/device/info', {
      device_id: deviceId,
    });
    return res.data as {
      result: {
        device: CiDevice;
      };
      status: string;
    };
  }

  public async getCloudDeviceState(deviceId: string) {
    const res = await this.axiosClient.get(`/cloud-int/event/state?device_id=${deviceId}`);
    return res.data as CloudDeviceState
  }

  public async editCloudDevice(deviceId: string, setting: any) {
    const res = await this.axiosClient.post('/cloud-int/device/edit', {
      device_id: deviceId,
      settings: setting,
    });
    return res.data as any;
  }

  public async editCloudDeviceName(deviceId: string, name: string, settings: any) {
    const res = await this.axiosClient.post('/cloud-int/device/edit', {
      device_id: deviceId,
      name,
      settings, // Uncessary but API requires it
    });
    return res.data as any;
  }

  public async deleteCloudDeviceCollection(dcId: string) {
    const res = await this.axiosClient.post('/cloud-int/dc/delete', {
      dc_id: dcId,
    });
    return res.data;
  }

  public async resetDeviceSttingsToDefaults(deviceId: string) {
    const res = await this.axiosClient.post('/cloud-int/device/update-settings-to-defaults', {
      device_id: deviceId,
    });
    return res.data;
  }

  /**
   * NOTE: This will only remove the device from smartlife side. Device will not remove from integrators side.
   * @param deviceId
   * @returns
   */
  public async forceDeleteDeviceRecord(deviceId: string) {
    const res = await this.axiosClient.post('/cloud-int/device/delete', {
      device_id: deviceId,
    });
    return res.data;
  }

  public async resetDeviceEnergyRecords(deviceId: string) {
    const res = await this.axiosClient.post('/cloud-int/device/remove-energy-records', {
      device_id: deviceId,
    });
    return res.data;
  }

  public async updateEnergyProvider(payload: {
    projectId: string;
    supplierCode: string;
    dailyFixedPrice: number;
    peekPrice: number;
    offPeakPrice?: number;
    nightPrice?: number;
  }) {
    const res = await this.axiosClient.post('/cloud-int/monitoring/v1/config/energy-supplier/set', {
      daily_fixed_price: payload.dailyFixedPrice,
      peak_price: payload.peekPrice,
      off_peak_price: payload.offPeakPrice,
      night_price: payload.nightPrice,
      supplier_code: payload.supplierCode,
      project_id: payload.projectId,
    });
  }

  public async getLocationEnergyStats(payload: {
    projectId: string;
    startTime: number;
    endTime: number;
    selectedGroup: string;
    resolution: string;
  }) {
    const res = await this.axiosClient.post('/cloud-int/monitoring/v1/stat3', {
      project_id: payload.projectId,
      date_start: payload.startTime,
      date_end: payload.endTime,
      resolution: payload.resolution,
      group_id: payload.selectedGroup,
    });

    return res.data as LocationEnergyStatResponose;
  }

  public async getLocationAllDevicesEnergyStats(payload: {
    projectId: string;
    startTime: number;
    endTime: number;
  }) {
    const res = await this.axiosClient.post('/cloud-int/monitoring/v1/stat5', {
      project_id: payload.projectId,
      date_start: payload.startTime,
      date_end: payload.endTime,
    });

    return res.data as LocationAllDevicesEnergyStatResponose;
  }

  public async getLocationEnergyDiffStats(payload: {
    projectId: string;
    startTime: number;
    endTime: number;
    resolution: string;
    group1: string;
    group2: string[];
  }) {
    const res = await this.axiosClient.post('/cloud-int/monitoring/v1/stat6', {
      project_id: payload.projectId,
      date_start: payload.startTime,
      date_end: payload.endTime,
      resolution: payload.resolution,
      main_group_id: payload.group1,
      substract_group_ids: payload.group2
    });

    return res.data as LocationEnergyDiffStatResponose;
  }


  public async getLocationEnergyProvider(projectId: string) {
    try {
      const res = await this.axiosClient.post(
        '/cloud-int/monitoring/v1/config/energy-supplier/get',
        {
          project_id: projectId,
        },
      );
      return res.data as {
        result: {
          changed_history: LocationEnergyMonitoringInfo[];
          info: LocationEnergyMonitoringInfo;
        };
        status: string;
      };
    } catch (err) {}
  }

  public async listEnergyGroups(projectId: string) {
    const res = await this.axiosClient.post('/cloud-int/monitoring/v1/groups/list', {
      project_id: projectId,
    });
    return res.data as EnergyGroupListResponse;
  }

  public async getEnergyGroup(groupId: string) {
    const res = await this.axiosClient.post('/cloud-int/monitoring/v1/groups/get-group', {
      group_id: groupId,
    });
    return res.data as EnergyGroupResponse;
  }

  public async createEnergyGroup(payload: {
    name: string;
    description: string;
    project_id: string;
    group_type: 'dg' | 'sg';
  }) {
    const res = await this.axiosClient.post('/cloud-int/monitoring/v1/groups/create', payload);
    return res.data;
  }

  public async updateEnergyGroup(payload: {
    name: string;
    description: string;
    group_id: string;
    group_type: 'dg' | 'sg';
  }) {
    const res = await this.axiosClient.post('/cloud-int/monitoring/v1/groups/update', payload);
    return res.data;
  }

  public async removeEnergyGroup(groupId: string) {
    const res = await this.axiosClient.post('/cloud-int/monitoring/v1/groups/delete', {
      group_id: groupId,
    });
    return res.data;
  }

  public async addChannelToEnergyGroup(payload: {
    group_id: string;
    device_id: string;
    state_field: string;
  }) {
    const res = await this.axiosClient.post('/cloud-int/monitoring/v1/groups/add-channel', payload);
    return res.data as EneryGroupAddChannelToGroupResponse;
  }

  public async removeChannelFromEnergyGroup(payload: {
    group_id: string;
    device_id: string;
    state_field: string;
  }) {
    const res = await this.axiosClient.post(
      '/cloud-int/monitoring/v1/groups/remove-channel',
      payload,
    );
    return res.data;
  }

  public getCloudIntegrationNameByType(type: number | string) {
    if (type === 4 || type === '4') {
      return 'Smartlife Shelly Integration';
    }
    if (type === 3 || type === '3') {
      return 'Smartlife Enode Integration';
    }
  }

  public getDeviceUvFieldSettings(deviceType?: string) {
    // This is the general setting.
    // TODO: implement inherited method in each specific integrations to get integraiton specific device uv settings
    return {
      fields: [
        { type: 'rule_name', default: '' },
        { type: 'macro_name', default: '' },
        { type: 'icon_name', default: 'calc-light1' },
      ],
    };
  }

  public isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  }
}

export default CloudIntegration;
