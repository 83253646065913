/* eslint-disable max-len */
/* eslint-disable camelcase */
import {createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosInstance} from 'axios';
import {LogicSearchRes} from './types';

async function getLogicChunk(filters, axiosClient: AxiosInstance) {
  const res = await axiosClient.post('/rest/logic-engine/v1/logic-item/list', filters);
  return res.data as LogicSearchRes;
}

// eslint-disable-next-line import/prefer-default-export
export const searchLogicList = createAsyncThunk<
  LogicSearchRes,
  {
    skip?: number;
    limit: number;
    project_id: string;
    keyword?: string;
    logic_status?: string;
    logic_version?: string;
  },
  {
    // eslint-disable-next-line @typescript-eslint/ban-types
    rejectValue: Object;
    extra: {
      axiosClient: AxiosInstance;
    };
  }
>('logics/getLogics', async (filters, thunkAPI) => {
  if (filters.limit === -1) {
    // Sync All logics
    const overridenFilters = {
      skip: 0,
      limit: 50,
      project_id: filters.project_id,
      keyword: '',
      logic_status: '',
      logic_version: '',
    };

    let totalItems;
    const items = [];

    const initialRes = await getLogicChunk(overridenFilters, thunkAPI.extra.axiosClient);
    totalItems = initialRes.result.resultCount;
    items.push(...initialRes.result.result);

    if (totalItems > items.length)  {
      for (let skip = 50; skip < totalItems; skip += 50) {
        overridenFilters.skip = skip;
        const res = await getLogicChunk(overridenFilters, thunkAPI.extra.axiosClient);
        items.push(...res.result.result);
      }
    }

    return {
      status: 'success',
      result: {
        limit: -1,
        skip: 0,
        resultCount: totalItems,
        currentItems: totalItems,
        result: items,
      },
    };
  } else {
    const res = await thunkAPI.extra.axiosClient.post(
      '/rest/logic-engine/v1/logic-item/list',
      filters
    );
    return res.data as LogicSearchRes;
  }
});
