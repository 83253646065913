/* eslint-disable consistent-return */
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { AuthContext } from 'src/store/Auth/AuthContext';
import { v2InfoUpdate } from 'src/store/redux/features/backup-location-config';
import fileDownload from 'src/utility/fileDownload';
import { CloneSuccessRateContext } from '../context/cloneSuccessRateContext';

/**
 * A re usable hook that holds some utility functions for the Restore module. Please
 * check each function description for more details.
 *
 * @author Maduka Dilshan
 */
const useRestoreHelpers = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const auth = useContext(AuthContext);
  const successRate = useContext(CloneSuccessRateContext);

  const fethSummary = async (sessionId: string) => {
    try {
      const url = '/rest/backups/v2/restore/get-session-backup-info-full';
      const response = await axios.post(
        process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0] + url,
        {
          session_id: sessionId,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.access_token?.token}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  }

  /**
   * Use for fetch session information for a given task type.
   *
   * @param sessionId - session id
   * @param type - type of the step 'lvar' | 'prod' | 'aird' | 'uv' | 'lgc' | 'uvi'
   * @param populated - when true it will return remaining devices information as well.
   * @param justReturn - when true function will just return the API payload,
   * otherwise it will update the redux state
   *
   * @returns Promise
   *
   */
  const fetchSession = async (
    sessionId: string,
    type = 'lvar',
    populated = true,
    justReturn = false,
  ) => {
    const url = '/rest/backups/v2/restore/get-session-progress';
    try {
      const response = await axios.post(
        process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0] + url,
        {
          session_id: sessionId,
          task_type: type,
          with_comp_map: populated,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.access_token?.token}`,
          },
        },
      );

      if (response.data && response.data.result) {
        // eslint-disable-next-line no-debugger
        // debugger;
        // setInfo(response.data);
        if (justReturn) return response.data.result;
        dispatch(
          v2InfoUpdate({
            value: response.data.result,
            // eslint-disable-next-line no-nested-ternary
            key: type === 'prod' ? 'prodc' : type === 'aird' ? 'airdc' : type,
            mergeErrorComps: true,
          }),
        );
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  /**
   * This method use for run once a restore process is end. One usage of this method is to,
   * run when `onEnd` triggered by the restore Terminal. Here we do some resetting, refetching
   * and updating containers.
   *
   * @param sessionId - Rurrent restore session id
   * @param type - Restore step type
   * @param innerModuleRef - The ref object for the actual main component of each steps.
   * These main screens are,
   *    1. SessionRestoreLocVar.tsx
   *    2. SessionRestoreProDevice.tsx
   *    3. SessionRestoreAirDevice.tsx
   *    4. SessionRestoreFinishUp.tsx
   * @param reCheckStats - When true, the function will fetch the latest stat of the current
   * restore step and update the context.
   * @param setCloneProgress - a callback react setState which call within the function
   * @param setLogPath - a callback react setState which update the parent state logpath for
   * current step
   * @param logPath - Logpath state object
   * @param refetchSession - When true, it will refetch the session for given type with
   * populated data & will update the redux store
   */
  const onRestoreProgressEnd = (
    sessionId: string,
    type,
    innerModuleRef: React.MutableRefObject<any>,
    reCheckStats?: any,
    setCloneProgress?: any,
    setLogPath?: any,
    logPath?: any,
    refetchSession = true,
  ) => {
    try {
      if (refetchSession) {
        fetchSession(sessionId, type);
      }

      if (setCloneProgress) {
        setCloneProgress(false);
      }
      if (setLogPath && logPath) {
        setLogPath({ ...logPath, [type]: '' });
      }

      if (reCheckStats) {
        reCheckStats(sessionId, type);
      }

      if (innerModuleRef && innerModuleRef.current) {
        const configContainerRef = innerModuleRef.current.getConfigurableContainerRef();

        if (configContainerRef && configContainerRef.current) {
          configContainerRef.current.invokeFetchDevice();
        }
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  /**
   * Use for find the next step to run in last step of the ui.
   * @param sessionId - Current Session ID
   * @returns Promise
   */
  // eslint-disable-next-line consistent-return
  const findCloneStepForLastStep = async (sessionId: string) => {
    try {
      const uvHistory = await fetchSession(sessionId, 'uv', false, true);
      // eslint-disable-next-line no-debugger
      // debugger;
      if (!uvHistory) throw new Error('Failed to fetch history');

      if (
        uvHistory.donePercentage != null
        && uvHistory.totalItems !== 0
        && uvHistory.donePercentage !== undefined
        && uvHistory.donePercentage < 100
      ) {
        return {
          type: 'uv',
          successRate: 100,
        };
      }

      const lgcHistory = await fetchSession(sessionId, 'lgc', false, true);

      if (!lgcHistory) throw new Error('Failed to fetch history');

      if (
        lgcHistory.donePercentage != null
        && lgcHistory.totalItems !== 0
        && lgcHistory.donePercentage !== undefined
        && lgcHistory.donePercentage < 100
      ) {
        return {
          type: 'lgc',
          successRate: Number(successRate.lgc_rate),
        };
      }

      const smartListsHistory = await fetchSession(sessionId, 'lv', false, true);

      if (!smartListsHistory) throw new Error('Failed to fetch history');

      if (
        smartListsHistory.donePercentage != null
        && smartListsHistory.totalItems !== 0
        && smartListsHistory.donePercentage !== undefined
        && smartListsHistory.donePercentage < 100
      ) {
        return {
          type: 'lv',
          successRate: Number(successRate.lgc_rate),
        };
      }

      const uviHistory = await fetchSession(sessionId, 'uvi', false, true);

      if (!uviHistory) throw new Error('Failed to fetch history');

      if (
        uviHistory.donePercentage != null
        && uviHistory.totalItems !== 0
        && uviHistory.donePercentage !== undefined
        && uviHistory.donePercentage < 100
      ) {
        return {
          type: 'uvi',
          successRate: Number(successRate.uvi_rate),
        };
      }
      if (uvHistory.donePercentage != null && uvHistory.donePercentage === 100) {
        enqueueSnackbar('Location clone finished', { variant: 'success' });
        return {
          done: true,
        }
      }

      return null;
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  /**
   * This functionc can use to determine the next step to run in last step and to call the
   * given callback function based on the additional config parameters given.
   * @param sessionId - Current session id
   * @param currentRunningStep - The last/ current ran/running step type
   * @param cb - Callback to execute on end
   * @param hard - If this is true, then the callback will be called always
   * @returns Promise
   */
  const determineTaskTypeToBeRunInLastStep = async (
    sessionId: string,
    currentRunningStep: string,
    cb?: (type: string, successRate: number) => any,
    hard = false,
    onCompleted?: () => any,
  ) => {
    try {
      const config = await findCloneStepForLastStep(sessionId);

      if (config && config.done) return onCompleted && onCompleted();

      if (!config) throw new Error('Something Went Wrong. Please Try again later');

      if (currentRunningStep === config.type && !hard) return;

      cb(config.type, config.successRate);
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  const downloadReport = async (sessionId: string, type: string) => {
    try {
      const url = '/rest/backups/v2/restore/clone/task/reports/v1';
      const response = await axios.post(
        process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0] + url,
        {
          session_id: sessionId,
          task_type: type,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.access_token?.token}`,
          },
        },
      );

      if (response && response.data) {
        fileDownload(response.data, `clone-summery-for-${type}-${sessionId}.txt`);
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return {
    fethSummary,
    fetchSession,
    onRestoreProgressEnd,
    determineTaskTypeToBeRunInLastStep,
    downloadReport,
  };
};

export default useRestoreHelpers;
