/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
import React, {useContext, useEffect, useState} from 'react';
import {arrayMoveImmutable as arrayMove} from 'array-move';
import {SortableContainer as SortableContainerFactory, SortableElement} from 'react-sortable-hoc';
import Draggable from 'react-draggable';
import Paper, {PaperProps} from '@mui/material/Paper';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide} from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import {TransitionProps} from '@mui/material/transitions';
import {Close, Done} from '@mui/icons-material';
import {useSnackbar} from 'notistack';
import {
  ErrorSnackbarActions,
  ErrorSnackbarOptions,
} from '../../../../Alters/Snackbar/SnackbarOptions';
import uvService, {userViewGetOrder, userViewOrderSetAll} from '../../../service/uv.service';
import {ApolloAuthContext} from '../../../../../store/Apollo/ApolloContext';
import {UVItemCatName} from '../../../utils';
import styles from './ItemSortDialog.module.css';
import {UVContext} from '../../../UVContext';
import {IItemSortCardItem, ItemSortCardItem} from './ItemSortCardItem';
import {LoadingComponent} from '../../../../shared/Loading/Loading';
import {appConsoleLog, appConsoleError} from '../../../../../utility/appUtils';
import {ProjectContext} from '../../../../../store/Project/ProjectContext';
import {userviewSearchGraphQL} from 'src/services/user-view/user-view.service';
import {useQueryStringFirst} from 'src/utility/CustomHooks/useQueryNumber';

enum SortResourceType {
  USERVIEW_LIST = 1,
  USERVIEW = 2,
  PROJECT_LIST = 3,
}

const fetchUserviews = async (client: any, projectId: string) => {
  const res = await userviewSearchGraphQL(client, projectId, 1, '', 100, 0);

  return res?.userviewSearch?.result || [];
};

const fetchUserviewFolder = async (client: any, folderId: string, userviewId: string) => {
  const response = await uvService.userviewListFolderItems(client, userviewId, folderId, 100, 0);

  return response?.items?.result || [];
};

function useResourceSort(
  resourceType: SortResourceType.USERVIEW_LIST,
  getItems: (client: any, projectId: string) => any,
  parseItem: (item: any) => any
): {
  fetch: (parentId: string) => any;
  sortedOrder: string[];
  selectedItems: IItemSortCardItem[];
  setSortedOrder: (items: string[]) => void;
  saveOrder: (parentId: string) => void;
};
function useResourceSort(
  resourceType: SortResourceType.USERVIEW,
  getItems: (client: any, folderId: string, userviewId: string) => any,
  parseItem: (item: any) => any
): {
  fetch: (parentId: string, userviewId: string) => any;
  sortedOrder: string[];
  selectedItems: IItemSortCardItem[];
  setSortedOrder: (items: string[]) => void;
  saveOrder: (parentId: string) => void;
};
function useResourceSort(resourceType: SortResourceType, getItems: any, parseItem: any): any {
  const userProfile = useContext(ApolloAuthContext);
  const selectedProject = useContext(ProjectContext);

  const [selectedItems, setSelectedItems] = useState<IItemSortCardItem[]>([]);
  const [sortedOrder, setSortedOrder] = useState<string[]>([]);

  async function fetch(parentId: string, userviewId: string);
  async function fetch(parentId: string);
  async function fetch(parentId: string, userviewId?: string) {
    try {
      const res = await userViewGetOrder(userProfile.apollo_client, parentId, resourceType);
      const card_order: any = [];
      if (res.data && res.data.itemOrderGet && res.data.itemOrderGet.items) {
        const list: {[key: string]: any} = res.data.itemOrderGet.items;
        // eslint-disable-next-line no-restricted-syntax
        for (const [_, val] of Object.entries(list)) {
          card_order.push(val.ref_val);
        }
        setSortedOrder(card_order);
      }

      const itemRes = await getItems(userProfile.apollo_client, parentId, userviewId);
      const temp_sort: string[] = [];
      const mappedItems: any[] = itemRes.map(item => {
        const v = parseItem(item);
        temp_sort.push(item.id);
        return v;
      });

      const restOfSortItems = temp_sort.filter(item => !card_order.includes(item));

      if (restOfSortItems.length > 0)  {
        setSortedOrder([...card_order, ...restOfSortItems]);
      }

      setSelectedItems(mappedItems);
    } catch (err) {
      appConsoleError(err);
      return null;
    }
  }

  const saveOrder = async (parentId: string) => {
    await userViewOrderSetAll(
      userProfile.apollo_client,
      selectedProject.selected_project.id,
      parentId,
      resourceType === SortResourceType.USERVIEW_LIST ? 1 : 2,
      sortedOrder
    );
  };

  return {
    fetch,
    sortedOrder,
    selectedItems,
    setSortedOrder,
    saveOrder,
  };
}

// eslint-disable-next-line max-len
const SortableItem = SortableElement<{value: any}>(({value}: any) => (
  <div style={{zIndex: 9999}}>{value}</div>
));

// eslint-disable-next-line max-len
const SortableContainer: any = SortableContainerFactory(({children}: any) => <div>{children}</div>);

type ComponentProps = {
  dialogOpen: boolean;
  dialogCloseEvent: (flag: any) => void;
  folderId?: string;
};

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title11" cancel={'[class*="MuiDialogContent-root"]'}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {children: React.ReactElement<any, any>},
    ref: React.Ref<unknown>
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <Slide direction="up" ref={ref} {...props} />
);

const SortDialog: React.FC<{
  sortedOrder: string[];
  selectedItems: IItemSortCardItem[];
  setSortedOrder: (items: string[]) => void;
  loading: boolean;
  dialogOpen: boolean;
  onSave: () => void;
  closeDialog: (val: boolean) => void;
}> = ({sortedOrder, selectedItems, setSortedOrder, dialogOpen, loading, onSave, closeDialog}) => {
  const onSortStart = ({node, index, collection, isKeySorting}: any, event: any) => {
    appConsoleLog({
      node,
      index,
      collection,
      isKeySorting,
    });
  };

  const onSortEnd = ({oldIndex, newIndex}: any) => {
    const shallow_cpy = [...sortedOrder];
    const newSorted = arrayMove(shallow_cpy, oldIndex, newIndex);
    setSortedOrder(newSorted);
  };
  return (
    <Dialog
      open={dialogOpen}
      TransitionComponent={Transition}
      onClose={() => {
        closeDialog(false);
      }}
      aria-labelledby="alert-dialog-slide-title1"
      aria-describedby="alert-dialog-slide-description"
      PaperComponent={PaperComponent}
    >
      <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title11">
        Sort folder items
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className={`row mt-2 ${styles.dialogContent}`}>
            <div className="col-md-12">
              <div className={styles.DragSelectorListCont}>
                <div className={styles.DragSelectorListInnerCont}>
                  <SortableContainer onSortEnd={onSortEnd} onSortStart={onSortStart}>
                    {sortedOrder.map((item_id, index) => {
                      const item = selectedItems.find(each_item => each_item.id === item_id);
                      if (!item) return <></>;
                      return (
                        <SortableItem
                          key={item.id}
                          value={
                            (
                              <>
                                <ItemSortCardItem item={item} key={item_id} itemIndex={index} />
                                <div className={styles.DragSelectorItemCardDivider} />
                              </>
                            ) as any
                          }
                          index={index}
                          collection={1}
                        />
                      );
                    })}
                  </SortableContainer>
                </div>
              </div>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={onSave} color="primary">
            <Done fontSize="large" />
            Save
          </Button>
          <Button
            onClick={() => {
              closeDialog(false);
            }}
            color="primary"
          >
            <Close fontSize="large" />
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export const ItemSortDialog: React.FunctionComponent<ComponentProps> = ({
  dialogOpen,
  dialogCloseEvent,
  folderId,
}) => {
  const snackbar = useSnackbar();
  const userViewContext = useContext(UVContext);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    fetch: initSortData,
    selectedItems,
    sortedOrder,
    setSortedOrder,
    saveOrder,
  } = useResourceSort(SortResourceType.USERVIEW, fetchUserviewFolder, item => ({
    id: item.id,
    title: item.display_name,
    subTitle: UVItemCatName(item.item_cat),
    icon_name: item.icon_name,
  }));

  useEffect(() => {
    if (userViewContext.projectId !== '' /* -1 */ && userViewContext.userViewId !== '' /* -1 */) {
      const fetch = async () => {
        try {
          setLoading(true);
          await initSortData(folderId, userViewContext.userViewId);
        } catch (err) {
          appConsoleError(err);

          snackbar.enqueueSnackbar('Unexpacted error', {
            key: 'uv-sort-cd-error',
            ...ErrorSnackbarOptions,
            action: ErrorSnackbarActions(snackbar.closeSnackbar),
          });
        } finally {
          setLoading(false);
        }
      };
      fetch();
    }
  }, [
    dialogOpen,
    folderId,
    userViewContext.projectId,
    userViewContext.userViewId,
    userViewContext.userViewPath,
    userViewContext.updated,
  ]);

  const closeDialog = (res: any) => {
    dialogCloseEvent(res);
  };

  const onSave = async () => {
    try {
      await saveOrder(folderId);
      closeDialog(true);
      snackbar.enqueueSnackbar('Item Sorted', {variant: 'success'});
    } catch (err: any) {
      snackbar.enqueueSnackbar(err.message, {variant: 'error'});
    }
  };

  return (
    <SortDialog
      sortedOrder={sortedOrder}
      selectedItems={selectedItems}
      setSortedOrder={setSortedOrder}
      loading={loading}
      dialogOpen={dialogOpen}
      onSave={onSave}
      closeDialog={closeDialog}
    />
  );
};

export const UserviewSortDialog: React.FunctionComponent<ComponentProps> = ({
  dialogOpen,
  dialogCloseEvent,
}) => {
  const projectId = useQueryStringFirst('projectId');
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    fetch: initSortData,
    selectedItems,
    sortedOrder,
    setSortedOrder,
    saveOrder,
  } = useResourceSort(SortResourceType.USERVIEW_LIST, fetchUserviews, item => ({
    id: item.id,
    title: item.name,
    subTitle: 'userview',
    icon_name: item.icon_name,
  }));

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        await initSortData(projectId);
      } catch (err) {
        appConsoleError(err);

        snackbar.enqueueSnackbar('Unexpacted error', {
          key: 'uv-sort-cd-error',
          ...ErrorSnackbarOptions,
          action: ErrorSnackbarActions(snackbar.closeSnackbar),
        });
      } finally {
        setLoading(false);
      }
    };
    if (dialogOpen) fetch();
  }, [dialogOpen, projectId]);

  const closeDialog = (res: any) => {
    dialogCloseEvent(res);
  };

  const onSave = async () => {
    try {
      await saveOrder(projectId);
      closeDialog(true);
      snackbar.enqueueSnackbar('Items Sorted', {variant: 'success'});
    } catch (err: any) {
      snackbar.enqueueSnackbar(err.message, {variant: 'error'});
    }
  };

  console.log(sortedOrder, selectedItems);

  return (
    <SortDialog
      sortedOrder={sortedOrder}
      selectedItems={selectedItems}
      setSortedOrder={setSortedOrder}
      loading={loading}
      dialogOpen={dialogOpen}
      onSave={onSave}
      closeDialog={closeDialog}
    />
  );
};

ItemSortDialog.defaultProps = {
  folderId: null,
};
