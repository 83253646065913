/* eslint-disable max-len */
/* eslint-disable camelcase */
/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * Define Redux Store definition. The redux store is
 * manage business state excluding such as auth user.
 * The redux store is only used
 * for storing business state.
 *
 * @author Maduka Dilshan
 */
import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import {
  AnyAction,
  configureStore,
  EnhancedStore,
  getDefaultMiddleware,
  MiddlewareArray,
} from '@reduxjs/toolkit';
import {useDispatch as useReduxDispatch, useSelector as useReduxSelector} from 'react-redux';

import {AxiosInstance} from 'axios';
import {FirebaseReducer, firebaseReducer, ProfileType} from 'react-redux-firebase';
import {Schema} from 'normalizr';
import {NormalizedState, RuleEngineState} from '@smartlife-redux-state/common';
import {reducer as ormReducer} from '@smartlife-redux-state/orm';
import {reducer as projectReducer} from './features/project';
import {
  reducer as projectUserViews,
  reducer as projectDeviceContainerViews,
} from './features/project-user-views';
import {reducer as ruleEngineReducer} from './features/logic-engine';
import {CommonState, reducer as commonReducer} from './features/common';
import {LogicListState, reducer as logicsReducer} from './features/logics';
import {
  ILocationCreatPayloadFromTemplate,
  reducer as backupReducer,
} from './features/backup-restore';
import {reducer as projectUserviewDeviceMapReducer} from './features/project-userview-device-map';
import {ProjectUserViewState} from './features/project-user-views/types';
import {ProjectState} from './features/project/types';
import {
  BackupLocationConfigState,
  reducer as BackupLocationConfigReducer,
} from './features/backup-location-config';
import {
  ConfigurableContainerState,
  reducer as configurableContainerReducer,
} from './features/configurable-containers';
import {ProjectUserViewDeviceMapState} from './features/project-userview-device-map/types';

export type StoreState = {
  orm: NormalizedState;
  common: CommonState;
  firebase: FirebaseReducer.Reducer<ProfileType, any>;
  projects: ProjectState;
  project_user_viws: ProjectUserViewState;
  project_device_containers: ProjectUserViewState;
  rule_engine: RuleEngineState;
  logics: LogicListState;
  backup: ILocationCreatPayloadFromTemplate;
  backup_location_config: BackupLocationConfigState;
  configurable_containers: ConfigurableContainerState;
  project_userview_device_map: ProjectUserViewDeviceMapState;
};

let store: EnhancedStore<StoreState, AnyAction, any>;
/**
 * The function which initialize the redux store. This is the start point of Redux store.
 * @param {ApolloClient} apollo_client apollo client object which
 * use for fetch data in async action creators
 * @returns — A configured Redux store.
 */
export default (
  apollo_client: ApolloClient<NormalizedCacheObject>,
  axiosInstance: AxiosInstance
) => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const store = configureStore<StoreState, AnyAction, any>({
    reducer: {
      orm: ormReducer as unknown as any, // TODO: fix the type issue. Added any as a workaround
      common: commonReducer,
      firebase: firebaseReducer,
      projects: projectReducer,
      project_user_viws: projectUserViews,
      project_device_containers: projectDeviceContainerViews,
      rule_engine: ruleEngineReducer,
      logics: logicsReducer,
      backup: backupReducer,
      backup_location_config: BackupLocationConfigReducer,
      configurable_containers: configurableContainerReducer,
      project_userview_device_map: projectUserviewDeviceMapReducer,
    },
    devTools: true,
    middleware: getDefaultMiddleware({
      thunk: {
        extraArgument: {
          apolloClient: apollo_client,
          axiosClient: axiosInstance,
        },
      },
    }),
  });
  // store.dispatch(createDc({ id: 'DC_ID_1', name: 'Pro 1' }));
  // store.dispatch(createDc({ id: 'DC_ID_2', name: 'Pro 2' }));
  // store.dispatch(createDevice({ id: 'Device_id_1', dc: 'DC_ID_2' }));
  // store.dispatch(createDevice({ id: 'Device_id_2', dc: 'DC_ID_2' }));
  // store.dispatch(createLogic({ id: 'Logic_#1' } as any));
  // store.dispatch(
  //   createLogicCard({ id: 'LogicCard_1', devices: ['Device_id_1', 'Device_id_2'], logic: 'Logic_#1' }),
  // );
  // store.dispatch(
  //   createLogicCard({
  //     id: 'LogicCard_2',
  //     devices: ['Device_id_1'],
  //     logic: 'Logic_#1',
  //     child: 'LogicCard_1',
  //   }),
  // );
  return store;
};

// export react-redux hooks from here for use other places. ( To keep similar things in same place )
export const useSelector = useReduxSelector;
export const useDispatch = () => useReduxDispatch();

export type RootState = ReturnType<typeof store.getState>;
