/**
 * Copyright (C) Smartlife 2021 The Smartlife Connect Project.
 *
 * Reusable search component
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define a search component
 * @author Mayantha
 * @since -
 */

import React from 'react';
import {appConsoleLog} from '../../../utility/appUtils';
import styles from './SearchBox.module.css';

type ComponentProps = {
  onChange?: any;
  minimal?: boolean;
  children?: any;
  hideSort?: boolean;
};

/**
 * Reusable search component
 *
 * @callback onTextChange
 * @param {Object} props                  component props
 * @param {Boolean} props.minimal         Flag use to enable/disable additional options
 * @param {Object} props.children         child component
 * @param {onTextChange} props.onChange   emit when input change
 *
 * @returns JSX.Element
 */
// eslint-disable-next-line import/prefer-default-export
export const SearchBox: React.FunctionComponent<ComponentProps> = ({
  onChange,
  hideSort,
  children,
  minimal,
}) => (
  <div className={styles.root}>
    <div className="row">
      <div className="col-lg-12">
        <div className="col-lg-8">
          <div className="">{children}</div>
        </div>
        {/* <div className={"col-lg-4"}></div>
          <div className={"col-lg-3"}></div> */}
        {!minimal && (
          <div
            className={`col-lg-4 ${styles.filterOptions}`}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {/* <FormatListBulletedIcon className={styles.iconSize} /> */}
            {!hideSort && (
              <>
                <span className={styles.sortText}>Sorted By: </span>
                <span className={styles.dropDownText}>Recent</span>
              </>
            )}

            {/* <ViewComfyIcon className={styles.iconSize} />
            <NotesIcon className={styles.iconSize} /> */}
          </div>
        )}
      </div>
    </div>
  </div>
);

SearchBox.defaultProps = {
  onChange: () => {
    appConsoleLog('default onChange');
  },
  minimal: false,
  children: null,
};
