import {TreeItem, TreeView} from '@mui/lab';
import {Box, Typography} from '@mui/material';
import {useSnackbar} from 'notistack';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {pluginHook} from 'src/store/Plugins/PluginProvider';
import { UVContext } from 'src/components/UserViewComponents/UVContext';
import { useNavigate } from 'react-router';
import { useQueryStringFirst } from 'src/utility/CustomHooks/useQueryNumber';

type DirectoryNode = {
  id: string;
  display_name: string;
  path: string;
  children?: DirectoryNode[];
  item_type?: string;
};

type DirectoryData = {
  id: string;
  created_by: string;
  project_id: string;
  icon_name: string;
  item_color: string;
  name: string;
  description: string;
  userview_status: number;
  lock_role3: number;
  home_folder_id: string;
  created_at: string;
  updated_at: string;
  home_folder: {
    id: string;
    created_by: string;
    project_id: string;
    userview_id: string;
    parent_item_id: any;
    display_path_ref: string;
    path_ref: string;
    pro_device_id: any;
    air_device_id: any;
    dc_id: any;
    item_cat: number;
    item_type: string;
    item_status: number;
    item_visibility: number;
    item_notification_type: number;
    display_name: string;
    name_id: string;
    rule_name: string;
    macro_name: string;
    icon_name: string;
    item_color: string;
    settings: object;
    config: object;
    created_at: string;
    updated_at: string;
    tags: any[];
    mongo_ref1: string;
    cint_device_id: any;
  };
  children: DirectoryNode[];
};

export const UserviewDirectoryExplorar: React.FC<{userviewId: string, onClick?: (folder: DirectoryNode) => void}> = ({userviewId,  onClick}) => {
  const [directoryData, setDirectoryData] = useState<DirectoryData>();
  const currentUserviewPath = useQueryStringFirst('userViewPath');
  const [error, setError] = useState<string>('');

  const {enqueueSnackbar} = useSnackbar();

  const navigate = useNavigate();

  const expanded: string[] = useMemo(() => {
    const allIds = [];
    function treverse(node: DirectoryNode) {
      if (node.children) {
        allIds.push(node.id);
        return node.children.map(treverse);
      } else {
        return allIds.push(node.id);
      }
    }

    if (directoryData?.children) {
      directoryData.children.map(treverse);
    }
    return allIds;
  }, [directoryData]);
  
  const getPageStateParams = (userViewPath = '', userViewId, projectId, userViewName) => {
    let params = `?userViewId=${userViewId}`;
    params += `&projectId=${projectId}`;

    params += `&userViewPath=${userViewPath}`;
    // params += `&userViewPathName=HOME${currentFolder?.display_path_ref}`;
    params += `&userViewName=${userViewName}`;
    return params;
  };


  const renderItem = (node: DirectoryNode, parentNode: DirectoryNode) => {
    node.path = `${parentNode.path}-${node.id}`
    const isFolder = Array.isArray(node.children) || !node.item_type;
    return (
      <TreeItem
        nodeId={node.id}
        style={{backgroundColor: node.path == currentUserviewPath ? '#ebf1f2' : 'inherit'}}
        onClick={() => {
          if (!isFolder) return;
          
          if (onClick) {
            onClick(node);
            return;
          }
          
          console.log(getPageStateParams(node.path, directoryData.id, directoryData.project_id, directoryData.name))
          console.log('path', node.path);


          navigate(`/app/user-view${getPageStateParams(node.path, directoryData.id, directoryData.project_id, directoryData.name)}`)
        }}
        label={
          <div style={{display: 'flex', alignItems: 'center', cursor: isFolder ? 'pointer' : 'not-allowed'}}>
            {Array.isArray(node.children) ? (
              <FolderIcon />
            ) : node.item_type ? (
              <FiberManualRecordIcon style={{fontSize: 8}} />
            ) : (
              <FolderOpenIcon />
            )}{' '}
            <Typography style={{marginLeft: 7}}>{node.display_name} {node.item_type && <span style={{ fontSize: 12, color: 'gray' }}>{node.item_type}</span>}</Typography>
          </div>
        }
      >
        {node.children?.map(childNode => renderItem(childNode, node))}
      </TreeItem>
    );
  };

  useEffect(() => {
    const init = async () => {
      try {
        const axios = pluginHook.commonIntegrationService.axiosClient;
        const res = await axios.post<{
          status: string;
          result: DirectoryData;
        }>('/project/userview/simplified-tree', {
          userview_id: userviewId,
        });
        const {data} = res;
        const dirData = data?.result;

        if (!dirData) throw new Error('No data found');

        setDirectoryData(dirData);
      } catch (err: any) {
        setError(err.message);
      }
    };

    if (userviewId) init();
  }, [userviewId]);

  return (
    <Box>
      <TreeView expanded={expanded}>
        {directoryData?.children &&
          directoryData.children.map(item =>
            renderItem(item, {
              path: `-${directoryData.home_folder_id}`,
              id: directoryData.id,
              display_name: directoryData.name,
            })
          )}
      </TreeView>
    </Box>
  );
};
