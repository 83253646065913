export const LoginWithEmailLinkActionCodeSettings: any = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: "https://smartlife-pro2-release.web.app/loginWithEmailLink",
  // This must be true.
  handleCodeInApp: true,

  // iOS: {
  //   bundleId: "com.smartlifepro2.ios",
  // },
  // android: {
  //   packageName: "com.smartlifepro2.android",
  //   installApp: true,
  //   minimumVersion: "12",
  // },
  // dynamicLinkDomain: "smartlifepro2.page.link",
};
