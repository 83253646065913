import {createSlice} from '@reduxjs/toolkit';
import {getUserviewItemGroups, getUvDeviceMapJson} from './async-thunks';

const projectUserviewDeviceMapSlice = createSlice({
  name: 'project-userview-device-map',
  initialState: {
    map: null,
    userview_item_groups: null,
    meta: {
      initialized: false,
      error: null,
    },
  },
  reducers: {
    setMap: (state, action) => {
      state.map = action.payload;
    },
    setMeta: (state, action) => {
      state.meta = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getUvDeviceMapJson.fulfilled, (state, action) => {
      state.map = action.payload;
      state.meta.initialized = true;
    });
    builder.addCase(getUvDeviceMapJson.rejected, (state, action) => {
      state.meta.error = action.payload;
    });
    builder.addCase(getUserviewItemGroups.fulfilled, (state, action) => {
      state.userview_item_groups = action.payload;
    });
    builder.addCase(getUserviewItemGroups.rejected, (state, action) => {
      state.meta.error = action.payload;
    });
  },
});

export const {setMap, setMeta} = projectUserviewDeviceMapSlice.actions;
export const {reducer} = projectUserviewDeviceMapSlice;
