import {useMemo} from 'react';
import {LocationEnergyStatResponose} from '../../services/cloud-integration.service';
import {usePopulatedGraphData} from '../../hooks/usePopulatedGraphData';
import {isArray} from 'lodash';
import moment from 'moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from 'chart.js';
import Chart from 'chart.js/auto';
import {Bar, Chart as MultiChart} from 'react-chartjs-2';


ChartJS.register(Filler);

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  require('chartjs-adapter-moment'),
);

const defaultYFill = {
  Peak: {
    value: 0,
    cost: 0,
  },
  OffPeak: {
    value: 0,
    cost: 0,
  },
  TOTAL: {
    value: 0,
    cost: 0,
  },
};

export function LocationCarbonMonitoring({
  stat,
  resolution,
  startUnix,
  endUnix,
}: {
  stat: LocationEnergyStatResponose;
  resolution: any;
  startUnix: any;
  endUnix: any;
}) {
  const totalCarbon = stat?.result?.result?.total_consumption_carbon;

  const populatedRawData = usePopulatedGraphData(
    stat?.result?.result?.tota_consumption_graph,
    resolution,
    defaultYFill,
    startUnix,
    endUnix
  );

  const carbonData = useMemo(() => {
    if (!isArray(populatedRawData) || populatedRawData.length === 0) return null;

    const labels = [];
    const datasets: {
      label: string;
      type: string;
      data: any[];
      [key: string]: any;
    }[] = [
      {
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        label: 'OffPeak',
        type: 'line' as const,
        data: [],
        backgroundColor: 'rgba(248, 190, 101, 0.8)',
        fill: true,
      },
      {
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        label: 'Peak',
        data: [],
        type: 'line' as const,
        backgroundColor: 'rgba(218, 101, 104, 0.8)',
        fill: true,
      },
    ];

    populatedRawData.map(data => {
      let label = `${data.x}`;
      try {
        label = moment.unix(data.x).format('YY-MM-DD HH:mm');
      } catch {}
      labels.push(label);

      if (!data.y) {
        datasets[0].data.push(0);
        datasets[1].data.push(0);
      }

      let isFoundOffPeak = false;
      let isFoundPeak = false;

      Object.keys(data.y).map(rangeKey => {
        if (rangeKey === 'OffPeak') {
          datasets[0].data.push(data.y[rangeKey].value);

          isFoundOffPeak = true;
        }

        if (rangeKey === 'Peak') {
          datasets[1].data.push(data.y[rangeKey].value);

          isFoundPeak = true;
        }
      });

      if (!isFoundOffPeak) {
        datasets[0].data.push(0);
      }
      if (!isFoundPeak) {
        datasets[1].data.push(0);
      }
    });

    return {
      labels,
      datasets,
    };
  }, [populatedRawData]);

  if (carbonData) {
    return (
      <MultiChart<any>
        type="line"
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'top' as const,
            },
            title: {
              display: true,
              text: 'Carbon Emission (g)', 
            },
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: resolution,
                displayFormats: {
                  hour: 'hh:mm A',
                  day: 'MMM DD',
                  week: '[Week] wo',
                },
                parser: 'YY-MM-DD HH:mm',
              },
            },
          },
        }}
        data={carbonData}
      />
    );
  }

  return null;
}
