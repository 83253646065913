/* eslint-disable no-shadow */
/* eslint-disable camelcase */
export interface IUserViewItem {
  status: number;
  id?: string;
  created_by: number;
  project_id: number;
  userview_id: number;

  parent_item_id: string; // userview item id or -1 for root folder item
  display_path_ref: string; // / for folders => , /home143435546565/gt64654654/656 other items empty
  path_ref: string; // / for folders => , /home143435546565/gt64654654/656 other items empty
  pro_device_id: number; // -1 for not use
  air_device_id: number; // -1 for not use

  item_cat: number;
  item_type: string; // folder, press_button, thirdprty_togle, lg_tv ....

  item_status?: number;
  item_visibility: number;
  item_notification_type: number;

  display_name: string;
  // underscore name 20 maxlen+ 10random +  timestamp
  name_id: string;
  rule_name: string;
  macro_name: string;

  icon_name: string;
  item_color: string;

  settings: string | any;
  config: string | any;

  created_at: number | string;
  updated_at: number | string;

  comp_group_id?: string;
}

export enum UserViewItemStatus {
  active = 1,
  disabled = 2,
  deleted = 3,
  hide = 4,
}

export enum ListViewUserviewItemLinkType {
  toList = 'to_list',
  toListItem = 'list_item',
}

export enum UserViewItemCategory {
  item = 1,
  folder = 2,
  linkedFolder = 3,
  linkedItem = 4,
  linkedUV = 5,
  otherItem = 8,
  listOfItems = 10,
  listViewItemLink = 11,
}

export enum UserViewItemVisibility {
  show = 1,
  hide = 2,
}

export enum UserViewItemNotificationType {
  NoNotification = 1,
  TextOnly = 2,
  TextWithPushNotification = 3,
  TextWithPushNotificationAndAlertSound = 4,
  TextWithPushNotificationAndWarningSound = 5,
}

export interface IUserviewPathItems {
  folder: IUserViewItem;
  items: {
    limit: number;
    skip: number;
    resultCount: number;
    result: IUserViewItem[];
  };
  user_view: IUserView;
}

export interface IUserViewPath {
  display_name: string;
  folder_id: string;
  path_ref: string;
}

/**
 * UserView types
 */
export interface IUserView {
  id?: string;
  created_by: number;
  project_id: number;
  parent_folder_id?: string;

  name: string;
  description: string;
  userview_status: number;
  lock_role3: number; // default 2

  item_color?: string;
  icon_name?: string;

  created_at: number | string;
  updated_at: number | string;
}
export enum UserViewStatus {
  active = 1,
  disabled = 2,
  deleted = 3,
}

export enum UserViewLockRole3 {
  lock = 1,
  unlock = 2,
}

export interface IUVCustomError {
  invalid: boolean;
  message: string;
}
