/* eslint-disable camelcase */
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { Trigger } from '@smartlife-redux-state/common';
import { getProjectMembersGraphQL } from 'src/services/member/member.service';
import { isArray } from 'lodash';
import { ApolloAuthContext } from 'src/store/Apollo/ApolloContext';
import { ProjectContext } from 'src/store/Project/ProjectContext';
import { useSnackbar } from 'notistack';
import { update_trigger } from '../../../../../../../store/redux/features/logic-engine';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
});

function isJson(str) {
  if (!str) return false;
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

// eslint-disable-next-line prefer-regex-literals
const friendlyPhraseReg = new RegExp(/^[a-zA-Z\s]+$/);

// eslint-disable-next-line import/prefer-default-export
export const VoiceTriggerForm: React.FC<{trigger: Trigger}> = ({ trigger }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const userProfile = useContext(ApolloAuthContext);
  const selectedProject = useContext(ProjectContext);
  const voice_state = isJson(trigger.variable)
    ? JSON.parse(trigger.variable)
    : {
      assistance: '',
      command: '',
      friendly_name: '',
      user_ids: [],
    };

  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [userIds, setUserIds] = useState([]);

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const res = await getProjectMembersGraphQL(
          userProfile.apollo_client,
          selectedProject.selected_project.id,
          -1,
          -1,
        );
        if (!res.projectListUsers) throw new Error('Failed to get users');
        const filtered = res.projectListUsers.users.filter((user) => user.id != null);
        setAllUsers(filtered);
        if (voice_state) {
          setUserIds(isArray(voice_state.user_ids) ? voice_state.user_ids : []);
        }
      } catch (err: any) {
        enqueueSnackbar(err.message, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };

    if (
      selectedProject
      && selectedProject.selected_project
      && selectedProject.selected_project.id
    ) {
      init();
    } else {
      // TODO: Close the dialog or move backward
    }
  }, []);

  const handleUVChange = (event) => {
    dispatch(
      update_trigger({
        trigger: {
          ...trigger,
          variable: JSON.stringify({
            ...voice_state,
            user_ids: event.target.value,
          }),
        },
      }),
    );
    setUserIds(event.target.value);
  };

  const width = useRef<HTMLDivElement>();

  if (!voice_state) return <></>;

  return (
    <Grid container spacing={2}>
      <Grid ref={width} item md={6}>
        <FormControl className={classes.formControl} variant="outlined" size="small">
          <InputLabel>Project Users (Alexa Account)</InputLabel>
          <Select
            multiple
            value={userIds}
            label="Project Users (Alexa Account)"
            onChange={handleUVChange}
            // input={<Input />}
            variant="outlined"
            style={{ maxWidth: width.current?.offsetWidth }}
            disableUnderline
            renderValue={(selected: any) => {
              const arr = [];
              selected.map((selectedone) => {
                const uv = allUsers.find((item) => item.id === selectedone);
                if (uv) {
                  arr.push(uv.user_email);
                }
                return null;
              });
              // const uv: any = allUsers.find((item) => item.id == selected);
              return arr.join(', ');
            }}
            // MenuProps={MenuProps}
          >
            {isLoading ? (
              <Skeleton variant="rectangular" height={50} />
            ) : (
              allUsers.map((uv) => (
                <MenuItem key={uv.id} value={uv.id}>
                  <Checkbox checked={userIds.indexOf(uv.id) > -1} />
                  <ListItemText secondary={uv.id} primary={uv.user_email} />
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl size="small" variant="outlined" className={classes.formControl}>
          <InputLabel>Voice Assistant</InputLabel>
          <Select
            label="Voice Assistant"
            onChange={(e) => {
              dispatch(
                update_trigger({
                  trigger: {
                    ...trigger,
                    variable: JSON.stringify({
                      ...voice_state,
                      assistance: e.target.value,
                    }),
                  },
                }),
              );
            }}
            value={voice_state.assistance}
          >
            <MenuItem value="amazon_alexa">Amazon Alexa</MenuItem>
            <MenuItem value="google_home" disabled>
              Google Home
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl variant="outlined" size="small" className={classes.formControl}>
          <InputLabel>Command</InputLabel>
          <Select
            label="Command"
            onChange={(e) => {
              dispatch(
                update_trigger({
                  trigger: {
                    ...trigger,
                    variable: JSON.stringify({
                      ...voice_state,
                      command: e.target.value,
                    }),
                  },
                }),
              );
            }}
            value={voice_state.command}
          >
            <MenuItem value="turn_on">Activate</MenuItem>
            <MenuItem value="turn_off">Deactivate</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          variant="outlined"
          size="small"
          label="Voice Phrase"
          error={trigger && !friendlyPhraseReg.test(voice_state.friendly_name)}
          // eslint-disable-next-line max-len
          // || voice_state.friendly_name.indexOf('^') > -1 || voice_state.friendly_name.indexOf('\\') > -1
          onChange={(e) => {
            dispatch(
              update_trigger({
                trigger: {
                  ...trigger,
                  variable: JSON.stringify({
                    ...voice_state,
                    friendly_name: e.target.value,
                  }),
                },
              }),
            );
          }}
          value={voice_state.friendly_name}
          className={classes.formControl}
        />
      </Grid>
    </Grid>
  );
};
