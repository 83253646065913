import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosInstance} from 'axios';
import {th} from 'date-fns/locale';
import {projectDeviceUvMapGraphQL} from 'src/services/location/location.service';

export const getUvDeviceMapJson = createAsyncThunk<
  any,
  {
    projectId: string;
  },
  {
    // eslint-disable-next-line @typescript-eslint/ban-types
    rejectValue: Object;
    extra: {
      apolloClient: ApolloClient<NormalizedCacheObject>;
    };
  }
>('project-userview-device-map/getUvDeviceMapJson', async (payload, thunkAPI) => {
  const res = await projectDeviceUvMapGraphQL(thunkAPI.extra.apolloClient, payload.projectId);
  if (res.ProjectDeviceUvMap && res.ProjectDeviceUvMap.map_json) {
    return JSON.parse(res.ProjectDeviceUvMap.map_json);
  }

  throw new Error('Failed to fetch device map');
});

export const getUserviewItemGroups = createAsyncThunk<
  any,
  string,
  {
    rejectValue: Object;
    extra: {
      axiosClient: AxiosInstance;
    };
  }
>('project-userview-device-map/getUserviewItemGroups', async (projectId, thunkAPI) => {
  const res = await thunkAPI.extra.axiosClient.post('/rest/com-groups/v1/list', {
    project_id: projectId,
  });
  return res.data?.result || [];
});
