/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import Draggable from 'react-draggable';
import Paper, { PaperProps } from '@mui/material/Paper';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide,
} from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { TransitionProps } from '@mui/material/transitions';
import { ArrowBack, ArrowForward, Close } from '@mui/icons-material';
import Done from '@mui/icons-material/Done';
import { useSnackbar } from 'notistack';
import { CiDevice } from 'src/plugins/shared/services/cloud-integration.service';
import {
  ErrorSnackbarActions,
  ErrorSnackbarOptions,
} from '../../../../Alters/Snackbar/SnackbarOptions';
import { ApolloAuthContext } from '../../../../../store/Apollo/ApolloContext';
import { UserViewItemCategory } from '../../../types/types';
import { LoadingComponent } from '../../../../shared/Loading/Loading';
import { ItemCategorySelectorStep } from './ItemCategorySelectorStep';
import { ICommDevice } from '../../UVDeviceFinder/types';
import { UVItemCreator } from './seconds_steps/UVItemCreator';
import { UVOtherItemCreator } from './seconds_steps/UVOtherItemCreator';
import { TreeViewProvider } from '../../../../shared/TreeView';
import { Tree } from '../../../../shared/TreeView/Tree';
import { projectDeviceUvMapGraphQL } from '../../../../../services/location/location.service';
import { ProjectContext } from '../../../../../store/Project/ProjectContext';
import UVLinkedItems from './seconds_steps/UVLinksCreator/UVLinkedItems';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/redux/store';

type ComponentProps = {
  dialogOpen: boolean;
  dialogCloseEvent: (flag) => void;
  itemId?: string;
  itemCat?: number;
};

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef((
  props: TransitionProps & {children: React.ReactElement<any, any>},
  ref: React.Ref<unknown>,
// eslint-disable-next-line react/jsx-props-no-spreading
) => <Slide direction="up" ref={ref} {...props} />);

export enum UVCreatorStep {
  secondStep = 1,
  firstStep = 2,
}
export const ItemCreateDialog: React.FunctionComponent<ComponentProps> = ({
  dialogOpen,
  dialogCloseEvent,
  itemId,
  itemCat,
}) => {
  const snackbar = useSnackbar();
  const userProfile = useContext(ApolloAuthContext);
  const selectedProject = useContext(ProjectContext);
  const { enqueueSnackbar } = useSnackbar();

  const [creatorStep, setCreatorStep] = useState<number>(UVCreatorStep.firstStep);
  const [itemCategoryType, setItemCategoryType] = useState<number>(UserViewItemCategory.item);

  //const [jsonMap, setJsonMap] = useState(null);
  const uvDeviceMap = useSelector((state: RootState) => state.project_userview_device_map.map);
  const userviewItemGroups = useSelector((state: RootState) => state.project_userview_device_map.userview_item_groups);

  const isUpdate = itemId !== undefined && itemId.length > 0;
  const [isLoading, setLoading] = useState(false);
  const [submitRefresh, setSubmitRefresh] = useState<number>(0);
  const [selectedDevice, setDevice] = useState<ICommDevice |(CiDevice & {dc_type: string})>(null);
  const formRef = useRef<any>();

  const closeDialog = (res: any) => {
    dialogCloseEvent(res);
  };

  useEffect(() => {
    const init = async () => {
      if (itemId === undefined || itemId.length < 1) {
        return;
      }
      if (itemCat === UserViewItemCategory.otherItem) {
        setItemCategoryType(UserViewItemCategory.otherItem);
      }
      setCreatorStep(UVCreatorStep.secondStep);
      setLoading(false);
    };
    if (isUpdate) {
      init();
    }
  }, [itemId]);

  // useEffect(() => {
  //   async function init() {
  //     try {
  //       const res = await projectDeviceUvMapGraphQL(
  //         userProfile.apollo_client,
  //         selectedProject.selected_project.id,
  //       );

  //       if (res.ProjectDeviceUvMap && res.ProjectDeviceUvMap.map_json) {
  //         setJsonMap(res.ProjectDeviceUvMap.map_json);
  //       }
  //     } catch (err:any) {
  //       enqueueSnackbar(err.message, { variant: 'error' });
  //     }
  //   }
  //   init();
  // }, []);

  let itemCreatorSecondStep: any = null;

  if (isLoading) {
    itemCreatorSecondStep = (
      <div className="row">
        <LoadingComponent color="#06454d" />
      </div>
    );
  } else if (itemCategoryType === UserViewItemCategory.item) {
    itemCreatorSecondStep = (
      <UVItemCreator
        itemCategoryType={itemCategoryType}
        isUpdate={isUpdate}
        itemId={itemId}
        selectedDevice={selectedDevice}
        formRef={formRef}
        closeDialog={closeDialog}
        setCreatorStep={setCreatorStep}
        setDevice={setDevice}
      />
    );
  } else if (itemCategoryType === UserViewItemCategory.otherItem) {
    itemCreatorSecondStep = (
      <UVOtherItemCreator
        submitRefresh={submitRefresh}
        itemCategoryType={itemCategoryType}
        isUpdate={isUpdate}
        itemId={itemId}
        formRef={formRef}
        closeDialog={closeDialog}
        setCreatorStep={setCreatorStep}
      />
    );
  } else if (itemCategoryType === UserViewItemCategory.linkedItem) {
    itemCreatorSecondStep = <UVLinkedItems formRef={formRef} closeDialog={closeDialog} />;
  }
  const handleCreateClick = () => {
    if (itemCategoryType === UserViewItemCategory.item && selectedDevice == null) {
      snackbar.enqueueSnackbar('Please select device ', {
        key: 'uv-item-create-error-ch',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
      return;
    }
    if (formRef.current) {
      setSubmitRefresh(Date.now());
      setTimeout(() => {
        formRef.current.submitForm();
      }, 400);
    }
  };

  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  return (
    <TreeViewProvider>
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        onClose={() => {
          closeDialog(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        PaperComponent={PaperComponent}
        maxWidth={!isUpdate && itemCategoryType === UserViewItemCategory.item ? 'lg' : 'sm'}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {isUpdate ? 'Update Item' : 'Add an Item'}
        </DialogTitle>
        <DialogContent>
          <div className="row" style={{ width: '100%', marginRight: 0, marginLeft: 0 }}>
            {creatorStep === UVCreatorStep.firstStep ? (
              <div className="col-md-12">
                <ItemCategorySelectorStep
                  initialCat={itemCategoryType}
                  onCatChange={(cat) => {
                    setItemCategoryType(cat);
                  }}
                />
              </div>
            ) : (
              <>
                {itemCategoryType === UserViewItemCategory.item && (
                  <>
                    {!isUpdate && (
                      <div className="col-md-5">
                        {uvDeviceMap && (
                          <Tree
                            //key={JSON.stringify(userviewItemGroups)}
                            dataSource={{
                              json_map: uvDeviceMap,
                              userview_item_groups: userviewItemGroups,
                              project_id: selectedProject.selected_project.id,
                            }}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
                {!isUpdate && itemCategoryType === UserViewItemCategory.item ? (
                  <div className="col-md-6">{itemCreatorSecondStep}</div>
                ) : (
                  itemCreatorSecondStep
                )}
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {creatorStep === UVCreatorStep.firstStep ? (
            <div>
              <Button
                onClick={() => {
                  setCreatorStep(UVCreatorStep.secondStep);
                }}
                color="primary"
              >
                Next
                <ArrowForward fontSize="large" />
              </Button>
              <Button
                onClick={() => {
                  closeDialog(false);
                }}
                color="primary"
              >
                <Close fontSize="large" />
                Close
              </Button>
            </div>
          ) : (
            <div>
              <Button
                onClick={() => {
                  setCreatorStep(UVCreatorStep.firstStep);
                }}
                color="primary"
              >
                Back
                <ArrowBack fontSize="large" />
              </Button>
              <Button
                onClick={() => {
                  handleCreateClick();
                }}
                color="primary"
              >
                <Done fontSize="large" />
                {isUpdate ? 'Update' : 'Create'}
              </Button>
              <Button
                onClick={() => {
                  closeDialog(false);
                }}
                color="primary"
              >
                <Close fontSize="large" />
                Close
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </TreeViewProvider>
  );
};

ItemCreateDialog.defaultProps = {
  itemId: null,
  itemCat: null,
};
