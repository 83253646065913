/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useEffect, useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  Button,
  Dialog,
} from '@mui/material';
import {
  Action, buildActionPayload, buildEmptyLogicPayload, toNormalize,
} from '@smartlife-redux-state/common';
import { insertInOrder } from '@smartlife-redux-state/orm';
import { ITEM_DEFAULTS } from '../../../../../../config/deviceConfig/item_defaults';
import { appConsoleError, appConsoleLog } from '../../../../../../utility/appUtils';
import {
  ErrorSnackbarOptions,
  ErrorSnackbarActions,
  SuccessSnackbarOptions,
  SuccessSnackbarActions,
} from '../../../../../Alters/Snackbar/SnackbarOptions';
import uvService from '../../../../service/uv.service';
import {
  IUserViewItem,
  UserViewItemVisibility,
  IUVCustomError,
  UserViewItemCategory,
  ListViewUserviewItemLinkType,
} from '../../../../types/types';
import { getFolderIdFromPathRef } from '../../../../utils';
import { UVContext, UVReducerTypes } from '../../../../UVContext';
import { UVItemCreatorDynamic } from '../UVItemCreatorDynamic';

import { ApolloAuthContext } from '../../../../../../store/Apollo/ApolloContext';
import { UVCreatorStep } from '../ItemCreateDialog';
import { IUVOtherItem } from '../../../../../../config/deviceConfig/types';
import { AuthContext } from '../../../../../../store/Auth/AuthContext';
import { RootState } from '../../../../../../store/redux/store';
import { ProjectContext } from '../../../../../../store/Project/ProjectContext';
import { userviewGetGraphQL } from '../../../../../../services/user-view/user-view.service';

interface FormType {
  display_name: string;
  visibility: boolean;
  rule_name: string;
  macro_name: string;
  item_notification_type: number;
}
type ComponentProps = {
  itemCategoryType: UserViewItemCategory;
  isUpdate: boolean;
  itemId?: string;
  formRef: any;
  submitRefresh: number;
  setCreatorStep: (step: any) => void;
  closeDialog: (result: boolean) => void;
};
// eslint-disable-next-line import/prefer-default-export
export const UVOtherItemCreator: React.FunctionComponent<ComponentProps> = ({
  itemCategoryType,
  isUpdate,
  itemId,
  formRef,
  submitRefresh,
  setCreatorStep,
  closeDialog,
}) => {
  const snackbar = useSnackbar();

  const userProfile = useContext(ApolloAuthContext);
  const userViewContext = useContext(UVContext);

  const [selectedIcon, selectNewIcon] = useState('calc-door2');
  const [selectedColor, selectNewColor] = useState('#FFFFFF');
  const [isLoading, setLoading] = useState(false);

  const [uvSettings, setUVSettings] = useState<any>(null);
  const [userviewDeviceSettings, setUserviewDeviceSettings] = useState<any>({});
  const [customFieldsErrors, updateCustomFieldErrors] = useState<{
    [key: string]: IUVCustomError;
  }>({});
  const [selectedOtherItemCat, setOtherItemCat] = useState<string>('');
  const [itemConfig, setOtherItemConfig] = useState<IUVOtherItem>();

  const [currentFolderItem, setCurrentFolderItem] = useState<IUserViewItem | null>(null);
  const [formDefaults, setFormDefaults] = useState({
    display_name: '',
    visibility: true,
    rule_name: '',
    macro_name: '',
    item_notification_type: 1,
  });

  useEffect(() => {
    const { UV_OTHER_ITEMS } = ITEM_DEFAULTS.ITEM_CREATOR;
    const items: IUVOtherItem[] = Object.values(UV_OTHER_ITEMS.items);
    const index = items.findIndex((itm) => itm.item_type === selectedOtherItemCat);
    if (index > -1) {
      setOtherItemConfig(items[index]);
      setUVSettings(items[index].features.uv_settings);
    }
  }, [selectedOtherItemCat]);
  // set current item properties when update
  useEffect(() => {
    const init = async () => {
      try {
        if (itemId === undefined || itemId.length < 1) {
          return;
        }
        const res = await uvService.userviewItemGet(userProfile.apollo_client, itemId);

        const uvItem = res.item;
        uvItem.settings = JSON.parse(uvItem.settings);
        setOtherItemCat(uvItem.item_type);
        setCurrentFolderItem(uvItem);
        setCreatorStep(UVCreatorStep.secondStep);
        setUserviewDeviceSettings(uvItem.settings);
        
        setFormDefaults({
          display_name: res.item.display_name,
          visibility: res.item.item_visibility === UserViewItemVisibility.show,
          rule_name: res.item.rule_name,
          macro_name: res.item.macro_name,
          item_notification_type: res.item.item_notification_type,
        });
        selectNewColor(res.item.item_color);
        selectNewIcon(res.item.icon_name);
      } catch (err) {
        appConsoleError(err);

        snackbar.enqueueSnackbar('Unexpacted error', {
          key: 'uv-item-update-error',
          ...ErrorSnackbarOptions,
          action: ErrorSnackbarActions(snackbar.closeSnackbar),
        });
      }
      setLoading(false);
    };
    if (isUpdate) {
      setLoading(true);
      init();
    }
  }, [itemId]);

  const appendSettings = (payload: any) => {
    const { data } = payload;

    console.log('-------------------------------', { ...userviewDeviceSettings, ...data });
    setUserviewDeviceSettings((oldData: any) => ({ ...oldData, ...data }));
  };

  const navigate = useNavigate();
  const authUser = useContext(AuthContext);
  const selectedProject = useContext(ProjectContext);
  const logic = useSelector((state: RootState) => state.rule_engine);
  const orm = useSelector((state: RootState) => state.orm);
  const dispatch = useDispatch();

  const [shouldCallOnSubmit, setShoulCallOnSubmit] = useState(false);
  const [submitData, setSubmitData] = useState({
    values: null,
    formikHelpers: null,
    flag: null,
  });
  const prepareNormalizedLogic = async () => {
    if (logic) {
      const normalized = toNormalize(logic);
      insertInOrder(normalized, dispatch);
    }
    setShoulCallOnSubmit(true);
  };

  useEffect(() => {
    if (!shouldCallOnSubmit) return;
    if (!submitData.values) return;
    if (Object.keys(orm.LogicActionCard.itemsById).length > 0) {
      const { values, formikHelpers, flag } = submitData;
      onSubmitForm1(values, formikHelpers, flag);
    }
  }, [shouldCallOnSubmit, orm, submitData]);

  /**
   * If the actions.device is empty that mean the user did not selected
   * any device in extra fields ( Audio, video devices ). So do not create a logic
   * @param actions
   * @returns
   */
  function shouldLogicCreat(actions: Action[]) {
    const filtered = actions.filter((action) => {
      let flag = true;

      if (action.device == null) {
        flag = false;
      }
      if (action.dc && action.dc.dc_type === 8) {
        flag = false;
      }
      // if (action.variable == null || action.variable.length == 0) {
      //   flag = false;
      // }

      return flag;
    });
    if (filtered.length > 0) {
      return true;
    }
    return false;
  }

  const onSubmitForm = async (values: FormType, formikHelpers: any, flag: string) => {
    if (shouldLogicCreat(logic.actions) && !isUpdate) {
      setSubmitData({
        values,
        formikHelpers,
        flag,
      });
      prepareNormalizedLogic();
    } else {
      onSubmitForm1(values, formikHelpers, flag);
    }
  };

  const onSubmitForm1 = async (values: FormType, formikHelpers: any, flag: string) => {
    let skip_logic_creation = false;
    if (flag === 'cancel') {
      return;
    }
    if (!values.display_name || values.display_name.length === 0) {
      formikHelpers.setFieldError('display_name', 'Item Name is Required');
      snackbar.enqueueSnackbar('Display Name is Required', {
        variant: 'error',
      });
      return;
    }
    try {
      const parentFolder = getFolderIdFromPathRef(userViewContext.userViewPath);
      const { projectId, userViewId } = userViewContext;

      const cfErrors: IUVCustomError[] = Object.values(customFieldsErrors).filter(
        (item) => item.invalid,
      );
      if (cfErrors.length > 0) {
        throw cfErrors[0].message;
      }
      if (!itemConfig) {
        throw new Error('Item type not selected');
      }
      let genarated_logic_id = null;

      // Creating the logic
      // Logics will create when it is first time "CREATE" uv or
      // if user force to recreate when updating a uv item
      if (
        (shouldLogicCreat(logic.actions) && !isUpdate)
        || flag === 'recreate'
        || flag === 'recreate-and-go'
      ) {
        try {
          // "IR" devices at the moment
          // selectedDevice.cat == "ac"
          if (selectedOtherItemCat === 'media_device') {
            // dispatch(validate_main_action())
            const parsedChecks = buildEmptyLogicPayload();
            const parsedActions = buildActionPayload(logic.actions);

            const uv_folder_ids = userViewContext.userViewPath.split('-');
            // debugger;
            let current_folder_id = '';
            if (uv_folder_ids.length > 0) {
              current_folder_id = uv_folder_ids[uv_folder_ids.length - 1];
            }
            let folderId = getFolderIdFromPathRef(current_folder_id);

            if (!folderId || folderId === 'root') {
              const res = await userviewGetGraphQL(
                userProfile.apollo_client,
                userViewContext.userViewId,
              );
              if (res.userviewGet) {
                const { home_folder_id } = res.userviewGet.userview;
                folderId = home_folder_id;
              }
            }

            const folder = await uvService.userviewListFolderItems(
              userProfile.apollo_client,
              userViewContext.userViewId,
              folderId,
              1,
              0,
            );

            if (parsedActions.length === 0) throw new Error('Empty Actions');
            const payload = {
              project_id: projectId,
              status: 'active',
              name:
                flag === 'recreate' || flag === 'recreate-and-go'
                  ? `Macro - ${userViewContext.userViewName}[ ${folder.folder.display_path_ref} ]`
                    + `(${values.display_name.trim()} - recreated at ${new Date().toLocaleString()})`
                  : `Macro -${userViewContext.userViewName}[ ${folder.folder.display_path_ref} ]`
                    + `(${values.display_name.trim()})`,
              alexa_friendly_name: '',
              input_json: JSON.stringify(orm),
              triggers: [],
              rules: parsedChecks.rules,
              logic: parsedChecks.logic,
              actions: parsedActions,
            };

            const { token } = authUser.access_token;
            const res = await axios.post(
              `${
                process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
              }/rest/logic-engine/v1/logic-item/create`,
              payload,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            );
            genarated_logic_id = res.data.result.logic_id;
          }
        } catch (err) {
          skip_logic_creation = true;
          snackbar.enqueueSnackbar('Skipping Logic creation due to insufficient data', {
            variant: 'info',
          });
          throw err;
        }
      }
      if (isUpdate) {
        const modified_device_setting = { ...userviewDeviceSettings };

        if (currentFolderItem.settings) {
          const settings = typeof currentFolderItem.settings === 'string'
            ? JSON.parse(currentFolderItem.settings)
            : currentFolderItem.settings;

          modified_device_setting.generated_logic_id = settings.generated_logic_id;

          // if user force recreating when update the uvitem then force to
          // get the logic id from newly created logic but not from the device setting.
          // selectedDevice.cat == "ac" ||
          if (
            !skip_logic_creation
            && shouldLogicCreat(logic.actions)
            && (flag === 'recreate' || flag === 'recreate-and-go')
            && selectedOtherItemCat === 'media_device'
          ) {
            const macors = values.macro_name.split(',');
            // console.log(
            //   "vbbnbnbnbnbnbnbn",
            //   modified_device_setting.generated_logic_id,
            //   currentFolderItem.settings,
            //   genarated_logic_id,
            //   values.macro_name
            // );
            const old_generate_macro_index = macors.findIndex(
              (item) => item === modified_device_setting.generated_logic_id,
            );

            if (old_generate_macro_index > -1) {
              macors[0] = genarated_logic_id;
              values.macro_name = macors.toString();
            } else {
              values.macro_name = genarated_logic_id;
            }

            const old_generated_rule_id = modified_device_setting.generated_logic_id;
            const { token } = authUser.access_token;

            if (old_generated_rule_id) {
              await axios.post(
                `${
                  process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
                }/rest/logic-engine/v1/logic-item/delete`,
                {
                  logic_id: old_generated_rule_id,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                },
              );
            }

            modified_device_setting.generated_logic_id = genarated_logic_id;
          }
          // console.log("cxcxcxcxcxc", modified_device_setting, settings);
        }

        const { list_settings } = modified_device_setting;

        let itemCat = UserViewItemCategory.otherItem;

        if (
          list_settings
          && list_settings.link_type
          && list_settings.link_type === ListViewUserviewItemLinkType.toList
        ) {
          itemCat = UserViewItemCategory.listOfItems;
        }

        if (
          list_settings
          && list_settings.link_type
          && list_settings.link_type === ListViewUserviewItemLinkType.toListItem
        ) {
          itemCat = UserViewItemCategory.listViewItemLink;
        }

        const deviceItem: any = {
          display_name: values.display_name.trim(),
          item_visibility: values.visibility
            ? UserViewItemVisibility.show
            : UserViewItemVisibility.hide,
          item_notification_type: values.item_notification_type,
          rule_name: values.rule_name,
          macro_name: values.macro_name,
          icon_name: selectedIcon,
          item_color: selectedColor,
          item_type: modified_device_setting.list_settings ? 'list_view_v_01' : null,
          item_cat: itemCat,
          settings: userviewDeviceSettings,
        };
        console.log(deviceItem);
        if (itemId === undefined) {
          throw new Error('Item id not valid');
        }

        appConsoleLog('uv update item', deviceItem);

        if (deviceItem.settings) {
          deviceItem.settings = JSON.stringify(deviceItem.settings);
        }
        await uvService.userviewUpdateItem(userProfile.apollo_client, itemId, deviceItem);
      } else {
        let modified_device_setting = userviewDeviceSettings;
        if (genarated_logic_id) {
          modified_device_setting = typeof userviewDeviceSettings === 'string'
            ? JSON.parse(userviewDeviceSettings)
            : userviewDeviceSettings;
          modified_device_setting.generated_logic_id = genarated_logic_id;
        }

        const { list_settings } = modified_device_setting;

        let itemCat = UserViewItemCategory.otherItem;

        if (
          list_settings
          && list_settings.link_type
          && list_settings.link_type === ListViewUserviewItemLinkType.toList
        ) {
          itemCat = UserViewItemCategory.listOfItems;
        }

        if (
          list_settings
          && list_settings.link_type
          && list_settings.link_type === ListViewUserviewItemLinkType.toListItem
        ) {
          itemCat = UserViewItemCategory.listViewItemLink;
        }

        const deviceItem: any = {
          project_id: projectId,
          userview_id: userViewId,
          display_name: values.display_name.trim(),
          parent_item_id: parentFolder === '' /* < 1 */ ? null : parentFolder,
          pro_device_id: null,
          air_device_id: null,
          dc_id: null,
          item_type: modified_device_setting.list_settings ? 'list_view_v_01' : null,
          item_cat: itemCat,
          item_visibility: values.visibility
            ? UserViewItemVisibility.show
            : UserViewItemVisibility.hide,
          item_notification_type: values.item_notification_type,
          rule_name: values.rule_name,
          // eslint-disable-next-line no-nested-ternary
          macro_name: genarated_logic_id
            ? values.macro_name.length > 0
              ? `${genarated_logic_id},${values.macro_name}`
              : genarated_logic_id
            : values.macro_name,
          icon_name: selectedIcon,
          item_color: selectedColor,
          settings: userviewDeviceSettings,
          config: null,
        };
        console.log(JSON.stringify(deviceItem));
        deviceItem.item_type = itemConfig?.item_type;
        appConsoleLog('uv item create', deviceItem);

        if (deviceItem.settings) {
          deviceItem.settings = JSON.stringify(deviceItem.settings);
        }

        await uvService.userviewItemCreate(userProfile.apollo_client, deviceItem);
      }

      snackbar.enqueueSnackbar(isUpdate ? 'Item updated' : 'Item created', {
        key: 'uv-folder-create-success',
        ...SuccessSnackbarOptions,
        action: SuccessSnackbarActions(snackbar.closeSnackbar),
      });

      userViewContext.dispatch({
        type: UVReducerTypes.CHANGE,
        payload: {
          updated: Date.now(),
        },
      });

      formRef.current.resetForm();
      closeDialog(true);

      // Navigation based on user input form dialog confirmation
      if (flag === 'go') {
        snackbar.enqueueSnackbar('Starting navigation, please wait....', {
          variant: 'info',
        });

        if (!isUpdate) {
          navigate(
            `/app/logics/edit?projectId=${selectedProject.selected_project.id}&logicId=${genarated_logic_id}`,
          );
        } else {
          const settings = typeof currentFolderItem.settings === 'string'
            ? JSON.parse(currentFolderItem.settings)
            : currentFolderItem.settings;
          const logic_id = settings.generated_logic_id;
          navigate(
            `/app/logics/edit?projectId=${selectedProject.selected_project.id}&logicId=${logic_id}`,
          );
        }
      } else if (flag === 'recreate' && !skip_logic_creation) {
        snackbar.enqueueSnackbar('Logic recreated!', { variant: 'success' });
      } else if (flag === 'recreate-and-go' && !skip_logic_creation) {
        snackbar.enqueueSnackbar('Logic recreated!', { variant: 'success' });
        navigate(
          `/app/logics/edit?projectId=${selectedProject.selected_project.id}&logicId=${genarated_logic_id}`,
        );
      } else {
        // do nothing
      }
    } catch (err) {
      // throw err;
      appConsoleError(err);
      let errMsg = isUpdate ? 'Cannot update item' : 'Cannot create item';

      if (typeof err === 'string') {
        errMsg = err;
      }
      snackbar.enqueueSnackbar(errMsg, {
        key: 'uv-item-create-error',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    } finally {
      // dispatch(reset_logic_creation());
    }
  };

  const [openLogicNavigationDialog, setOpenLogicNavigationDialog] = React.useState(false);
  const [formVals, setFormVals] = useState<{values: FormType; formikHelpers: any}>(null);

  async function handleLogicNavigationButtonClose(flag: string) {
    try {
      await onSubmitForm(formVals.values, formVals.formikHelpers, flag);
    } catch (err: any) {
      snackbar.enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setOpenLogicNavigationDialog(false);
    }
  }

  function onConfirmLogicCreation(values: FormType, formikHelpers: any) {
    // selectedDevice.cat == "ac" ||
    if (selectedOtherItemCat === 'media_device' && isUpdate) {
      setOpenLogicNavigationDialog(true);
      setFormVals({
        values,
        formikHelpers,
      });
    } else {
      onSubmitForm(values, formikHelpers, 'no');
    }
  }
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openLogicNavigationDialog}
        // onClose={handleLogicNavigationButtonClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ color: 'red' }}>
          Alert!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {!isUpdate
              ? `You are about to create a user view item that contains a logic(macro). Do you need to navigate to the
            generated logic once this user view item created ?`
              : 'You are about to update a userview that may have a generated logic attached to it. If you do any changes by updating the devices, or changing zones, source or hdmi ports, thats used for generate the the logic, you may need to manually update the logic. Do you prefer that? or do you want to recreate a new logic item ?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Tooltip title="Cancel the update process">
            <Button onClick={() => handleLogicNavigationButtonClose('cancel')} color="primary">
              <>Cancel</>
            </Button>
          </Tooltip>
          <Tooltip title="This will only update the userview. It won't do any update to the generated logic content">
            <Button onClick={() => handleLogicNavigationButtonClose('no')} color="primary">
              <>Just update the userview</>
            </Button>
          </Tooltip>
          <Tooltip title="If you don't wish to recreate a logic but to modified the current logic click this. This will navigate to the logic content after userview updated">
            <Button
              onClick={() => handleLogicNavigationButtonClose('go')}
              color="primary"
              variant={isUpdate ? 'text' : 'contained'}
              autoFocus
            >
              Yes Go there
            </Button>
          </Tooltip>
          {isUpdate && (
            <>
              <Tooltip title="This will recreate a new logic and remove the old generated logic and replace it and will navigate to the logic content after this userview updated">
                <Button
                  onClick={() => handleLogicNavigationButtonClose('recreate-and-go')}
                  color="primary"
                  autoFocus
                >
                  Recreate and Go There
                </Button>
              </Tooltip>
              <Tooltip title="This will recreate a new logic and remove the old generated logic and replace it">
                <Button
                  onClick={() => handleLogicNavigationButtonClose('recreate')}
                  color="primary"
                  variant="contained"
                  autoFocus
                >
                  Recreate the logic
                </Button>
              </Tooltip>
            </>
          )}
        </DialogActions>
      </Dialog>
      <UVItemCreatorDynamic
        itemCategoryType={itemCategoryType}
        selectedColor={selectedColor}
        selectedIcon={selectedIcon}
        uvSettings={uvSettings}
        selectedDevice={null}
        isUpdate={isUpdate}
        isLoading={isLoading}
        setDevice={(device) => {
          appConsoleLog(device);
        }}
        selectNewColor={(color) => selectNewColor(color)}
        selectNewIcon={(icon) => selectNewIcon(icon)}
        formDefaults={formDefaults}
        setFormDefaults={(defaults) => setFormDefaults(defaults)}
        // eslint-disable-next-line react/jsx-no-bind
        onSubmitForm={onConfirmLogicCreation}
        appendSettings={appendSettings}
        uvItem={currentFolderItem}
        formRef={formRef}
        submitRefresh={submitRefresh}
        selectedOtherItemCat={selectedOtherItemCat}
        setOtherItemCat={async (cat) => {
          setUVSettings(null);
          await updateCustomFieldErrors({});
          setOtherItemCat(cat);
        }}
        updateCustomFieldErrors={(updatedErrors, hardReset = false) => {
          updateCustomFieldErrors((errors: any) => {
            if (hardReset) {
              return {
                ...updatedErrors,
              };
            }
            return {
              ...errors,
              ...updatedErrors,
            };
          });
        }}
      />
    </>
  );
};

UVOtherItemCreator.defaultProps = {
  itemId: null,
};
