/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/**
 * Copyright (C) Smartlife 2021 The Smartlife Connet Project.
 *
 * Draggable device card which will use in Device container components
 *
 * @file This file define the draggable device card
 * @author Maduka Dilshan
 * @since 0.1.243
 */
import {Box, Checkbox, Divider, Grid, IconButton, Tooltip, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import {AirDevice, ProDevice} from '@smartlife-redux-state/common';
import {AirAvaibleDevice} from 'src/store/redux/features/configurable-containers';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {ITEM_DEFAULTS} from '../../../../../../config/deviceConfig/item_defaults';
import {DeviceConfigContext} from './context/deviceConfigContext';
import useLongPress from './hooks/useOnLongPress';
import {useSelector} from 'react-redux';
import {RootState} from 'src/store/redux/store';
import {de} from 'date-fns/locale';

const useStyles = makeStyles({
  top: {
    background: 'white',
    borderRadius: '5px',
    height: '100%',
  },
  root: {
    height: '90px',
    minHeight: '50px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5%',
    textAlign: 'center',
    fontSize: '10px',
    flexDirection: 'column',
    cursor: 'default',
    overflow: 'hidden',
  },
  dragContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  icon: {
    fontSize: '10px',
    '&::before': {
      fontSize: '20px',
      color: '#048696',
    },
  },
  name: {
    color: '#048696',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
  },
});

export enum DEVICE_STATUS_IN_RESTORE_STEP {
  IN_TEMPLATE_DEVICE = 'in_template',
  EXISTING_DEVICE_IN_CONFIGURABLE_CONTAINER = 'existing_device_in_configurable_container',
  DROPPED_DEVICE_FROM_TEMPLATE = 'dropped_device_from_template',
}

const Device: React.FC<{
  deviceStatus: DEVICE_STATUS_IN_RESTORE_STEP;
  device?: AirDevice | ProDevice;
  avaibleDevice?: AirAvaibleDevice;
  dragHandlerProps?: any;
  dragDisabled?: boolean;
  isHidden?: boolean;
  hasError?: boolean;
  bgColor?: string;
  showCheckBox?: boolean;
  checked?: boolean;
  containerId?: any;
  handleCheckChange?: (value) => void;
  handleOnPullClick?: () => void;
  onLongPress?: () => void;
  onClickUnHide?: (device: AirDevice | ProDevice) => void;
}> = React.memo(
  ({
    device,
    avaibleDevice,
    dragHandlerProps,
    deviceStatus,
    dragDisabled,
    hasError = false,
    bgColor = null,
    checked = false,
    showCheckBox = false,
    isHidden = false,
    containerId,
    handleCheckChange,
    handleOnPullClick,
    onLongPress,
    onClickUnHide,
  }) => {
    const deviceInfo = useContext(DeviceConfigContext);

    const {userview_item_groups} = useSelector(
      (state: RootState) => state.project_userview_device_map
    );

    const group = useMemo(() => {
      if (!userview_item_groups) return null;
      if (!device || !device.comp_group_id) return null;

      return userview_item_groups.find(g => g.id === device?.comp_group_id);
    }, [device, userview_item_groups]);

    const handleClick = event => {
      console.log({
        selectedDevice: avaibleDevice
          ? {...avaibleDevice, configContainerId: containerId}
          : {...device, configContainerId: containerId},
        showPanel: true,
        isClickedOnAvailableDevice: !!avaibleDevice,
      });
      // eslint-disable-next-line no-debugger
      // debugger;
      if (deviceStatus === DEVICE_STATUS_IN_RESTORE_STEP.IN_TEMPLATE_DEVICE) return;
      deviceInfo.dispatch({
        type: 'UPDATE_PANEL',
        payload: {
          selectedDevice: avaibleDevice
            ? {...avaibleDevice, configContainerId: containerId}
            : device,
          showPanel: true,
          isClickedOnAvailableDevice: !!avaibleDevice,
        },
      });
    };

    const classes = useStyles();

    const [deviceConfig, setDeviceConfig] = useState<{
      icon: string;
      title: string;
    }>({icon: '', title: ''});

    useEffect(() => {
      if (!device) return;
      const ITEMS_LIB: any = ITEM_DEFAULTS.ITEM_CREATOR.item_info_flows;
      if (ITEMS_LIB[device.cat] && ITEMS_LIB[device.cat].children[device.sub_cat]) {
        const conf = ITEMS_LIB[device.cat].children[device.sub_cat];
        setDeviceConfig(conf);
        deviceInfo.dispatch({
          type: 'UPDATE_PANEL',
          payload: {
            deviceConfig: conf,
          },
        });
      }
    }, [device]);

    const longPressEvent = useLongPress(
      () => {
        if (onLongPress) onLongPress();
      },
      null,
      {
        shouldPreventDefault: true,
        delay: 500,
      }
    );

    return (
      <>
        <Grid
          container
          className={classes.top}
          onClick={handleClick}
          {...longPressEvent}
          style={{
            // eslint-disable-next-line no-nested-ternary
            backgroundColor:
              device && device.comp_group_id
                ? '#b0f4bb'
                : isHidden
                ? '#E7EBF0'
                : bgColor || (hasError ? '#F38583' : 'white'),
          }}
        >
          <Grid item md={12}>
            <Box className={classes.root}>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <span
                  style={{fontSize: '10px !important'}}
                  className={deviceConfig ? `calc ${deviceConfig.icon} ${classes.icon}` : ''}
                />
              </div>
              <div className={classes.name}>
                <Tooltip
                  title={
                    <div>
                      <Typography>
                        {(device && device.cat.replaceAll('_', ' ').toLocaleUpperCase()) || ''} -{' '}
                        {(device && device.sub_cat.replaceAll('_', ' ').toUpperCase()) || ''}
                      </Typography>
                      <Divider />
                      <Typography>
                        {group && group.name ? group.name : 'No Group'}
                      </Typography>
                    </div>
                  }
                >
                  <Typography>
                    {device ? device.name : avaibleDevice ? avaibleDevice.name : ''}
                  </Typography>
                </Tooltip>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                {deviceStatus === DEVICE_STATUS_IN_RESTORE_STEP.DROPPED_DEVICE_FROM_TEMPLATE && (
                  <Tooltip title="Send back to template">
                    <IconButton onClick={() => handleOnPullClick()}>
                      <MoveUpIcon />
                    </IconButton>
                  </Tooltip>
                )}

                {deviceStatus === DEVICE_STATUS_IN_RESTORE_STEP.IN_TEMPLATE_DEVICE && dragDisabled && (
                  <Tooltip title="Pull from dropped container">
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        handleOnPullClick();
                      }}
                    >
                      <MoveUpIcon />
                    </IconButton>
                  </Tooltip>
                )}

                {showCheckBox && !isHidden && (
                  <Checkbox
                    size="small"
                    checked={checked}
                    onChange={e => handleCheckChange && handleCheckChange(e.target.checked)}
                    inputProps={{'aria-label': 'controlled'}}
                  />
                )}

                {deviceStatus === DEVICE_STATUS_IN_RESTORE_STEP.IN_TEMPLATE_DEVICE && isHidden && (
                  <IconButton onClick={() => onClickUnHide && onClickUnHide(device)}>
                    <VisibilityOffIcon />
                  </IconButton>
                )}
              </div>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Grid container spacing={1}>
              {!avaibleDevice && device && (
                <Grid item md={12} className={classes.dragContainer}>
                  <Box style={{alignSelf: 'center'}}>
                    <IconButton {...dragHandlerProps} size="large" disabled={dragDisabled}>
                      <DragIndicatorIcon />
                    </IconButton>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
);

export default Device;
