/* eslint-disable array-callback-return */
import {isArray} from 'lodash';

function toAPIFriendyUVInfoJson(
  uvs: {[cluster: string]: {[uvId: string]: any}},
  isFolderRestore: boolean,
  selectedUv = 'NEW_USERVIEW',
  compGroupInfo
): any {
  if (!isArray(Object.keys(uvs))) return null;

  const list = {};

  Object.keys(uvs).map(clusterId => {
    Object.keys(uvs[clusterId]).map(uvId => {
      if (!list[clusterId]) list[clusterId] = {};

      if (isFolderRestore) {
        const userviewToRestore = compGroupInfo.user_view_id;
        if (userviewToRestore !== uvId) {
          list[clusterId][uvId] = {
            new_id: '',
            new_dc_id: '',
            old_dc_id: '',
            old_id: uvId,
            clone_status: 'p',
            clone_type: 'disable',
          };
        } else {
          if (selectedUv === 'NEW_USERVIEW') {
            list[clusterId][uvId] = {
              new_id: '',
              new_dc_id: '',
              old_dc_id: '',
              old_id: uvId,
              clone_status: 'p',
              clone_type: 'create_new',
            };
          } else {
            list[clusterId][uvId] = {
              new_id: selectedUv,
              new_dc_id: '',
              old_dc_id: '',
              old_id: uvId,
              clone_status: 'p',
              clone_type: 'select_existing',
            };
          }
        }
      } else {
        list[clusterId][uvId] = {
          new_id: '',
          new_dc_id: '',
          old_dc_id: '',
          old_id: uvId,
          clone_status: 'p',
          clone_type: 'create_new',
        };
      }
    });
  });

  // uvs.map((uv) => {
  //   list[uv.id] = {
  //     new_id: '',
  //     new_dc_id: '',
  //     old_dc_id: '',
  //     old_id: uv.id,
  //     clone_status: 'p',
  //     clone_type: 'create_new',
  //   };
  //   return null;
  // });

  // return {
  //   0: { ...list },
  // };

  return list;
}

export default toAPIFriendyUVInfoJson;
