import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {pluginHook} from 'src/store/Plugins/PluginProvider';
import {useQueryStringFirst} from 'src/utility/CustomHooks/useQueryNumber';
import {useSnackbar} from 'notistack';
import {DateTimePicker} from '@mui/lab';
import {capitalize, isArray} from 'lodash';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Co2Icon from '@mui/icons-material/Co2';
import {
  EnergyGroup,
  LocationAllDevicesEnergyStatResponose,
  LocationEnergyDiffStatResponose,
  LocationEnergyStatResponose,
} from '../../services/cloud-integration.service';
import {StatCard} from './StatCard';
import {LocationEnergyMonitoringGraph} from './LocationEnergyMonitoringGraph';
import {LocationTotalEnergyGraph} from './LocationTotalEnergyGraph';
import {LocationCarbonMonitoring} from './LocationCarbonMonitoring';

function generateTimeRange(option: number) {
  let startTime: number;
  let endTime: number;
  let resolution: string;
  // "hour",   "day",   "week",   "month",   "quarter"
  switch (option) {
    case 1: // Last 24 hours
      startTime = moment().subtract(24, 'hours').unix();
      endTime = moment().unix();
      resolution = 'hour';
      break;
    case 2: // Last 7 days
      startTime = moment().subtract(7, 'days').unix();
      endTime = moment().unix();
      resolution = 'day';
      break;
    case 3: // Last 2 weeks
      startTime = moment().subtract(2, 'weeks').unix();
      endTime = moment().unix();
      resolution = 'day';
      break;
    case 4: // Last month
      startTime = moment().subtract(1, 'month').unix();
      endTime = moment().unix();
      resolution = 'week';
      break;
    default:
      throw new Error('Invalid option selected.');
  }

  return {startTime, endTime, resolution};
}

function getNextRange(
  date: moment.Moment,
  resolution: 'hour' | 'day' | 'week' | 'month' | 'quarter'
) {
  switch (resolution) {
    case 'hour':
      return {
        startTime: date.unix(),
        endTime: date.add(24, 'hour').unix(),
      };
    case 'day':
      return {
        startTime: date.unix(),
        endTime: date.add(7, 'day').unix(),
      };
    case 'week':
      return {
        startTime: date.unix(),
        endTime: date.add(4, 'week').unix(),
      };
    case 'month':
      return {
        startTime: date.unix(),
        endTime: date.add(12, 'month').unix(),
      };
    case 'quarter':
      return {
        startTime: date.unix(),
        endTime: date.add(2, 'quarter').unix(),
      };
    default:
      throw new Error('Invalid option selected.');
  }
}

function getPreviouseRange(
  date: moment.Moment,
  resolution: 'hour' | 'day' | 'week' | 'month' | 'quarter'
) {
  switch (resolution) {
    case 'hour':
      return {
        endTime: date.unix(),
        startTime: date.subtract(24, 'hour').unix(),
      };
    case 'day':
      return {
        endTime: date.unix(),
        startTime: date.subtract(7, 'day').unix(),
      };
    case 'week':
      return {
        endTime: date.unix(),
        startTime: date.subtract(4, 'week').unix(),
      };
    case 'month':
      return {
        endTime: date.unix(),
        startTime: date.subtract(12, 'month').unix(),
      };
    case 'quarter':
      return {
        endTime: date.unix(),
        startTime: date.subtract(2, 'quarter').unix(),
      };
    default:
      throw new Error('Invalid option selected.');
  }
}

function getTimeRange(
  resolution: 'hour' | 'day' | 'week' | 'month' | 'quarter',
  startDate: moment.Moment,
  isNextOrPreviouseRange: 'next' | 'previous'
) {
  let range: {
    startTime: number;
    endTime: number;
  } = null;
  if (isNextOrPreviouseRange === 'next') {
    range = getNextRange(startDate, resolution);
  } else {
    range = getPreviouseRange(startDate, resolution);
  }

  return range;
}

const AutoFilter: React.FC<{
  startTime: number;
  endTime: number;
  resolution: 'month' | 'week' | 'day' | 'hour' | 'quarter';
  setStartTime: (value: any) => void;
  setEndTime: (value: any) => void;
  onFilterChange: (payload: any) => void;
  setPressedChip: (value: any) => void;
  setResolution: (value: any) => void;
}> = ({
  startTime,
  endTime,
  resolution,
  setStartTime,
  setEndTime,
  onFilterChange,
  setPressedChip,
  setResolution,
}) => {
  return (
    <Box style={{display: 'flex', justifyContent: 'center'}}>
      <IconButton
        onClick={() => {
          const range = getTimeRange(resolution, moment.unix(startTime), 'previous');

          setStartTime(range.startTime);
          setEndTime(range.endTime);

          onFilterChange({
            startTime: range.startTime,
            endTime: range.endTime,
            resolution,
          });
          setPressedChip(undefined);
        }}
      >
        <Tooltip title="Previous">
          <ArrowBackIosIcon />
        </Tooltip>
      </IconButton>
      <FormControl style={{minWidth: 200}} size="small">
        <InputLabel>Range</InputLabel>
        <Select
          label="Range"
          value={resolution}
          onChange={e => {
            const newResolution = e.target.value as any;
            const range = getTimeRange(newResolution, moment(), 'previous');

            setStartTime(range.startTime);
            setEndTime(range.endTime);
            setResolution(newResolution);

            onFilterChange({
              startTime: range.startTime,
              endTime: range.endTime,
              resolution: newResolution,
            });
            setPressedChip(undefined);
          }}
        >
          <MenuItem value="hour">By Days</MenuItem>
          <MenuItem value="day">By Weeks</MenuItem>
          <MenuItem value="week">By Months</MenuItem>
          <MenuItem value="month">By Years</MenuItem>
          <MenuItem value="quarter">By 5 Years</MenuItem>
        </Select>
        {startTime && endTime && (
          <FormHelperText>
            {new Date(startTime * 1000).toDateString()} - {new Date(endTime * 1000).toDateString()}
          </FormHelperText>
        )}
      </FormControl>

      <IconButton
        onClick={() => {
          const range = getTimeRange(resolution, moment.unix(endTime), 'next');
          setStartTime(range.startTime);
          setEndTime(range.endTime);

          onFilterChange({
            startTime: range.startTime,
            endTime: range.endTime,
            resolution,
          });
          setPressedChip(undefined);
        }}
      >
        <Tooltip title="Next">
          <ArrowForwardIosIcon />
        </Tooltip>
      </IconButton>
    </Box>
  );
};

const LocationEnergyMonitoringFilters: React.FC<{
  onFilterChange: (payload: {startTime: number; endTime: number; resolution: string}) => void;
}> = ({onFilterChange}) => {
  const [advancedFilter, setAdvancedFilter] = useState(false);
  const [startTime, setStartTime] = useState<number>();
  const [endTime, setEndTime] = useState<number>();
  const [resolution, setResolution] = useState<'month' | 'week' | 'day' | 'hour' | 'quarter'>(
    'hour'
  );

  const [pressedChip, setPressedChip] = useState<number>();

  const setPredefinedRange = (type: number) => {
    const d = generateTimeRange(type);
    setStartTime(d.startTime);
    setEndTime(d.endTime);
    setResolution(d.resolution as 'month' | 'week' | 'day' | 'hour' | 'quarter');

    onFilterChange({
      startTime: d.startTime,
      endTime: d.endTime,
      resolution: d.resolution,
    });
    setPressedChip(type);
  };

  useEffect(() => {
    setPressedChip(1);
    setPredefinedRange(1);
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={12}>
          <Chip
            label="Last 24 hrs"
            color={pressedChip == 1 ? 'primary' : 'default'}
            style={{cursor: 'pointer', marginRight: 5}}
            onClick={() => {
              setPredefinedRange(1);
            }}
          />
          <Chip
            label="Last 7 days"
            color={pressedChip == 2 ? 'primary' : 'default'}
            style={{cursor: 'pointer', marginRight: 5}}
            onClick={() => {
              setPredefinedRange(2);
            }}
          />
          <Chip
            label="Last 2 weeks"
            color={pressedChip == 3 ? 'primary' : 'default'}
            style={{cursor: 'pointer', marginRight: 5}}
            onClick={() => {
              setPredefinedRange(3);
            }}
          />
          <Chip
            label="Last month"
            color={pressedChip == 4 ? 'primary' : 'default'}
            style={{cursor: 'pointer', marginRight: 5}}
            onClick={() => {
              setPredefinedRange(4);
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={advancedFilter}
                onChange={e => setAdvancedFilter(e.target.checked)}
                name="advancedFilter"
              />
            }
            label="Advanced Filter"
          />
        </Grid>
        <>
          {!advancedFilter ? (
            <Grid item md={12}>
              <AutoFilter
                startTime={startTime}
                endTime={endTime}
                resolution={resolution}
                setStartTime={setStartTime}
                setEndTime={setEndTime}
                setPressedChip={setPressedChip}
                setResolution={setResolution}
                onFilterChange={onFilterChange}
              />
            </Grid>
          ) : (
            <>
              <Grid item md={3}>
                <DateTimePicker
                  value={startTime ? new Date(startTime * 1000) : null}
                  onChange={(date: Date | null) => {
                    const startTime = date ? Math.floor(date.getTime() / 1000) : null;
                    setStartTime(startTime);
                  }}
                  renderInput={params => (
                    <TextField size="small" {...params} style={{width: '100%'}} />
                  )}
                />
              </Grid>
              <Grid item md={3}>
                <DateTimePicker
                  value={endTime ? new Date(endTime * 1000) : null}
                  onChange={(date: Date | null) => {
                    const endTime = date ? Math.floor(date.getTime() / 1000) : null;
                    setEndTime(endTime);
                  }}
                  renderInput={params => (
                    <TextField size="small" {...params} style={{width: '100%'}} />
                  )}
                />
              </Grid>
              <Grid item md={2}>
                <FormControl style={{width: '100%'}} size="small">
                  <InputLabel>Resolution</InputLabel>
                  <Select
                    label="Resolution"
                    value={resolution}
                    onChange={e => setResolution(e.target.value as any)}
                  >
                    {['hour', 'day', 'week', 'month', 'quarter'].map(res => (
                      <MenuItem key={res} value={res}>
                        {capitalize(res)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    onFilterChange({
                      startTime,
                      endTime,
                      resolution,
                    });
                    setPressedChip(undefined);
                  }}
                >
                  Apply Filters
                </Button>
              </Grid>
            </>
          )}
        </>
      </Grid>
    </>
  );
};

export const LocationEnergyMonitoringStats: React.FC<{
  selectedGroup: string;
  allGroups: EnergyGroup[];
}> = ({selectedGroup, allGroups}) => {
  const projectId = useQueryStringFirst('projectId');
  const {enqueueSnackbar} = useSnackbar();
  const [stat, setStat] = useState<LocationEnergyStatResponose>();
  const [stat5, setStat5] = useState<LocationAllDevicesEnergyStatResponose>();
  const [stat6, setStat6] = useState<LocationEnergyDiffStatResponose>();

  const [resolution, setResolution] = useState<'hour' | 'day' | 'week'>('hour');
  const [startDate, setStartDate] = useState<number>();
  const [endDate, setEndDate] = useState<number>();
  const [compareWithGroup, setCompareWithGroup] = useState<string[]>([]);

  const totalConsumption = stat?.result?.result?.total_consumption ?? 0;
  const totalCost = stat?.result?.result?.total_consumption_cost ?? 0;
  const fixedCost = stat?.result?.result?.fixed_charge_for_used_days ?? 0;
  const usedDays = stat?.result?.result?.used_days ?? 0;

  const diffTotalConsumption = stat6?.result?.total_consumption ?? {a: 0, b: 0, value: 0};
  const diffTotalCostConsumption = stat6?.result?.total_consumption_cost ?? {a: 0, b: 0, value: 0};

  const diffTotalConsumptionPercentatge = useMemo(
    () =>
      (
        (diffTotalConsumption.value * 100) /
        (diffTotalConsumption.a + diffTotalConsumption.b)
      ).toFixed(2),
    [diffTotalConsumption]
  );

  const diffTotalConsumptionCostPercentatge = useMemo(
    () =>
      (
        (diffTotalCostConsumption.value * 100) /
        (diffTotalCostConsumption.a + diffTotalCostConsumption.b)
      ).toFixed(2),
    [diffTotalCostConsumption]
  );

  const lastFilters = useRef<{startTime: number; endTime: number; resolution: string}>();

  const getState = async (payload: {startTime: number; endTime: number; resolution: string}) => {
    try {
      lastFilters.current = payload;
      const res = await pluginHook.commonIntegrationService.getLocationEnergyStats({
        ...payload,
        selectedGroup,
        projectId,
      });
      const res2 = await pluginHook.commonIntegrationService.getLocationAllDevicesEnergyStats({
        projectId,
        startTime: payload.startTime,
        endTime: payload.endTime,
      });
      setStat(res);
      setStat5(res2);
      setResolution(payload.resolution as any);
      setStartDate(payload.startTime);
      setEndDate(payload.endTime);

      if (compareWithGroup && compareWithGroup.length > 0) {
        getDiffState({
          ...lastFilters.current,
          group1: selectedGroup,
          group2: compareWithGroup,
        });
      }
    } catch (err) {
      enqueueSnackbar('Failed to get stats for the selected device');
    }
  };

  const getDiffState = async (payload: {
    startTime: number;
    endTime: number;
    resolution: string;
    group1: string;
    group2: string[];
  }) => {
    try {
      const res = await pluginHook.commonIntegrationService.getLocationEnergyDiffStats({
        ...payload,
        projectId,
      });
      setStat6(res);
    } catch (err) {
      enqueueSnackbar('Failed to get stats for the comparison');
    }
  };

  useEffect(() => {
    if (!compareWithGroup) return;

    if (compareWithGroup.length === 0) return;

    getDiffState({
      ...lastFilters.current,
      group1: selectedGroup,
      group2: compareWithGroup,
    });
  }, [compareWithGroup]);

  const group = useMemo(() => {
    return isArray(allGroups) ? allGroups.find(grp => grp._id === selectedGroup) : null;
  }, [allGroups, selectedGroup]);

  const isMainGroup = group && (group.group_type === 'dg' || group.group_type === 'all');

  return (
    <Box>
      <LocationEnergyMonitoringFilters onFilterChange={getState} />
      <Box height={20} />
      <Typography variant="h6">Statistics</Typography>
      <Grid container spacing={1}>
        <Grid item md={3} sm={12}>
          <StatCard
            title="Days"
            value={usedDays.toFixed(0)}
            unit="days"
            icon={<CalendarMonthIcon />}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <StatCard
            title="Total Consumption"
            value={totalConsumption.toFixed(2)}
            unit="kWh"
            icon={<EnergySavingsLeafIcon />}
          />
        </Grid>
        {isMainGroup && (
          <>
            <Grid item md={3} sm={12}>
              <StatCard
                title="Total Fixed Cost"
                value={fixedCost.toFixed(2)}
                unit="$"
                icon={<CalendarMonthIcon />}
              />
            </Grid>
            <Grid item md={3} sm={12}>
              <StatCard
                title="Total Cost"
                value={totalCost.toFixed(2)}
                unit="$"
                icon={<MonetizationOnIcon />}
              />
            </Grid>
          </>
        )}
      </Grid>
      {stat && stat.result?.result?.total_consumption_carbon ? (
        <>
          <Box height={20} />
          <Grid container spacing={1}>
            <Grid item md={12} style={{marginBottom: 10}}>
              <Grid container spacing={1}>
                <Grid item md={9} sm={12} style={{backgroundColor: '#F5F5F5'}}>
                  <LocationCarbonMonitoring
                    stat={stat}
                    resolution={resolution}
                    startUnix={moment.unix(startDate)}
                    endUnix={moment.unix(endDate)}
                  />
                </Grid>
                <Grid item md={3} sm={12}>
                  <div>
                    <StatCard
                      title="Carbon Emission"
                      value={stat?.result?.result?.total_consumption_carbon.toFixed(2)}
                      unit="g"
                      icon={<Co2Icon />}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={8} sm={12} style={{backgroundColor: '#F5F5F5'}}>
              {stat?.result?.result?.tota_consumption_graph && (
                <LocationEnergyMonitoringGraph
                  rawData={stat.result.result.tota_consumption_graph}
                  resolution={resolution}
                  startUnix={moment.unix(startDate)}
                  endUnix={moment.unix(endDate)}
                />
              )}
            </Grid>
            <Grid item md={4} sm={12} style={{backgroundColor: '#F5F5F5'}}>
              {stat?.result?.result?.total_stat && (
                <LocationTotalEnergyGraph rawData={stat?.result?.result?.total_stat} />
              )}
            </Grid>
            <Grid item md={4} sm={12}>
              {stat && (
                <Box style={{backgroundColor: '#F5F5F5', height: '100%', padding: 10}}>
                  <Box style={{display: 'flex'}}>
                    <Typography
                      style={{
                        marginTop: 15,
                        marginBottom: 10,
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      This shows the difference after the energy values are subtracted from the
                      monitoring group.
                    </Typography>
                  </Box>
                  <Box style={{display: 'flex'}}>
                    <FormControl size="small" style={{width: '100%'}}>
                      <InputLabel>Subtract</InputLabel>
                      <Select
                        label="Compare With"
                        multiple
                        value={compareWithGroup}
                        onChange={(e: any) => {
                          console.log(e.target.value);
                          let arr =
                            typeof e.target.value === 'string'
                              ? e.target.value.split(',')
                              : e.target.value;
                          if (arr.length === 0) {
                            setStat6(undefined);
                          }
                          setCompareWithGroup(arr);
                        }}
                        renderValue={selected =>
                          selected.map(id => allGroups.find(grp => grp._id === id).name).join(', ')
                        }
                      >
                        {allGroups.map(group => (
                          <MenuItem value={group._id}>
                            <Checkbox checked={compareWithGroup.indexOf(group._id) > -1} />
                            <ListItemText primary={group.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box style={{display: 'flex', marginTop: 10}}>
                    {stat6 && diffTotalConsumption && (
                      <Box style={{background: 'white', padding: '10px', marginRight: 10}}>
                        <Typography>Consumption Diff</Typography>
                        <Typography variant="h5">
                          {diffTotalConsumption.value.toFixed(2)} kWh
                        </Typography>
                        <Typography
                          style={{
                            color: Number(diffTotalConsumptionPercentatge) >= 0 ? 'red' : 'green',
                            fontWeight: 500,
                          }}
                        >
                          {Number(diffTotalConsumptionPercentatge) >= 0 ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )}
                          {diffTotalConsumptionPercentatge} %
                        </Typography>
                      </Box>
                    )}
                    {stat6 && diffTotalCostConsumption && (
                      <Box style={{background: 'white', padding: '10px'}}>
                        <Typography>Cost Diff</Typography>
                        <Typography variant="h5">
                          $ {diffTotalCostConsumption.value.toFixed(2)}{' '}
                        </Typography>
                        <Typography
                          style={{
                            color:
                              Number(diffTotalConsumptionCostPercentatge) >= 0 ? 'red' : 'green',
                            fontWeight: 500,
                          }}
                        >
                          {Number(diffTotalConsumptionCostPercentatge) >= 0 ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )}
                          {diffTotalConsumptionCostPercentatge} %
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  {stat6 && (
                    <Alert style={{marginTop: 10}} severity="info">
                      Above comparison shows the difference of the energy values once the monitoring
                      group is substracted from the above selected group/s.
                    </Alert>
                  )}
                </Box>
              )}
            </Grid>
            <Grid item md={8} sm={12}>
              {stat6 && (
                <Box style={{background: '#F5F5F5'}}>
                  {stat6?.result?.tota_consumption_graph && (
                    <LocationEnergyMonitoringGraph
                      rawData={stat6?.result?.tota_consumption_graph}
                      resolution={resolution}
                      diffMode
                      diffLabels={{
                        aPeak: 'Main Group (Peak)',
                        aOffPeak: 'Main Group (Off Peak)',
                        bOffPeak: 'Subtract Group (Off Peak)',
                        bPeak: 'Substract Group (Peak)',
                      }}
                      startUnix={moment.unix(startDate)}
                      endUnix={moment.unix(endDate)}
                    />
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
        </>
      ) : null}
      <Grid container spacing={1}>
        <Grid item md={12}>
          {isArray(stat?.result?.result?.reading_devices) && (
            <>
              <Typography style={{marginTop: 15, marginBottom: 10}} variant="h6">
                Usage Breakdown ( Based on monitoring groups )
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableCell>Device</TableCell>
                    <TableCell>Usage Breakdown</TableCell>
                    <TableCell>Cost Breakdown</TableCell>
                    <TableCell>Total Usage</TableCell>
                    <TableCell>Total Cost</TableCell>
                  </TableHead>
                  <TableBody>
                    {stat?.result?.result?.reading_devices?.map(device => {
                      const channelWise = device.fields;
                      return Object.keys(channelWise).map(cKey => {
                        const channel = channelWise[cKey];
                        return (
                          <TableRow>
                            <TableCell>
                              {device.info.name}/{channel.state_field}
                            </TableCell>
                            <TableCell>
                              {Object.keys(channel.pop).map(popKey => {
                                const pop = channel.pop[popKey];
                                return (
                                  <Box>
                                    <Typography variant="subtitle2">
                                      {capitalize(popKey)} :{pop.consumption.toFixed(3)} kWh
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </TableCell>
                            <TableCell>
                              {Object.keys(channel.pop).map(popKey => {
                                const pop = channel.pop[popKey];
                                return (
                                  <Box>
                                    <Typography variant="subtitle2">
                                      {capitalize(popKey)} : ${pop.cost.toFixed(2)}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2">
                                {channel.consumption.toFixed(3)} kWh
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2">
                                ${channel.cost.toFixed(2)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      });
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item md={12}>
          {isArray(stat5?.result?.result?.device_vis_usage) && (
            <>
              <Typography style={{marginTop: 15, marginBottom: 10}} variant="h6">
                Device Usage
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableCell>Device</TableCell>
                    <TableCell>Usage Breakdown</TableCell>
                    <TableCell>Cost Breakdown</TableCell>
                    <TableCell>Total Usage</TableCell>
                    <TableCell>Total Cost</TableCell>
                  </TableHead>
                  <TableBody>
                    {stat5?.result?.result?.device_vis_usage?.map(device => {
                      const channelWise = device.fields;
                      return Object.keys(channelWise).map(cKey => {
                        const channel = channelWise[cKey];
                        return (
                          <TableRow>
                            <TableCell>
                              {device.info.name}/{channel.state_field}
                            </TableCell>
                            <TableCell>
                              {Object.keys(channel.pop).map(popKey => {
                                const pop = channel.pop[popKey];
                                return (
                                  <Box>
                                    <Typography variant="subtitle2">
                                      {capitalize(popKey)} :{pop.consumption.toFixed(3)} kWh
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </TableCell>
                            <TableCell>
                              {Object.keys(channel.pop).map(popKey => {
                                const pop = channel.pop[popKey];
                                return (
                                  <Box>
                                    <Typography variant="subtitle2">
                                      {capitalize(popKey)} : ${pop.cost.toFixed(2)}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2">
                                {channel.consumption.toFixed(3)} kWh
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2">
                                ${channel.cost.toFixed(2)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      });
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
