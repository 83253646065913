import { COMPONENT_UNIT_TYPES } from "src/plugins/shared/item.config";
import { Component, Tag } from "src/plugins/shared/uvitem.config";

const commonTagFields = {
    type: COMPONENT_UNIT_TYPES.Sensor,
    main_key: null,
    command_key: null,
    can_send_command: false,
}

const ChargerTags: Record<string, Tag> = {
    chargeState__chargeRate: {
        tag: 'chargeState__chargeRate',
        description: 'Charge Rate',
        command_v: "v1",
        ...commonTagFields,
    },
    chargeState__maxCurrent: {
        tag: 'chargeState__maxCurrent',
        description: 'Max Current',
        command_v: "v1",
        ...commonTagFields,
    },
    chargeState__isPluggedIn: {
        tag: 'chargeState__isPluggedIn',
        description: 'Is PluggedIn',
        command_v: "v1",
        icon: 'minimize-2',
        ...commonTagFields,
    },
    chargeState__isCharging: {
        tag: 'chargeState__isCharging',
        description: 'Is Charging',
        command_v: "v1",
        icon: 'maximize-2',
        ...commonTagFields,
    },
    chargeState__powerDeliveryState: {
        tag: 'chargeState__powerDeliveryState',
        description: 'Power Delivery State',
        command_v: "v1",
        icon: 'trending-up',
        ...commonTagFields,
    },
}

const ChargerExtraTags: Record<string, Tag> = {
    chargingLoad: {
        tag: 'chargingLoad',
        description: 'Charging Load',
        display_suffix: 'kW',
        config: {
            decimal_points: 0,
        },
        command_v: 'v1',
        ...commonTagFields,
    },
    chargingCurrent: {
        tag: 'chargingCurrent',
        description: 'Charging Current',
        display_suffix: 'A',
        config: {
            decimal_points: 1,
        },
        command_v: 'v1',
        ...commonTagFields,
    },
    chargerButton: {
        tag: 'chargerButton',
        description: 'Power',
        command_v: 'v1',
        ...commonTagFields,
        type: COMPONENT_UNIT_TYPES.Switch,
        can_send_command: true,
    }
}

const config: Record<string, Component> = {
  charger_map_1: {
    id: 'charger_map_1',
    componentName: "Charger Map 1",
    created_at: '2023.04.27',
    default_inline_ui_data_map: null,
    default_inner_ui_data_map: null,
    ui_tags: ChargerTags,
    extra_ui_tags: null,
    item_type: 'enode.charger',
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {
        chargeState__chargeRate: {
          ui_component: 'CloudValueDisplayComponent1'
        },
        chargeState__maxCurrent: {
          ui_component: 'CloudValueDisplayComponent1'
        },
        chargeState__isPluggedIn: {
          ui_component: 'CloudValueDisplayComponent2'
        },
        chargeState__isCharging: {
          ui_component: 'CloudValueDisplayComponent2'
        },
        chargeState__powerDeliveryState: {
          ui_component: 'CloudValueDisplayComponent2'
        },
      }, // TODO: to be filled by @Mayantha
    },
  }
}

export default config;