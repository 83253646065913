import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import {useContext, useEffect, useState} from 'react';
import Layout from 'src/components/layout/Layout';
import {PageHeader} from 'src/components/layout/PageHeader';
import {AuthContext} from 'src/store/Auth/AuthContext';
import {useQueryStringFirst} from 'src/utility/CustomHooks/useQueryNumber';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '94%',
    margin: '15px auto',
  },
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    margin: '0%',
    overflow: 'auto',
  },
}));

export const ItemGroups = () => {
  const classes = useStyles();
  const authUser = useContext(AuthContext);
  const projectId = useQueryStringFirst('projectId');

  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getItemGroupList();
  }, []);

  async function getItemGroupList() {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]}/rest/com-groups/v1/list`,
        {
          project_id: projectId,
        },
        {
          headers: {
            Authorization: `Bearer ${authUser?.access_token?.token}`,
          },
        }
      );
      setGroups(Array.isArray(res.data?.result) ? res.data?.result : []);
    } catch (err) {}
  }

  return (
    <Layout>
      <PageHeader>
        <Box className={classes.headerRoot}>
          <Typography className={classes.headerTitle}>Userview Item Groups</Typography>
        </Box>
      </PageHeader>
      <Box className={classes.contentRoot}>
        <Table className={classes.root}>
          <TableHead>
            <TableRow style={{ backgroundColor: '#7F8FA4' }}>
              <TableCell>Group ID</TableCell>
              <TableCell>Group Name</TableCell>
              <TableCell>Folder ID</TableCell>
              <TableCell>Last Updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.map((group: any) => (
              <TableRow>
                <TableCell>{group.id}</TableCell>
                <TableCell>{group.name}</TableCell>
                <TableCell>{group.root_folder_id}</TableCell>
                <TableCell>{group.updated_at}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Layout>
  );
};
