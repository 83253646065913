/**
 * Copyright (C) Smartlife 2021 The Smartlife Connet Project.
 *
 * The main header component of the web application. The header component
 * mainly handle location selection from the top drop down and handle logout.
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define the Header component of the Smarlife connect web app
 * @author Mayantha
 * @since 1.0.0
 */

import {IconButton, Menu, MenuItem, Backdrop, Hidden} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import {KeyboardArrowDown, NotificationsNone} from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {debounce} from 'lodash';
import {useLocation, useNavigate} from 'react-router-dom';
import DehazeIcon from '@mui/icons-material/Dehaze';
import TextField from '@mui/material/TextField';
import {APP_TEXT} from '../../../utility/AppTexts/DefaultAppText.utils';
import {appConsoleLog, AppUtils, getInitial} from '../../../utility/appUtils';
import {ApolloAuthContext} from '../../../store/Apollo/ApolloContext';
import {AuthContext} from '../../../store/Auth/AuthContext';
import FirebaseAuthFunctions from '../../../store/Auth/providers/FirebaseAuthFunctions';
import styles from './Header.module.css';
import {
  projectGetGraphQL,
  searchProjectsGraphQL,
} from '../../../services/location/location.service';
import {ProjectContext} from '../../../store/Project/ProjectContext';
import useQueryString from '../../../utility/CustomHooks/useQueryString';
import {RootState, useSelector} from '../../../store/redux/store';
import {Project} from '../../../store/redux/features/project/types';
import ResendLink from './components/ResendLink';
import LocationMenu from './components/LocationMenu';
import {useDispatch} from 'react-redux';
import {getUserviewItemGroups, getUvDeviceMapJson} from 'src/store/redux/features/project-userview-device-map/async-thunks';

const useStyle = makeStyles(theme => ({
  dropDown: {
    textTransform: 'capitalize',
  },
  avatar: {
    color: 'white',
    backgroundColor: '#673AB7',
  },
  backdrop: {
    zIndex: 1, // theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

type ComponentProps = {
  navOnClick: any;
};

/**
 * The React component which use as Header component of the app.
 *
 * @param {Object}    ComponentProps            Component props
 * @param {Function}  ComponentProps.navOnClick Callback function which fires when click
 *                                              on back button
 * @returns JSX.Element
 */
const HeaderComponent: React.FunctionComponent<ComponentProps> = ({navOnClick}) => {
  const navigate = useNavigate();
  const matStyle = useStyle();
  const user = useContext(AuthContext);
  const userProfile = useContext(ApolloAuthContext);
  const selectedProjectID = useQueryString('projectId') as string;
  const location = useLocation();
  const projectState = useSelector<
    RootState,
    {
      [key: string]: Project;
    }
  >(state => state.projects.projects);

  const filterRef = React.useRef();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElProject, setAnchorElProject] = React.useState<null | HTMLElement>(null);
  // Project selector
  const [options, setOptions] = useState<any>([]);
  const selectedProject = useContext(ProjectContext);
  // Get the project data if selectedProjectId is available or changed
  useEffect(() => {
    async function init() {
      if (selectedProjectID) getProjectByID(selectedProjectID);
    }
    init();
  }, [selectedProjectID]);

  const previousProjectId = useRef<string | null>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (previousProjectId.current === selectedProjectID) return;

    if (!previousProjectId.current && selectedProjectID) {
      dispatch(getUvDeviceMapJson({projectId: selectedProjectID}));
      dispatch(getUserviewItemGroups(selectedProjectID));
      previousProjectId.current = selectedProjectID;
    }
  }, [selectedProjectID]);

  // Update the location dropdown items if project list changed
  useEffect(() => {
    if (!projectState) return;
    const arr = [];
    Object.keys(projectState).map(key => arr.push(projectState[key]));
    setOptions(arr);
  }, [projectState]);

  /**
   * Location function which save the given project information to the context
   *
   * @param {String}  id            id of the selected project
   * @param {String}  title         title of the selected project
   * @param {String}  description   description of the selected project
   * @param {String}  image         image of the selected project
   * @param {String}  projectStatus project status of the selected project
   * @param {String}  projectType   project type of the selected project
   * @param {String}  accessLevel   access level of the selected project
   * @param {Boolean} demoMode      demo mode of the selected project
   * @param {String}  restoreBsid   backup id of the selected project
   */
  const projectSelector = async (
    id: string,
    title: string,
    description: string,
    image: string,
    projectStatus: string,
    projectType: string,
    accessLevel: string,
    demoMode: boolean,
    restoreBsid: string
  ) => {
    await selectedProject.dispatch({
      selected_project: {
        id,
        title,
        description,
        image,
        project_status: projectStatus,
        project_type: projectType,
        access_level: accessLevel,
        demo_mode: demoMode,
        restore_bsid: restoreBsid,
      },
    });
    navigateRoute(`/app/project/dashboard?projectId=${id}`);
  };

  /**
   * Get project by id and set to the context as selected project
   * @param {String} projectId Id of the location/project
   * @returns Promise
   */
  const getProjectByID = async (projectId: string) => {
    try {
      const res = await projectGetGraphQL(userProfile?.apollo_client, projectId);
      const {project} = res.projectGet;

      await selectedProject.dispatch({
        selected_project: {
          id: project.id,
          title: project.name,
          description: project.description,
          project_country_code: project.project_country_code,
          project_timezone: project.project_timezone,
          image: project.image,
          project_status: project.project_status,
          project_type: project.project_type,
          access_level: project.access_level,
          demo_mode: project.demo_mode,
          restore_bsid: project.restore_bsid,
        },
      });
    } catch (err) {
      appConsoleLog(err);
    }
  };

  const debouncedGetSelectorData = useCallback(
    debounce((keyword: string) => getSelectorData(keyword), 300),
    []
  );

  const getSelectorData = async (keyword: any) => {
    const res = await searchProjectsGraphQL(userProfile?.apollo_client, keyword, 5, 0, 1, -1);
    const tempArray: any[] = [];
    res.projectSearch.result.forEach((loc: any) => {
      tempArray.push(loc);
    });
    setOptions(tempArray);
  };

  /**
   * Calls when user type on input field
   * @param {HtmlInputEvent} e  Html input onchange event
   */
  const handleProjectSearch = (e: any) => {
    debouncedGetSelectorData(e.target.value);
  };

  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    setAnchorElProject(null);
    navigate(path);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickProjectSelect = (event: React.MouseEvent<HTMLButtonElement>) => {
    // setLocationList([]);
    setAnchorElProject(event.currentTarget);
  };

  const handleSignOut = async () => {
    await FirebaseAuthFunctions.LogoutCurrentUser();
    window.location.href = '/app';
  };

  const stopPropagation = (e: any) => {
    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowUp':
      case 'Home':
      case 'End':
        break;
      default:
        e.stopPropagation();
    }
  };

  const moveFocusToInput = (e: any) => {
    if (e.key === 'Tab' || e.key === 'ArrowRight') {
      e.stopPropagation();
      e.preventDefault();
      (filterRef?.current as any).focus();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElProject(null);
  };

  return (
    <>
      {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
      <div className={styles.root}>
        <Hidden smUp>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <div className={styles.menuIcon} onClick={navOnClick} role="button" tabIndex={0}>
            <DehazeIcon />
          </div>
        </Hidden>

        <div
          role="button"
          aria-label="Navigate App"
          tabIndex={0}
          className={styles.logoImage}
          onClick={() => {
            // navigateRoute("/app");
            window.location.href = '/app';
          }}
          onKeyDown={e => {
            if (e.key === '13') {
              window.location.href = '/app';
            }
          }}
        />

        <div className={styles.flexSpacer}>
          <div className={styles.headerBar}>
            <div className={styles.leftItem}>
              {location.pathname !== '/app' && location.pathname !== '/app/all-projects' && (
                <LocationMenu setAnchorEl={setAnchorElProject} selectedProject={selectedProject} />
              )}
            </div>
          </div>

          {!user?.email_verified && <ResendLink />}
        </div>
        <div className={styles.profileTag}>
          <Avatar
            className={matStyle.avatar}
            src={AppUtils.getStorageBucketFileUrl('photo', userProfile.user_profile.profile_photo)}
          >
            {getInitial(userProfile.user_profile.user_name)}
          </Avatar>
          <Button
            color="primary"
            className={styles.userNameBtn}
            endIcon={<KeyboardArrowDown />}
            onClick={handleClick}
          >
            {/* {userProfile?.user_profile?.user_name} */}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() =>
                navigateRoute(
                  selectedProjectID ? `/app/profile?projectId=${selectedProjectID}` : '/app/profile'
                )
              }
            >
              {APP_TEXT.header.PROFILE}
            </MenuItem>
            <MenuItem onClick={handleSignOut}>{APP_TEXT.header.SIGN_OUT}</MenuItem>
          </Menu>
        </div>
        <Menu
          className={styles.projectSelectorMenu}
          autoFocus={false}
          disableAutoFocusItem
          id="simple-menu"
          anchorEl={anchorElProject}
          keepMounted
          open={Boolean(anchorElProject)}
          onClose={handleClose}
          PaperProps={{
            style: {
              marginTop: '85px',
              // marginLeft: "-320px",
            },
          }}
        >
          <MenuItem className={styles.searchField} onKeyDown={moveFocusToInput}>
            <TextField
              style={{width: '100%'}}
              placeholder="Enter project name.."
              className={styles.fieldSpace}
              size="small"
              variant="outlined"
              onKeyUp={handleProjectSearch}
              onKeyDown={stopPropagation}
            />
          </MenuItem>
          {options.map((item: any) => {
            // if (!projectState) return;
            const val: any = item; // projectState[id] as any;
            return (
              <MenuItem
                className={styles.menuItem}
                key={`project-id-${val.id}`}
                onClick={() => {
                  // console.log(val.image);
                  projectSelector(
                    val.id,
                    val.name,
                    val.description,
                    val.image,
                    val.access_level,
                    val.project_type,
                    val.project_status,
                    val.dome_mode,
                    val.restore_bsid
                  );
                }}
              >
                <img
                  className={styles.menuCardImage}
                  src={
                    val.image && val.image.length > 0
                      ? AppUtils.getStorageBucketFileUrl('photo', val.image)
                      : 'https://storage.googleapis.com/smartlife-pro2-develop.appspot.com/images/img_2Uz44tX55WUzDYZ9aGvgm32VYAdDMyRe78GD6wN81620959969703.jpeg'
                  }
                  alt=""
                />
                <p className={styles.menuCardTitle}>{val.name}</p>
              </MenuItem>
            );
          })}
        </Menu>
        <Backdrop open={Boolean(anchorElProject)} className={matStyle.backdrop}>
          <Menu
            className={styles.projectSelectorMenuMobile}
            autoFocus={false}
            disableAutoFocusItem
            id="simple-menu"
            anchorEl={anchorElProject}
            keepMounted
            open={Boolean(anchorElProject)}
            onClose={handleClose}
          >
            <MenuItem
              // button={false}
              className={styles.searchField}
              onKeyDown={moveFocusToInput}
            >
              <TextField
                style={{width: '100%'}}
                placeholder="Enter project name.."
                className={styles.fieldSpace}
                size="small"
                variant="outlined"
                onKeyUp={handleProjectSearch}
                onKeyDown={stopPropagation}
              />
            </MenuItem>
            {options.map((item: any) => {
              // if (!projectState) return;
              const val = item; // projectState[id];
              return (
                <MenuItem
                  className={styles.menuItem}
                  key={val.id}
                  onClick={() => {
                    // console.log(val.image);
                    projectSelector(
                      val.id.toString(),
                      val.name,
                      val.description,
                      val.image,
                      val.access_level.toString(),
                      val.project_type.toString(),
                      val.project_status.toString(),
                      val.demo_mode,
                      val.restore_bsid
                    );
                  }}
                >
                  <img
                    className={styles.menuCardImage}
                    src={
                      val.image && val.image.length > 0
                        ? `https://storage.googleapis.com/smartlife-pro2-develop.appspot.com/images/${val.image}`
                        : 'https://storage.googleapis.com/smartlife-pro2-develop.appspot.com/images/img_2Uz44tX55WUzDYZ9aGvgm32VYAdDMyRe78GD6wN81620959969703.jpeg'
                    }
                    alt=""
                  />
                  <p className={styles.menuCardTitle}>{val.name}</p>
                </MenuItem>
              );
            })}
          </Menu>
        </Backdrop>
        <div className={styles.notificationBtn}>
          <IconButton size="large">
            <NotificationsNone />
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default HeaderComponent;
