/* eslint-disable camelcase */
import {
  Avatar,
  TextField,
  Button,
  Box,
  Autocomplete,
  FormHelperText,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {CloudUpload, Save} from '@mui/icons-material';
import clsx from 'clsx';
import * as Yup from 'yup';
import {Formik} from 'formik';
import React, {useContext, useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';
import axios from 'axios';
import FirebaseAuthFunctions from 'src/store/Auth/providers/FirebaseAuthFunctions';
import {APP_TEXT} from '../../../utility/AppTexts/DefaultAppText.utils';
import {ApolloAuthContext, profileReducerTypes} from '../../../store/Apollo/ApolloContext';
import {LoadingComponent} from '../../../components/shared/Loading/Loading';
import styles from './Profile.module.css';
import {
  ErrorSnackbarOptions,
  ErrorSnackbarActions,
  SuccessSnackbarActions,
  SuccessSnackbarOptions,
} from '../../../components/Alters/Snackbar/SnackbarOptions';
import FormSubmitButton from '../../../components/shared/buttons/FormSubmitButton/FormSubmitButton';
import {UserAuthFunctions} from '../../../store/Auth/providers/UserAuthFunctions';
import {appConsoleLog, AppUtils, getInitial} from '../../../utility/appUtils';
import {AuthContext} from '../../../store/Auth/AuthContext';
import {UVConfirmDialog} from '../../../components/UserViewComponents/UIItems/Dialogs/UVConfirmDialog/UVConfirmDialog';
import {deleteLoggedInUser} from '../../../services/member/member.service';
import {CountryCodes} from '../../../config/countryCodes';
import {PageHeader} from 'src/components/layout/PageHeader';
import Layout from 'src/components/layout/Layout';

type IProfileFormValue = {
  name: string;
  phone_number: string;
  user_address: string;
  user_city: string;
};

const useStyle = makeStyles((theme: any) => ({
  largAvatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  avatar: {
    color: 'white',
    backgroundColor: '#673AB7',
  },
}));

export const ProfileComponent: React.FunctionComponent<any> = () => {
  const matStyle = useStyle();
  const userProfile = useContext(ApolloAuthContext);
  const user = useContext(AuthContext);
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [deleteState, setDeleteStatus] = useState(false);
  let uploadRef: HTMLInputElement | null;

  console.log(userProfile);

  const [country_code_iso, setCountryCodeIso] = useState<{code: string; name: string}>({
    name: 'New Zealand',
    code: 'NZ',
  });

  useEffect(() => {
    if (userProfile && userProfile.user_profile && userProfile.user_profile.user_country_code) {
      setCountryCodeIso({
        name: userProfile.user_profile.user_country,
        code: userProfile.user_profile.user_country_code,
      });
    }
  }, [userProfile]);

  const onFileChange = async (event: any) => {
    appConsoleLog(event);
    setUploading(true);
    try {
      const file = event.target.files[0];
      if (!file) {
        throw new Error('Please select valid image file');
      }
      const axiosInstace = axios.create({
        baseURL: process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0],
        headers: {
          Authorization: `Bearer ${user.access_token.token}`,
        },
      });

      const imageId = await UserAuthFunctions.updateUserProfilePhotoRest(
        axiosInstace,
        file,
        '/rest/media/v1/image/upload'
      );
      // eslint-disable-next-line max-len
      // const imageId = await UserAuthFunctions.updateUserProfilePhoto(user.access_token.token, file);
      appConsoleLog(imageId);
      snackbar.enqueueSnackbar(APP_TEXT.profile.PROFILE_PHOTO_UPDATED, {
        key: 'profile-file-success',
        ...SuccessSnackbarOptions,
        action: SuccessSnackbarActions(snackbar.closeSnackbar),
      });
      setUploading(false);
      userProfile.dispatch({
        type: profileReducerTypes.UPDATE_USER_PHOTO,
        payload: imageId,
      });
    } catch (err: any) {
      setUploading(false);
      appConsoleLog(err);
      snackbar.enqueueSnackbar(err, {
        key: 'profile-file-error',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
  };
  const selectNewPhoto = () => {
    if (uploadRef) {
      uploadRef.click();
    }
  };

  const submitUpdateProfile = async (
    values: IProfileFormValue,
    {resetForm, setErrors, setStatus, setSubmitting}: any
  ) => {
    if (userProfile.user_profile.id === -1) {
      return;
    }
    setLoading(true);
    try {
      await UserAuthFunctions.updateUserProfile(
        userProfile?.apollo_client,
        values.name,
        values.phone_number.trim(),
        values.user_address.trim(),
        country_code_iso.name,
        country_code_iso.code,
        values.user_city.trim()
      );

      userProfile.dispatch({
        type: profileReducerTypes.UPDATE_PROFILE,
        payload: {
          user_profile: {
            user_name: values.name,
            user_contact_no1: values.phone_number.trim(),
            user_city: values.user_city.trim(),
            user_address: values.user_address.trim(),
            user_country: country_code_iso.name,
            user_country_code: country_code_iso.code,
          },
        },
      });

      snackbar.enqueueSnackbar(APP_TEXT.profile.PROFILE_UPDATED, {
        key: 'profile-update-success',
        ...SuccessSnackbarOptions,
        action: SuccessSnackbarActions(snackbar.closeSnackbar),
      });
    } catch (err: any) {
      appConsoleLog(err.message);
      snackbar.enqueueSnackbar(err.message, {
        key: 'profile-update-error',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
    setLoading(false);
  };

  const handleSignOut = async () => {
    await FirebaseAuthFunctions.LogoutCurrentUser();
    window.location.href = '/app';
  };

  const deleteDeviceConfirm = async () => {
    try {
      const {id, deleted_status, message}: any = await deleteLoggedInUser(
        userProfile?.apollo_client
      );
      snackbar.enqueueSnackbar(message, {variant: 'success'});
      handleSignOut();
    } catch (err: any) {
      snackbar.enqueueSnackbar(err.message, {variant: 'error'});
    }
  };

  let profileBgStyle: React.CSSProperties = {
    backgroundImage: "url('../../../../assets/images/photo-1507003211169-0a1dd7228f2d.jpg')",
  };

  if (userProfile.user_profile.profile_photo) {
    profileBgStyle = {
      backgroundImage: `url("${AppUtils.getStorageBucketFileUrl(
        'photo',
        userProfile.user_profile.profile_photo
      )}")`,
    };
  }

  return (
    <Layout>
      <PageHeader>
        <Box className={styles.headerRoot}>
          <Typography variant='h5' className={styles.headerTitle}>{APP_TEXT.profile.PROFILE}</Typography>
        </Box>
      </PageHeader>
      <Box className={styles.contentRoot}>
        <div className={`${styles.innerPageContent} pageContent`}>
          <div className={styles.photoForm}>
            <div className={styles.profilePohoto}>
              <Avatar
                className={clsx(matStyle.avatar, matStyle.largAvatar)}
                src={AppUtils.getStorageBucketFileUrl(
                  'photo',
                  userProfile.user_profile.profile_photo
                )}
              >
                {getInitial(userProfile.user_profile.user_name)}
              </Avatar>
              <div className={styles.uploadButton}>
                <input
                  type="file"
                  className={styles.uploadInput}
                  onChange={onFileChange}
                  // eslint-disable-next-line no-return-assign
                  ref={ref => (uploadRef = ref)}
                  accept="image/x-png,image/gif,image/jpeg"
                />
                <FormSubmitButton
                  text={APP_TEXT.profile.UPLOAD_NEW_PHOTO}
                  onClick={selectNewPhoto}
                  disable={uploading}
                >
                  {uploading ? <LoadingComponent size={20} /> : <CloudUpload />}
                </FormSubmitButton>
              </div>
            </div>
          </div>
          <div className={styles.profileForm}>
            <Formik
              initialValues={{
                name: userProfile.user_profile.user_name || '',
                phone_number: userProfile.user_profile.user_contact_no1 || '',
                user_address: userProfile.user_profile.user_address || '',
                user_city: userProfile.user_profile.user_city || '',
              }}
              enableReinitialize
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .required('Required')
                  .min(3, 'Name must be at least 3 characters'),
                phone_number: Yup.string()
                  .required('Please enter your phone number.')
                  .matches(
                    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                    'Phone number is not valid'
                  ),
              })}
              onSubmit={submitUpdateProfile}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                submitForm,
              }) => (
                <form autoComplete="off">
                  <div className={styles.emailText}>{userProfile?.user_profile?.email || ''}</div>
                  <div className="row col-lg-12">
                    <div className="col-lg-6 mt-3">
                      <TextField
                        fullWidth
                        className={styles.fieldSpace}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        name="name"
                        label={APP_TEXT.profile.NAME}
                        size="small"
                        variant="outlined"
                      />
                    </div>
                    <div className="col-lg-6 mt-3">
                      <TextField
                        fullWidth
                        className={styles.fieldSpace}
                        error={Boolean(touched.phone_number && errors.phone_number)}
                        helperText={touched.phone_number && errors.phone_number}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.phone_number}
                        name="phone_number"
                        label={APP_TEXT.auth.PHONE_NUMBER}
                        size="small"
                        variant="outlined"
                      />
                    </div>
                    <div className="col-lg-6 mt-3">
                      <TextField
                        fullWidth
                        className={styles.fieldSpace}
                        error={Boolean(touched.user_address && errors.user_address)}
                        helperText={touched.user_address && errors.user_address}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.user_address}
                        name="user_address"
                        label={APP_TEXT.auth.ADDRESS}
                        size="small"
                        variant="outlined"
                      />
                    </div>
                    <div className="col-lg-6 mt-3">
                      <TextField
                        fullWidth
                        className={styles.fieldSpace}
                        error={Boolean(touched.user_city && errors.user_city)}
                        helperText={touched.user_city && errors.user_city}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.user_city}
                        name="user_city"
                        label={APP_TEXT.auth.CITY}
                        size="small"
                        variant="outlined"
                      />
                    </div>
                    <div className="col-lg-6 mt-3">
                      <FormHelperText>Country</FormHelperText>
                      <Autocomplete
                        size="small"
                        onChange={(_, value) => {
                          setCountryCodeIso(value as any);
                        }}
                        value={country_code_iso}
                        options={CountryCodes}
                        getOptionLabel={(option: any) => option.name}
                        renderInput={params => (
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          <TextField
                            {...params}
                            variant="outlined"
                            type="text"
                            autoComplete="off"
                          />
                        )}
                      />
                    </div>
                    <div className="col-lg-12 nopadding">
                      <div className="col-lg-2 mt-3">
                        <FormSubmitButton
                          text={APP_TEXT.profile.UPDATE}
                          onClick={submitForm}
                          disable={loading}
                        >
                          {loading ? <LoadingComponent size={20} /> : <Save />}
                        </FormSubmitButton>
                      </div>
                      <div className="col-lg-2 mt-3">
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => setDeleteStatus(true)}
                        >
                          Delete User
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className={styles.buttonBar}>
                    <Box width={10} />
                    <UVConfirmDialog
                      dialogOpen={deleteState}
                      dialogCloseEvent={(e: any) => {
                        setDeleteStatus(false);
                        if (e === true) {
                          deleteDeviceConfirm();
                        }
                      }}
                      title="Confirm to delete current user ?"
                      text={
                        "This will delete user profile only if this user don't have any locations/projects"
                      }
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Box>
    </Layout>
  );

  return (
    <div className={`${styles.root} main-content`}>
      <div className="pageHeader">{APP_TEXT.profile.PROFILE}</div>
      <div className={`${styles.innerPageContent} pageContent`}>
        <div className={styles.photoForm}>
          <div className={styles.profilePohoto}>
            <Avatar
              className={clsx(matStyle.avatar, matStyle.largAvatar)}
              src={AppUtils.getStorageBucketFileUrl(
                'photo',
                userProfile.user_profile.profile_photo
              )}
            >
              {getInitial(userProfile.user_profile.user_name)}
            </Avatar>
            <div className={styles.uploadButton}>
              <input
                type="file"
                className={styles.uploadInput}
                onChange={onFileChange}
                // eslint-disable-next-line no-return-assign
                ref={ref => (uploadRef = ref)}
                accept="image/x-png,image/gif,image/jpeg"
              />
              <FormSubmitButton
                text={APP_TEXT.profile.UPLOAD_NEW_PHOTO}
                onClick={selectNewPhoto}
                disable={uploading}
              >
                {uploading ? <LoadingComponent size={20} /> : <CloudUpload />}
              </FormSubmitButton>
            </div>
          </div>
        </div>
        <div className={styles.profileForm}>
          <Formik
            initialValues={{
              name: userProfile.user_profile.user_name || '',
              phone_number: userProfile.user_profile.user_contact_no1 || '',
              user_address: userProfile.user_profile.user_address || '',
              user_city: userProfile.user_profile.user_city || '',
            }}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Required').min(3, 'Name must be at least 3 characters'),
              phone_number: Yup.string()
                .required('Please enter your phone number.')
                .matches(
                  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                  'Phone number is not valid'
                ),
            })}
            onSubmit={submitUpdateProfile}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              submitForm,
            }) => (
              <form autoComplete="off">
                <div className={styles.emailText}>{userProfile?.user_profile?.email || ''}</div>
                <div className="row col-lg-12">
                  <div className="col-lg-6 mt-3">
                    <TextField
                      fullWidth
                      className={styles.fieldSpace}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      name="name"
                      label={APP_TEXT.profile.NAME}
                      size="small"
                      variant="outlined"
                    />
                  </div>
                  <div className="col-lg-6 mt-3">
                    <TextField
                      fullWidth
                      className={styles.fieldSpace}
                      error={Boolean(touched.phone_number && errors.phone_number)}
                      helperText={touched.phone_number && errors.phone_number}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phone_number}
                      name="phone_number"
                      label={APP_TEXT.auth.PHONE_NUMBER}
                      size="small"
                      variant="outlined"
                    />
                  </div>
                  <div className="col-lg-6 mt-3">
                    <TextField
                      fullWidth
                      className={styles.fieldSpace}
                      error={Boolean(touched.user_address && errors.user_address)}
                      helperText={touched.user_address && errors.user_address}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.user_address}
                      name="user_address"
                      label={APP_TEXT.auth.ADDRESS}
                      size="small"
                      variant="outlined"
                    />
                  </div>
                  <div className="col-lg-6 mt-3">
                    <TextField
                      fullWidth
                      className={styles.fieldSpace}
                      error={Boolean(touched.user_city && errors.user_city)}
                      helperText={touched.user_city && errors.user_city}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.user_city}
                      name="user_city"
                      label={APP_TEXT.auth.CITY}
                      size="small"
                      variant="outlined"
                    />
                  </div>
                  <div className="col-lg-6 mt-3">
                    <FormHelperText>Country</FormHelperText>
                    <Autocomplete
                      size="small"
                      onChange={(_, value) => {
                        setCountryCodeIso(value as any);
                      }}
                      value={country_code_iso}
                      options={CountryCodes}
                      getOptionLabel={(option: any) => option.name}
                      renderInput={params => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <TextField {...params} variant="outlined" type="text" autoComplete="off" />
                      )}
                    />
                  </div>
                  <div className="col-lg-12 nopadding">
                    <div className="col-lg-2 mt-3">
                      <FormSubmitButton
                        text={APP_TEXT.profile.UPDATE}
                        onClick={submitForm}
                        disable={loading}
                      >
                        {loading ? <LoadingComponent size={20} /> : <Save />}
                      </FormSubmitButton>
                    </div>
                    <div className="col-lg-2 mt-3">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setDeleteStatus(true)}
                      >
                        Delete User
                      </Button>
                    </div>
                  </div>
                </div>

                <div className={styles.buttonBar}>
                  <Box width={10} />
                  <UVConfirmDialog
                    dialogOpen={deleteState}
                    dialogCloseEvent={(e: any) => {
                      setDeleteStatus(false);
                      if (e === true) {
                        deleteDeviceConfirm();
                      }
                    }}
                    title="Confirm to delete current user ?"
                    text={
                      "This will delete user profile only if this user don't have any locations/projects"
                    }
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ProfileComponent;
