import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { ListRouts } from '@smartlife/common-ui';
import AirDeviceCreateEdit from './containers/app/AirDeviceCreateEdit/AirDeviceCreateEdit';
import BackupCreate from './containers/app/Backups/BackupCreate';
import BackupList from './containers/app/Backups/BackupList';
import SessionRestore from './containers/app/Backups/components/Restore/SessionRestore';
import SessionList from './containers/app/Backups/components/Restore/SessionList';
import TemplateCreate from './containers/app/Backups/TemplateCreate';
import CreateEditDeviceContainerComponent from './containers/app/DeviceContainers/CreateEditDeviceContainer/CreateEditDeviceContainer';
import ListDeviceContainersComponent from './containers/app/DeviceContainers/ListDeviceContainers/ListDeviceContainers';
import SingleContainerAirComponent from './containers/app/DeviceContainers/SingleContainerAir/SingleContainerAir';
import SingleContainerProComponent from './containers/app/DeviceContainers/SingleContainerPro/SingleContainerPro';
import SingleDeviceComponent from './containers/app/DeviceContainers/SingleDevice/SingleDevice';
import CreateRule from './containers/app/LogicsEngine/CreateRule';
import RuleList from './containers/app/LogicsEngine/RuleList';
import { LogsContainer } from './containers/app/Logs/LogView';
import AddEditMemberComponent from './containers/app/Members/AddEditMember/AddEditMember';
import ListMembersComponent from './containers/app/Members/ListMembers/ListMembers';
import ItemDefaultsComponent from './containers/app/ProDeviceCreateEdit/context/ItemDefaultsContext';
import ProDeviceCreateEdit from './containers/app/ProDeviceCreateEdit/ProDeviceCreateEdit';
import ProfileComponent from './containers/app/Profile/Profile';
import { AllProjectsComponent } from './containers/app/Project/AllProjects/AllProjects';
import CreateEditCustomProjectComponent from './containers/app/Project/CreateEditCustomProject/CreateEditCustomProject';
import CreateProjectProjectTypeSelectorComponent from './containers/app/Project/CreateProjectProjectTypeSelector/CreateProjectProjectTypeSelector';
import { ProjectDashboardComponent } from './containers/app/Project/ProjectDashboard/NewDashboard/ProjectDashboard';
import ProjectSelectorComponent from './containers/app/Project/ProjectSelector/ProjectSelector';
import TagsComponent from './containers/app/Tags/Tags';
import CreateEditUserViewComponent from './containers/app/UserViews/CreateEditUserView/CreateEditUserView';
import CreateIntefaceTypeSelectorComponent from './containers/app/UserViews/CreateIntefaceTypeSelector/CreateIntefaceTypeSelector';
import ListUserViewsComponent from './containers/app/UserViews/ListUserViews/ListUserViews';
import SingleUserViewComponent from './containers/app/UserViews/SingleUserView/SingleUserView';
import { AuthPage } from './containers/authentication/AuthPage/AuthPage';
import AuthGuard from './guards/AuthGuard';
import GuestGuard from './guards/GuestGuard';
import AppLayout from './layout/AppLayout';
import SessionRestorePre from './containers/app/Backups/components/Restore/SessionRestorePre';
import HubFinder from './containers/app/HubFinder/HubFinder';
import DashboardLayout from './layout/Dashboard';
import ListGrideView from './containers/app/Lists/ListsGridView';
import CreateNewList from './containers/app/Lists/CreateNewList';
import ListSignleView from './containers/app/Lists/ListSingleView';
import ListEntryForm from './containers/app/Lists/forms/ListEntryForm';
import ListSetting from './containers/app/Lists/forms/SettingForm';
import ListLayout from './layout/ListLayout';
import SingleContainerCloudIntegration from './containers/app/DeviceContainers/SingleContainerCloudIntegration/SingleContainerCloudIntegration';
import { EnodeDeviceCallback } from './plugins/enode.plugin/ui/DeviceCallback';
import { pluginHook } from './store/Plugins/PluginProvider';
import { EnergyMonitoring } from './plugins/shared/react-components/EnergyMonitoring';
import { HubStats } from './containers/app/HubStats';
import { ItemGroups } from './containers/app/UserViews/ItemGroups';

const SignUpForm = lazy(() => import('./containers/authentication/SignupForm/SignupForm'));
const LoginForm = lazy(() => import('./containers/authentication/Login/LoginForm'));
const ForgetPassword = lazy(
  () => import('./containers/authentication/ForgotPassword/ForgotPassword'),
);
const LoginWithEmail = lazy(
  () => import('./containers/authentication/LoginWithEmailLink/LoginWithEmailLink'),
);

const CloudDeviceEdit = pluginHook.sharedUiComponents.CloudDeviceEditUi;

const AppRoutes = () => (
  <AuthGuard>
    <AppLayout>
      <Routes>
        <Route path="/" element={<ProjectSelectorComponent />} />
        <Route path="/profile" element={<ProfileComponent />} />
        <Route path="/all-projects" element={<AllProjectsComponent />} />
        <Route path="/create-project" element={<CreateProjectProjectTypeSelectorComponent />} />
        <Route path="/create-custom-project" element={<CreateEditCustomProjectComponent />} />
        <Route path="/edit-custom-project" element={<CreateEditCustomProjectComponent />} />
        <Route path="/energy" element={<EnergyMonitoring />} />
        <Route path="/select-inteface-type" element={<CreateIntefaceTypeSelectorComponent />} />
        <Route path="/project">
          <Route path="dashboard" element={<ProjectDashboardComponent />} />
        </Route>
        <Route path="/members" element={<ListMembersComponent />} />
        <Route path="/member">
          <Route path="update" element={<AddEditMemberComponent />} />
          <Route path="create" element={<AddEditMemberComponent />} />
        </Route>
        <Route path="/user-views" element={<ListUserViewsComponent />} />
        <Route path="/user-view">
          <Route index element={<SingleUserViewComponent />} />
          <Route path="create" element={<CreateEditUserViewComponent />} />
          <Route path="update" element={<CreateEditUserViewComponent />} />
        </Route>
        <Route path="/item-groups" element={<ItemGroups />} />
        <Route path="/device-containers" element={<ListDeviceContainersComponent />} />
        <Route path="device-container">
          <Route path="create" element={<CreateEditDeviceContainerComponent />} />
          <Route path="update" element={<CreateEditDeviceContainerComponent />} />
          <Route path="device" element={<SingleDeviceComponent />} />
          <Route path="air" element={<SingleContainerAirComponent />} />
          <Route path="pro" element={<SingleContainerProComponent />} />
          <Route path="cloud-integration" element={<SingleContainerCloudIntegration />} />
          <Route
            path="pro/update/device"
            element={(
              <ItemDefaultsComponent>
                <ProDeviceCreateEdit />
              </ItemDefaultsComponent>
            )}
          />
          <Route path="air/update/device" element={<AirDeviceCreateEdit />} />
          <Route path="cloud-int/update/device" element={<CloudDeviceEdit integration={pluginHook.commonIntegrationService} />} />
        </Route>
        <Route path="tags" element={<TagsComponent />} />
        <Route
          path="pro-devices"
          element={(
            <ItemDefaultsComponent>
              <ProDeviceCreateEdit />
            </ItemDefaultsComponent>
          )}
        />
        <Route path="logs" element={<LogsContainer />} />
        <Route path="hub-finder" element={<HubFinder />} />
        <Route path="hub-stats" element={<HubStats />} />
        <Route path="backup-restore">
          <Route index element={<BackupList />} />
          <Route path="create" element={<BackupCreate />} />
          <Route path="edit" element={<BackupCreate />} />
          <Route path="universal-templates/create" element={<TemplateCreate />} />
          <Route path="universal-templates/edit" element={<TemplateCreate />} />
          <Route path="restore-sessions/list" element={<SessionList />} />
          <Route path="restore-sessions/pre-screen" element={<SessionRestorePre />} />
          <Route path="restore-sessions/restoring" element={<SessionRestore />} />
        </Route>
        <Route path="logics">
          <Route index element={<RuleList />} />
          <Route path="create" element={<CreateRule />} />
          <Route path="edit" element={<CreateRule />} />
        </Route>
        <Route path="list">
          <Route index element={<ListGrideView />} />
          <Route path={`${ListRouts.LIST_CREATE_SELECT_TYPE}`} element={<CreateNewList />} />
          <Route path={ListRouts.LIST_VIEW} element={<ListSignleView />} />
          <Route path={ListRouts.LIST_ADD_ITEM} element={<ListEntryForm />} />
          <Route path={ListRouts.LIST_VIEW_SETTING} element={<ListSetting />} />
        </Route>
      </Routes>
    </AppLayout>
  </AuthGuard>
);

const AuthRoutes = () => (
  <>
    <GuestGuard>
      <AuthPage>
        <Routes>
          <Route path="signup" element={<SignUpForm />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="forgotPassword" element={<ForgetPassword />} />
          <Route path="loginWithEmailLink" element={<LoginWithEmail />} />
          <Route path="/" element={<Navigate to="/app" />} />
        </Routes>
      </AuthPage>
    </GuestGuard>
  </>
);

const AppRouter = () => (
  <Routes>
    <Route path="app/*" element={<AppRoutes />} />
    <Route path="/*" element={<AuthRoutes />} />
    <Route path="enode-cb" element={<EnodeDeviceCallback />} />
  </Routes>
);

export default AppRouter;
