import {Box, Button, Input, TextField, Typography} from '@mui/material';
// import '@patternfly/react-core/dist/styles/base.css'; // do not use this css file as it breaks the styles of other components

import {LogViewer, LogViewerSearch} from '@patternfly/react-log-viewer';
import firebase from 'firebase';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useFirebaseConnect} from 'react-redux-firebase';
import {pluginHook} from 'src/store/Plugins/PluginProvider';
import {RootState} from 'src/store/redux/store';
import {useQueryStringFirst} from 'src/utility/CustomHooks/useQueryNumber';

const DATA = {
  '-NihAQBxa1El_WgPsrtu': {
    status: 'STARTED',
    total_logics: 4,
    t: 1699422005,
  },
  '-NihARjItcoyw34NZlSd': {
    logic_id: 'G7i8KTJYViQxc_uXY9QQgxTuyaMCYz',
    name: 'svar2 become trigger 4',
    status: 'UPDATED_DONE',
    t: 1699422005,
  },
  '-NihARjN-LivjbpZWHs7': {
    logic_id: 'G7i8KTJYViQxc_sNMBNBVETuyaE2wz',
    name: 'svar2 become trigger 2',
    status: 'UPDATED_DONE',
    t: 1699422005,
  },
  '-NihARvDD1Xfi3TVu2bA': {
    logic_id: 'G7i8KTJYViQxc_GQgHNxkFTuyuIfoz',
    name: 'test check',
    status: 'UPDATED_DONE',
    t: 1699422005,
  },
  '-NihARvFoqr6xANiz4xj': {
    logic_id: 'G7i8KTJYViQxc_FkHf24teTuyadJ4z',
    name: 'when svar Trigger 4 notification',
    status: 'UPDATED_DONE',
    t: 1699422005,
  },
  '-NihAQG6LSafLEJOxe6r': {
    status: 'DONE_ALL_LOGICS',
    t: 1699422005,
  },
};

const serviceLogColors = {
  smLogic: `\u001b[38;2;255;68;255m`,
};

const statusColors = {
  info: `\u001b[38;5;255m`,
  error: `\u001b[38;5;9m`,
};

const buildConsoleLogLine = ({
  message,
  serviceId,
  buildGroupUid,
  instanceId,
  timestamp,
  isError,
}) => {
  const build = buildGroupUid ? `${buildGroupUid} ` : '';
  return `\u001b[38;2;155;155;155m${timestamp} ${
    serviceLogColors[serviceId]
  }${build}${instanceId} ${isError ? statusColors.error : statusColors.info}${message}`;
};

const getParsedLog = (
  logs: Record<
    string,
    {
      logic_id?: string;
      name?: string;
      status?: string;
      t: number;
    }
  >
) => {
  // convert logs to array
  const logsArr = Object.values(logs);
  return logsArr.reduce((acc, cur) => {
    const beautifyMessage = JSON.stringify(cur); // JSON.stringify(cur, null, 2);
    acc.push(
      buildConsoleLogLine({
        message: beautifyMessage,
        serviceId: 'smLogic',
        buildGroupUid: 'logic',
        instanceId: cur.logic_id || '',
        timestamp: moment.unix(cur.t).format('YYYY-MM-DD HH:mm:ss'),
        isError: cur.status === 'ERROR' ? true : false,
      })
    );

    return acc;
  }, []);
};

export const LogicRefresh = () => {
  const projectId = useQueryStringFirst('projectId');
  useFirebaseConnect('location_update/' + projectId);

  const rawLogs = useSelector(
    (state: RootState) =>
      (state.firebase.data?.location_update && state.firebase.data.location_update[projectId]) || {}
  );
  const {enqueueSnackbar} = useSnackbar();
  const [newTag, setNewTag] = useState('2023nov9');
  const logs = useMemo(() => {
    return getParsedLog(rawLogs || {});
  }, [rawLogs]);

  const tagInitialized = useRef(false);
  useEffect(() => {
    if (tagInitialized.current) return;
    const startedMsgKey = Object.keys(rawLogs).find(key => rawLogs[key]?.status === 'STARTED');
    const log = rawLogs[startedMsgKey];

    if (log?.tag) {
      setNewTag(log.tag);
      tagInitialized.current = true;
    }
  }, [rawLogs]);

  const onRefresh = () => {
    try {
      tagInitialized.current = false;
      const axios = pluginHook.commonIntegrationService.axiosClient;

      axios.post('/project/logics/logics-update-to-latest', {
        project_id: projectId,
        tag: newTag,
      });
      enqueueSnackbar('Start logic refresh', {variant: 'success'});
    } catch (err: any) {
      enqueueSnackbar(err.message, {variant: 'error'});
    }
  };

  return (
    <Box style={{margin: '2% 0', backgroundColor: '#151515', fontFamily: 'monospace', fontSize: '12.5px',}}>
      <LogViewer
        hasLineNumbers={false}
        height={500}
        data={logs}
        isTextWrapped={false}
        toolbar={
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              backgroundColor: 'white',
              paddingBottom: 5,
            }}
          >
            <Typography variant="h6">Logics Update to latest version</Typography>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                label="Tag"
                size="small"
                value={newTag}
                onChange={e => setNewTag(e.target.value)}
                placeholder="tag"
                style={{ marginRight: 5 }}
              />
              <Button size="small" variant="contained" color="primary" onClick={() => onRefresh()}>
                Proceed
              </Button>
            </Box>
          </Box>
        }
      />
    </Box>
  );
};
