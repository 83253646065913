/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 * @author Maduka Dilshan
 */
import { useState, useEffect, useContext } from 'react';
import FormControl from '@mui/material/FormControl';
import makeStyles from '@mui/styles/makeStyles';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Box from '@mui/material/Box';
import {
  Skeleton,
  Button,
  FormControlLabel,
  TextField,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Divider,
  LinearProgress,
  Alert,
} from '@mui/material';
import Input from '@mui/material/Input';

import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

import { DatePicker as KeyboardDatePicker } from '@mui/lab';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import axios from 'axios';
import parseCustomApiError from 'src/utility/parseError';
import fileDownload from 'src/utility/fileDownload';
import { AuthContext } from 'src/store/Auth/AuthContext';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import { ProjectContext } from '../../../../store/Project/ProjectContext';
import { userviewSearchGraphQL } from '../../../../services/user-view/user-view.service';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  root1: {
    display: 'flex',
    alignItems: 'center',
  },
  formControl: {
    minWidth: 180,
    // maxWidth: 180,
    padding: '5px',
  },
  selectEmpty: {
    marginTop: '2%',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      width: 400,
    },
  },
};

export enum EventLogTypes {
  system = 'system',
  user_chat = 'user_chat',
  user_event = 'user_event',
  device_event = 'device_event',
  logic_event = 'logic_event',
  ml_detection = 'ml_detection',
  user_cmd = 'user_cmd',
}

export enum UserViewItemNotificationType {
  NoNotification = 1,
  TextOnly = 2,
  TextWithPushNotification = 3,
  TextWithPushNotificationAndAlertSound = 4,
  TextWithPushNotificationAndWarningSound = 5,
}

const UVNotifiList = [
  { label: 'No Notification', value: 1 },
  { label: 'Text Only', value: 2 },
  { label: 'Text with push notifi', value: 3 },
  { label: 'Text with push notifi & alert sound', value: 4 },
  { label: 'Text with push notifi & warning sound', value: 5 },
];

const DownloadReport: React.FC<{projectId: string; token; minDate: Date; maxDate: Date}> = ({
  projectId,
  token,
  minDate,
  maxDate,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const classes = useStyles();
  const [selectedMinDate, setSeletedMinState] = useState(minDate);
  const [selectedMaxDate, setSeletedMaxState] = useState(maxDate);
  const [page, setPage] = useState(0);
  const [fullReport, setFullReport] = useState(true);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [batchData, setBatchData] = useState('');

  const [totalRecords, setTotalRecord] = useState(1);

  useEffect(() => {
    setSeletedMaxState(maxDate);
    setSeletedMinState(minDate);
  }, [minDate, maxDate]);

  const getReport = async () => {
    let startDate = 0;
    let endDate = 0;

    if (!fullReport) {
      const unix = moment(selectedMinDate).unix();
      if (unix) {
        startDate = unix;
      }
      const unix1 = moment(selectedMaxDate).unix();
      if (unix1) {
        endDate = unix1;
      }
    }
    try {
      setLoading(true);
      const url = '/rest/event-log/v1/export-logs/project-report/download';
      const response = await axios.post(
        process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0] + url,
        {
          project_id: projectId,
          start_date: startDate,
          end_date: endDate,
          page,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      // console.log('content length', response.headers);
      if (response.headers['content-length'] === '0') {
        enqueueSnackbar(
          'Server responded with empty document. Possibly no events logged in selected range',
          { variant: 'info' },
        );
        return;
      }
      if (response.headers && response.data) {
        const pageLimit = Number(response.headers['page-limit']);
        const totalRecord = Number(response.headers['total-records']);
        setTotalRecord(totalRecord);

        if (totalRecord >= pageLimit * (page + 1)) {
          setPage((p) => p + 1);
          setBatchData((data) => data + response.data);
        } else {
          const filenameFromHeaders = response.headers['log-file-name'];
          const name = filenameFromHeaders.split('.log')[0];
          fileDownload(
            batchData + response.data,
            `${name}__log-summary-project-id-${projectId}-${
              !fullReport
                ? `${moment(selectedMinDate).format('MM-DD-YYYY')} - ${moment(
                  selectedMaxDate,
                ).format('MM-DD-YYYY')}`
                : 'full-report'
            }.log`,
          );
        }
      }
    } catch (err: any) {
      const msg = parseCustomApiError(err.response && err.response.data);
      enqueueSnackbar(msg, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (page > 0) {
      getReport();
    }
  }, [page]);

  return (
    <>
      <Tooltip title="Download Log Report">
        <IconButton size="small" onClick={() => setShowDialog(true)}>
          <FileDownloadIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={showDialog} maxWidth="md" PaperProps={{ style: { width: 500 } }}>
        <DialogTitle>
          <Typography variant="h5">Select an option</Typography>
          {loading && (
            <>
              <LinearProgress />
              {' '}
              {totalRecords === 1
                ? 0
                : Math.round(((page + 1) * 5000 * 100) / totalRecords) > 100
                  ? 100
                  : Math.round(((page + 1) * 5000 * 100) / totalRecords)}
              {' '}
              %
            </>
          )}
        </DialogTitle>
        <DialogContent>
          <Box>
            <Alert severity="info">
              Downloading saves all records in selected time range. Other Filters from the table
              won't apply.
            </Alert>
          </Box>
          <Box>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={fullReport}
                    onChange={() => {
                      setFullReport(!fullReport);
                      setTotalRecord(1);
                    }}
                  />
                )}
                label="All Logs"
              />
            </FormControl>
          </Box>
          {selectedMinDate && selectedMaxDate && (
            <Box>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!fullReport}
                      onChange={() => {
                        setFullReport(!fullReport);
                        setTotalRecord(1);
                      }}
                    />
                  )}
                  label={`${selectedMinDate.toLocaleDateString()}:${selectedMinDate.toLocaleTimeString()} to ${selectedMaxDate.toLocaleDateString()}:${selectedMaxDate.toLocaleTimeString()}`}
                />
              </FormControl>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={() => setShowDialog(false)}>
            Cancel
          </Button>
          <Button disabled={loading} onClick={getReport}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const LogsFilter: React.FC<{
  onFilterChange: (
    uvs: number[],
    min_date: any,
    max_date: any,
    exclude_types: string[],
    notifi_types: number[]
  ) => void;
}> = ({ onFilterChange }) => {
  const classes = useStyles();
  const [allUvs, setAllUvs] = useState([]);
  const [uvIds, setUvIds] = useState([]);
  const [excluedEventTypes, setExcludeEventTypes] = useState([]);
  const [notifiList, setNotifiList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const auth = useContext(AuthContext);
  const [customDateRange, setCustomDateRange] = useState(false);
  const [selectedMinDate, setSeletedMinState] = useState(null);
  const [selectedMaxDate, setSeletedMaxState] = useState(null);
  const [isDirty, setIsDirty] = useState(false);

  const userProfile = useContext(ApolloAuthContext);
  const selectedProject = useContext(ProjectContext);

  const [isLoading, setLoading] = useState(false);

  const [dateInputKey, setDateInputKey] = useState(1);

  const handleChange = (event) => {
    setIsDirty(true);
    setUvIds(event.target.value);
  };

  const handleTypeChange = (event) => {
    setIsDirty(true);
    setExcludeEventTypes(event.target.value);
  };

  const handleNotifiChange = (event) => {
    setIsDirty(true);
    setNotifiList(event.target.value);
  };

  const handleCustomDateRange = () => {
    setIsDirty(true);
    if (customDateRange) {
      setSeletedMinState(null);
      setSeletedMaxState(null);
      setDateInputKey((current) => current + 1);
    }
    setCustomDateRange(!customDateRange);
  };

  const handleMinDateChange = (date) => {
    setIsDirty(true);
    setSeletedMinState(date);
  };

  const handleMaxDateChange = (date) => {
    setIsDirty(true);
    setSeletedMaxState(date);
  };

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const res = await userviewSearchGraphQL(
          userProfile.apollo_client,
          selectedProject.selected_project.id,
          1,
          '',
          100,
          0,
        );
        if (!res.userviewSearch) throw new Error('Failed to get user views');
        setAllUvs(res.userviewSearch.result);
      } catch (err: any) {
        enqueueSnackbar(err.message, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };

    if (
      selectedProject
      && selectedProject.selected_project
      && selectedProject.selected_project.id
    ) {
      init();
    } else {
      // TODO: Close the dialog or move backward
    }
  }, []);

  return (
    <>
      <Box mt={3} className={classes.root}>
        <Box>
          <FormControl className={classes.formControl} variant="outlined" size="small">
            <InputLabel>UserView IDs</InputLabel>
            <Select
              label="UserView IDs"
              multiple
              value={uvIds}
              onChange={handleChange}
              // input={<Input />}
              disableUnderline
              renderValue={(selected: any) => {
                const arr = [];
                selected.map((selectedone) => {
                  const uv = allUvs.find((item) => item.id === selectedone);
                  arr.push(uv.name);
                  return null;
                });
                // const uv: any = allUvs.find((item) => item.id == selected);
                return arr.join(', ');
              }}
              MenuProps={MenuProps}
            >
              {isLoading ? (
                <Skeleton variant="rectangular" height={50} />
              ) : (
                allUvs.map((uv) => (
                  <MenuItem key={uv.id} value={uv.id}>
                    <Checkbox checked={uvIds.indexOf(uv.id) > -1} />
                    <ListItemText primary={uv.name} />
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl} variant="outlined" size="small">
            <InputLabel>Exclude Events</InputLabel>
            <Select
              label="Exclude Events"
              multiple
              value={excluedEventTypes}
              onChange={handleTypeChange}
              // input={<Input />}
              disableUnderline
              renderValue={(selected: any) => {
                const arr = [];
                selected.map((selectedone) => {
                  const type = excluedEventTypes.find((item) => item === selectedone);
                  arr.push(type);
                  return null;
                });
                // const uv: any = allUvs.find((item) => item.id == selected);
                return arr.join(', ');
              }}
              MenuProps={MenuProps}
            >
              {Object.keys(EventLogTypes).map((type) => (
                <MenuItem key={type} value={EventLogTypes[type]}>
                  <Checkbox checked={excluedEventTypes.indexOf(EventLogTypes[type]) > -1} />
                  <ListItemText primary={type} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl} variant="outlined" size="small">
            <InputLabel>Only</InputLabel>
            <Select
              label="Only"
              multiple
              value={notifiList}
              onChange={handleNotifiChange}
              // input={<Input />}
              disableUnderline
              renderValue={(selected: any) => {
                const arr = [];
                selected.map((selectedone) => {
                  // const type = notifiList.find(
                  //   (item) => item == selectedone
                  // );

                  const found = UVNotifiList.find((item) => item.value === selectedone);
                  arr.push(found.label);
                  return null;
                });
                // const uv: any = allUvs.find((item) => item.id == selected);
                return arr.join(', ');
              }}
              MenuProps={MenuProps}
            >
              {UVNotifiList.map((notifi) => (
                <MenuItem key={notifi.value} value={notifi.value}>
                  <Checkbox checked={notifiList.indexOf(notifi.value) > -1} />
                  <ListItemText primary={notifi.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Button
            onClick={() => {
              onFilterChange(
                uvIds,
                customDateRange && selectedMinDate ? selectedMinDate : -1,
                customDateRange && selectedMaxDate ? selectedMaxDate : -1,
                excluedEventTypes,
                notifiList,
              );
              setIsDirty(false);
            }}
            color="primary"
            variant="contained"
            disabled={!isDirty}
          >
            Apply Filters
          </Button>
          <DownloadReport
            token={auth.access_token.token}
            projectId={selectedProject.selected_project.id}
            minDate={selectedMinDate}
            maxDate={selectedMaxDate}
          />
        </Box>
      </Box>
      <Box className={classes.root1}>
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={<Checkbox checked={customDateRange} onChange={handleCustomDateRange} />}
            label="Custom Date Range"
          />
        </FormControl>
        <FormControl className={classes.formControl} variant="outlined" size="small">
          <TextField
            id="datetime-local"
            size="small"
            variant="outlined"
            label="From"
            type="datetime-local"
            disabled={!customDateRange}
            onChange={(e) => {
              const date = new Date(e.target.value);
              handleMinDateChange(date);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            key={dateInputKey}
          />
        </FormControl>
        <FormControl className={classes.formControl} variant="outlined" size="small">
          <TextField
            id="datetime-local"
            size="small"
            variant="outlined"
            label="To"
            type="datetime-local"
            onChange={(e) => {
              const date = new Date(e.target.value);
              handleMaxDateChange(date);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={!customDateRange}
            InputProps={{
              inputProps: {
                min: selectedMinDate && selectedMinDate.toISOString().slice(0, 16),
              },
            }}
            key={dateInputKey}
          />
        </FormControl>
      </Box>
    </>
  );
};
