/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import {
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useState, useEffect} from 'react';

import {AirDevice, ProDevice} from '@smartlife-redux-state/common';

export enum SINGLE_VARIABLE_DEVICES {}

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  between_root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export const AirVariable: React.FC<{
  air_device: AirDevice;
  behaveAs: string;
  selectedVariable: {field: string; type: string};
  hideTypeSelector?: boolean;
  onChange: (selectedFiled: string, type: string) => void;
}> = ({air_device, behaveAs, selectedVariable, hideTypeSelector, onChange}) => {
  const classes = useStyles();
  const [isSingleVar, setIsSingleVar] = useState<boolean>(null);

  useEffect(() => {
    // determine the device type
    const {device_type} = air_device;
    let found = false;
    Object.keys(SINGLE_VARIABLE_DEVICES).map(key => {
      if (SINGLE_VARIABLE_DEVICES[key] === device_type) {
        found = true;
      }
      return key;
    });
    setIsSingleVar(found);
  }, [air_device]);

  function renderVariableFields(isSingle: boolean) {
    const fields = [];
    if (isSingle) {
      fields.push({
        label: 'Online',
        value: 'online',
        type: 'Number',
      });
    } else if (air_device.shadow_type === 'cloudIntegration') {
      let settings: any = air_device.settings ? JSON.parse(air_device.settings) : null;
      if (typeof settings === 'string') {
        settings = JSON.parse(settings);
      }
      if (!settings) throw new Error('Device setting is null');
      const functions: {[key: string]: any} = settings.sf;
      if (functions) {
        for (const [key, val] of Object.entries(functions)) {
          if (behaveAs === 'valueAssigner') {
            if (val.command) {
              fields.push({
                label: `[${val.t}] - ${val.command}`,
                value: val.command,
                type: val.t,
              });
            }
          } else {
            fields.push({
              label: `[${val.t}] - ${val.k}`,
              value: val.k,
              type: val.t,
            });
          }
        }
      }
    } else {
      let settings: any =
        typeof air_device.settings === 'string'
          ? JSON.parse(air_device.settings)
          : air_device.settings && typeof air_device.settings === 'object'
          ? air_device.settings
          : null;

      if (typeof settings === 'string') {
        settings = JSON.parse(settings);
      }

      if (!settings) throw new Error('Device setting is null');

      const functions: {[key: string]: any} = settings.selected_functions;
      if (functions) {
        for (const [key, val] of Object.entries(functions)) {
          fields.push({
            label: val.name,
            value: val.code,
            type: val.type === 'Integer' ? 'Number' : val.type,
          });
        }
      }
      fields.push({
        label: 'Online',
        value: 'online',
        type: 'Number',
      });
    }

    return (
      <FormControl size="small" variant="outlined" className={classes.formControl}>
        <InputLabel>Variable</InputLabel>
        <Select
          label="Variable"
          value={selectedVariable.field}
          onChange={e => {
            // setSelectedField(e.target.value as string);
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const field = fields.find(field => field.value === e.target.value);
            onChange(
              e.target.value as string,
              // eslint-disable-next-line no-nested-ternary
              field && field.type
                ? field.type
                : selectedVariable && selectedVariable.type
                ? selectedVariable.type
                : 'string'
            );
          }}
          startAdornment={<InputAdornment position="start">{'State '}</InputAdornment>}
        >
          {fields.map((field, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem value={field.value} key={index}>
              {field.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <Grid container spacing={2}>
      {isSingleVar != null && (
        <Grid item xs={12} md={8}>
          {isSingleVar ? renderVariableFields(true) : renderVariableFields(false)}
        </Grid>
      )}
      <Grid item xs={12} md={4}>
        <FormControl size="small" variant="outlined" className={classes.formControl}>
          {/* <InputLabel>Possible Var Type</InputLabel> */}
          <Select
            disabled={hideTypeSelector}
            // label="Possible Var Type"
            value={selectedVariable.type}
            onChange={(e: any) => {
              onChange(selectedVariable.field, e.target.value);
            }}
          >
            <MenuItem value="Number">Number</MenuItem>
            <MenuItem value="String">String</MenuItem>
            <MenuItem value="Boolean">Boolean</MenuItem>
            <MenuItem value="Enum">Enum</MenuItem>
          </Select>
        </FormControl>
        <FormHelperText>* Possible Variable Type</FormHelperText>
      </Grid>
    </Grid>
  );
};
