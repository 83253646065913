import { gql } from '@apollo/client';

export const PRO_DC_GET_QUERY = gql`
  query ProDCGet($prodc_id: String) {
    proDCGet(prodc_id: $prodc_id) {
      proDeviceCollection {
        id
        name
        description
        image
        dc_type
        dc_status
        serial_name
        firmware_version
        online_date_time
        ip
      }
    }
  }
`;

export const PRO_DC_SEARCH_QUERY = gql`
  query ProDCSearch(
    $project_id: String
    $dc_type: Int
    $dc_status: Int
    $keyword: String
    $limit: Int
    $skip: Int
  ) {
    proDCSearch(
      project_id: $project_id
      dc_type: $dc_type
      dc_status: $dc_status
      keyword: $keyword
      limit: $limit
      skip: $skip
    ) {
      limit
      skip
      resultCount
      result {
        id
        serial_name
        name
        description
        dc_type
        dc_status
      }
    }
  }
`;

export const PRO_DEVICE_SEARCH = gql`
  query ProDeviceSearch(
    $project_id: String
    $prodc_id: String
    $shadow_type: String
    $cat: String
    $sub_cat: String
    $device_type: String
    $device_status: Int
    $tags: [String]
    $system_tags: [String]
    $keyword: String
    $limit: Int
    $skip: Int
  ) {
    proDeviceSearch(
      project_id: $project_id
      prodc_id: $prodc_id
      shadow_type: $shadow_type
      cat: $cat
      sub_cat: $sub_cat
      device_type: $device_type
      device_status: $device_status
      tags: $tags
      system_tags: $system_tags
      keyword: $keyword
      limit: $limit
      skip: $skip
    ) {
      limit
      skip
      resultCount
      result {
        id
        created_by
        project_id
        dc_id
        name
        icon
        tags
        display_type
        device_type
        device_status
        notify_status
        notify_sound
        settings_json
        created_at
        updated_at
        comp_group_id
      }
    }
  }
`;

export const PRO_DC_CREATE_MUTATION = gql`
  mutation ProDCCreate($input: ProDeviceCollectionIn) {
    proDCCreate(input: $input) {
      id
      name
    }
  }
`;

export const PRO_DC_UPDATE_MUTATION = gql`
  mutation ProDCUpdate($input: ProDeviceCollectionUpdateIn) {
    proDCUpdate(input: $input) {
      id
      update_status
      updated_at
    }
  }
`;

export const PRO_DC_DELETE_MUTATION = gql`
  mutation ProDCDelete($item_id: String) {
    proDCDelete(item_id: $item_id) {
      id
      delete_status
    }
  }
`;

export const PRO_DC_CLEAR_MUTATION_V2 = gql`
  mutation DeleteAllDeviceInHub($status: Int!, $proDcId: String!) {
    DeleteAllDeviceInHub(status: $status, pro_dc_id: $proDcId) {
      status
      ref_id
    }
  }
`;

export const PRO_DC_ENABLE_DISABLE_ALL_DEVICES_V2 = gql`
  mutation EnableDisableAllDeviceInCollection($status: Int!, $proDcId: String!) {
    EnableDisableAllDeviceInCollection(status: $status, pro_dc_id: $proDcId) {
      status
      ref_id
    }
  }
`;

export const PRO_DC_GET_STATUS = gql`
  query ProDCGetStatus($prodc_id: String) {
    proDCGetStatus(prodc_id: $prodc_id) {
      id
      update_status
      updated_at
    }
  }
`;

export const PRO_DC_GET_LOCATION_AND_MEMBERS = gql`
  query ProDCLocationAndMemberSearch($dc_name: String) {
    proDCLocationAndMemberSearch(dc_name: $dc_name) {
      location_info {
        id
        name
        description
        image
        demo_mode
        created_at
        userview_count
      }
      member_info {
        id
        created_by
        project_id
        user_id
        user_email
        user_title
        access_level
        permission_status
        permission_enabled
        invite_to_userviews
        notifications
        last_updated
        created_at
        updated_at
      }
    }
  }
`;
