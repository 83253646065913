import React, { useContext, useEffect } from 'react';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Link,
  ListSubheader,
  Typography,
} from '@mui/material';
import {
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  User as UserIcon,
  Users as UsersIcon,
  Bookmark as BookMarkIcon,
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@mui/styles';
import { ApolloAuthContext } from 'src/store/Apollo/ApolloContext';
import { AuthContext } from 'src/store/Auth/AuthContext';
import { AppUtils } from 'src/utility/appUtils';
import {
  FiBookmark,
  FiCopy,
  FiFileText,
  FiGrid,
  FiInbox,
  FiRotateCcw,
  FiTag,
  FiUser,
  FiSearch,
  FiBarChart2,
  FiLayers
} from 'react-icons/fi';
import { APP_TEXT } from 'src/utility/AppTexts/DefaultAppText.utils';
import NavItem, { TabNavItem } from './NavItem';

export enum NAV_LINK_VISIBILITY_LEVEL {
  ALL = 'all',
  SUPER_ADMIN = 'super_admin',
}

const sections = [
  {
    subheader: '',
    items: [
      {
        href: '/app/project/dashboard',
        icon: FiGrid,
        title: APP_TEXT.sidebar.DASHBOARD,
        withProjectId: true,
        visibilityLevel: NAV_LINK_VISIBILITY_LEVEL.ALL,
      },
      {
        href: '/app/energy',
        icon: FiBarChart2,
        title: APP_TEXT.sidebar.ENERRGY,
        withProjectId: true,
        visibilityLevel: NAV_LINK_VISIBILITY_LEVEL.ALL,
      },
      {
        href: '/app/device-containers',
        icon: FiInbox,
        title: APP_TEXT.sidebar.DEVICE_CONTAINERS,
        withProjectId: true,
        visibilityLevel: NAV_LINK_VISIBILITY_LEVEL.ALL,
      },
      {
        href: '/app/members',
        icon: FiUser,
        title: APP_TEXT.sidebar.MEMBERS,
        withProjectId: false,
        visibilityLevel: NAV_LINK_VISIBILITY_LEVEL.ALL,
      },
      {
        href: '/app/user-views',
        icon: FiCopy,
        title: APP_TEXT.sidebar.USER_VIEWS,
        withProjectId: true,
        visibilityLevel: NAV_LINK_VISIBILITY_LEVEL.ALL,
      },
      {
        href: '/app/item-groups',
        icon: FiLayers,
        title: APP_TEXT.sidebar.USERVIEW_ITEM_GROUPS,
        withProjectId: false,
        visibilityLevel: NAV_LINK_VISIBILITY_LEVEL.ALL,
      },
      {
        href: '/app/logics',
        icon: FiBookmark,
        title: APP_TEXT.sidebar.LOGICS,
        withProjectId: true,
        visibilityLevel: NAV_LINK_VISIBILITY_LEVEL.ALL,
      },
      {
        href: '/app/backup-restore',
        icon: FiRotateCcw,
        title: APP_TEXT.sidebar.BACKUP_RESTORE,
        withProjectId: true,
        visibilityLevel: NAV_LINK_VISIBILITY_LEVEL.ALL,
      },
      {
        href: '/app/list',
        icon: FiBookmark,
        title: APP_TEXT.sidebar.LISTS,
        withProjectId: true,
        visibilityLevel: NAV_LINK_VISIBILITY_LEVEL.ALL,
      },
      {
        href: '/app/tags',
        icon: FiTag,
        title: APP_TEXT.sidebar.TAGS,
        withProjectId: true,
        visibilityLevel: NAV_LINK_VISIBILITY_LEVEL.ALL,
      },
      {
        href: '/app/logs',
        icon: FiFileText,
        title: APP_TEXT.sidebar.LOGS,
        withProjectId: true,
        visibilityLevel: NAV_LINK_VISIBILITY_LEVEL.ALL,
      },
      {
        href: '/app/hub-finder',
        icon: FiFileText,
        title: APP_TEXT.sidebar.HUB_FINDER,
        withProjectId: true,
        visibilityLevel: NAV_LINK_VISIBILITY_LEVEL.ALL,
      },
      {
        href: '/app/hub-stats',
        icon: FiFileText,
        title: APP_TEXT.sidebar.HUB_STATS,
        withProjectId: false,
        visibilityLevel: NAV_LINK_VISIBILITY_LEVEL.SUPER_ADMIN,
      },

    ],
  },
];

function renderNavItems({
  items, pathname, depth = 0, tab = false,
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({
          acc,
          item,
          pathname,
          depth,
          tab,
        }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc, pathname, item, depth, tab,
}) {
  const key = item.title + depth;

  if (tab) {
    acc.push(
      <TabNavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        withProjectId={item.withProjectId}
      />,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        withProjectId={item.withProjectId}
        visibilityLevel={item.visibilityLevel}
      />,
    );
  }
  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  tabDrawer: {
    width: 50,
    height: 'calc(100%)',
    position: 'absolute',
    left: 0,
    borderRadius: '10px',
    overflowX: 'hidden',
  },
  desktopDrawer: {
    width: 236,
    top: 0,
    // marginLeft: '10%',
    // left: 'calc(100% - 88.7%)',
    height: 'calc(100%)',
    position: 'absolute',
    left: 0,
    borderRadius: '10px',
    // [theme.breakpoints.down('lg')]: {
    //   width: 80,
    // },
    // [theme.breakpoints.down('md')]: {
    //   display: 'none',
    // },
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const user = useContext(AuthContext);
  const userProfile = useContext(ApolloAuthContext);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  const tabContent = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box>
          {sections.map((section) => (
            <List
              key={section.subheader}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
                depth: 0,
                tab: true,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden smUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden smDown lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.tabDrawer }}
          onClose={onMobileClose}
          open
          variant="persistent"
        >
          {tabContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
