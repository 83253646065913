/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
import {
  Alert,
  Box,
  Button,
  Typography,
  AlertTitle,
  Input,
  InputAdornment,
  FormControl,
  InputLabel,
  TextField,
  Select,
  Skeleton,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import axios from 'axios';
import React, {useEffect, useState, useContext, useMemo} from 'react';
import PercentIcon from '@mui/icons-material/Percent';
import {useDispatch, useSelector} from 'react-redux';
import {CofirmDialog} from 'src/containers/app/LogicsEngine/components/ui/Confirm';
import {AuthContext} from 'src/store/Auth/AuthContext';
import {RootState} from 'src/store/redux/store';
import {useLocation, useNavigate} from 'react-router';
import findAndReplaceQueryString from 'src/utility/findAndReplaceUrlQuery';
import {v2InfoUpdate} from 'src/store/redux/features/backup-location-config';
import {getProjectMembersGraphQL} from 'src/services/member/member.service';
import {ApolloAuthContext} from 'src/store/Apollo/ApolloContext';
import {ProjectContext} from 'src/store/Project/ProjectContext';
import {isArray, set} from 'lodash';
import {useSnackbar} from 'notistack';
import NodeRed from '../shared/NodeRed';
import {CloneSuccessRateContext} from '../shared/context/cloneSuccessRateContext';
import {RestoreLogTerminal} from '../shared/Terminal';
import {ContainerMapper} from '../shared/ContainerMapper';
import {UserviewDirectoryExplorar} from 'src/containers/app/UserViews/SingleUserView/UserviewExplorar';
import {userviewSearchGraphQL} from 'src/services/user-view/user-view.service';
import {IconTextCardComponent} from 'src/components/shared/IconTextCard/IconTextCard';

const UserviewList: React.FC<{userviews; loading; onSelect: (uv: any) => void}> = ({
  onSelect,
  userviews,
  loading,
}) => {
  console.log('zzz', userviews);

  if (loading) {
    return <Skeleton variant="rectangular" height={200} />;
  }

  return (
    <Grid container spacing={1}>
      {userviews.map((uv: any) => (
        <Grid item md={3} sm={6} xs={12} key={uv.id}>
          <IconTextCardComponent
            uvId={uv.id}
            title={uv.name ? uv.name : 'N/A'}
            imgBgStyle=""
            iconClass={uv.icon_name}
            itemColor={uv.item_color}
            onClick={() => {
              onSelect(uv);
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const RestoreUserviewFolderConfig: React.FC<{
  onFolderSelect: (folder: any) => void;
  onUserviewSelect: (userview: any) => void;
}> = ({onFolderSelect, onUserviewSelect}) => {
  const ROOT_FOLDER = 'ROOT_FOLDER';
  const NEW_USRVIEW = 'NEW_USERVIEW';
  const [selectedUserview, setSelectedUserview] = useState(NEW_USRVIEW);
  const [selectedFolder, setSelectedFolder] = useState(ROOT_FOLDER);
  const [selectedFolderData, setSelectedFolderData] = useState(null);

  const [openFolderSelector, setOpenFolderSelector] = useState(false);
  const [openUserviewSelector, setOpenUserviewSelector] = useState(false);

  const {enqueueSnackbar} = useSnackbar();
  const userProfile = useContext(ApolloAuthContext);
  const selectedProject = useContext(ProjectContext);
  const [userviews, setUserviews] = useState([]);
  const [loading, setLoading] = useState(false);

  const selectuserviewData = useMemo(() => {
    return userviews.find(uv => uv.id === selectedUserview);
  }, [userviews, selectedUserview]);

  useEffect(() => {
    if (!selectedProject.selected_project.id) {
      return;
    }
    async function init() {
      try {
        setLoading(true);
        const res = await userviewSearchGraphQL(
          userProfile?.apollo_client,
          selectedProject.selected_project.id,
          1,
          '',
          100,
          0
        );

        setUserviews(res.userviewSearch.result ? res.userviewSearch.result : 0);

        //await getLatestOrder(res.userviewSearch.result);
      } catch (err: any) {
        enqueueSnackbar(err.message, {variant: 'error'});
      } finally {
        setLoading(false);
      }
    }

    init();
  }, [selectedProject, userProfile]);

  return (
    <Box style={{border: '1px solid gray', borderRadius: 5, padding: 10}}>
      <Typography style={{fontWeight: 700}}>Userview Folder Config</Typography>
      <Box height={10} />
      <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
        <Box style={{width: '100%', display: 'flex', alignItems: 'center'}}>
          <Typography style={{width: 100}}>Userview: </Typography>
          <Box width={10} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setOpenUserviewSelector(true);
            }}
          >
            {selectuserviewData ? selectuserviewData.name : 'Select Existing'}
          </Button>
          <Box width={10} />
          <Typography>or</Typography>
          <Box width={10} />
          <FormControlLabel
            label="Create as New"
            control={
              <Checkbox
                checked={selectedUserview === NEW_USRVIEW}
                onChange={e => {
                  setSelectedUserview(e.target.checked ? NEW_USRVIEW : null);
                }}
              />
            }
          />
        </Box>
        <Box style={{width: '100%', display: 'flex', alignItems: 'center'}}>
          <Typography style={{width: 100}}>Folder:</Typography>
          <Box width={10} />
          <Button
            disabled={selectedUserview === NEW_USRVIEW}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setOpenFolderSelector(true);
            }}
          >
            {selectedFolderData ? selectedFolderData.display_name : 'Select a Folder'}
          </Button>
          <Box width={10} />
          <Typography>or</Typography>
          <Box width={10} />
          <FormControlLabel
            label="Pick Root Folder"
            control={
              <Checkbox
                disabled={selectedUserview === NEW_USRVIEW}
                checked={selectedFolder === ROOT_FOLDER}
                onChange={e => {
                  setSelectedFolder(e.target.checked ? ROOT_FOLDER : null);
                }}
              />
            }
          />
        </Box>
      </Box>
      <Dialog open={openFolderSelector} maxWidth="sm" fullWidth>
        <DialogTitle style={{display: 'flex', alignItems: 'center'}}>
          <AccountTreeIcon />
          <Typography style={{marginLeft: 5, fontWeight: 500}}>Userview Directory</Typography>
        </DialogTitle>
        <DialogContent>
          <UserviewDirectoryExplorar
            userviewId={selectedUserview}
            onClick={folder => {
              setSelectedFolder(folder.id);
              setSelectedFolderData(folder);
              setOpenFolderSelector(false);
              onFolderSelect(folder);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFolderSelector(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openUserviewSelector} maxWidth="lg" fullWidth>
        <DialogTitle style={{display: 'flex', backgroundColor: '#ebf1f2', alignItems: 'center'}}>
          <AccountTreeIcon />
          <Typography style={{marginLeft: 5, fontWeight: 500}}>Userview Selector</Typography>
        </DialogTitle>
        <DialogContent style={{backgroundColor: '#ebf1f2'}}>
          <UserviewList
            loading={loading}
            userviews={userviews}
            onSelect={uv => {
              setSelectedUserview(uv.id);
              setOpenUserviewSelector(false);
              onUserviewSelect(uv);
            }}
          />
        </DialogContent>
        <DialogActions style={{backgroundColor: '#ebf1f2'}}>
          <Button onClick={() => setOpenUserviewSelector(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const SessionRestoreFinishUp: React.FC<{
  sessionId: string;
  onFolderSelect?: (folder: any) => void;
  onUserviewSelect?: (userview: any) => void;
}> = ({sessionId, onFolderSelect, onUserviewSelect}) => {
  const dispatch = useDispatch();
  const auth = useContext(AuthContext);
  const userProfile = useContext(ApolloAuthContext);
  const selectedProject = useContext(ProjectContext);
  const navigate = useNavigate();
  const successRatio = useContext(CloneSuccessRateContext);
  const hidden = useSelector((state: RootState) => state.backup_location_config.info.v2.hidden);
  const [disableDevices, setDisableDevices] = useState<'lvar' | 'prod' | 'aird'>(null);

  const [working, setWorking] = useState<'lvar' | 'prod' | 'aird'>(null);

  const [locVarInfo, setLocVarInfo] = useState<any>({});
  const [proDevInfo, setProDevInfo] = useState<any>({});
  const [airDevInfo, setAirDevInfo] = useState<any>({});

  const locVarPendingComp = useSelector(
    (state: RootState) => state.backup_location_config.info.v2.lvar
  );
  const proDevicePendingComp = useSelector(
    (state: RootState) => state.backup_location_config.info.v2.prodc
  );
  const airDevicePendingComp = useSelector(
    (state: RootState) => state.backup_location_config.info.v2.airdc
  );
  const hiddenDevices = useSelector(
    (state: RootState) => state.backup_location_config.info.v2.hidden
  );

  const [activeTaskUid, setActiveTaskUid] = useState<string>(null);
  const [logPath, setLogPath] = useState({
    lvar: '',
    prod: '',
    aird: '',
  });

  const [finishedAlerts, setFinishedAlerts] = useState({
    lvar: false,
    prod: false,
    aird: false,
  });

  const {enqueueSnackbar} = useSnackbar();

  const [isFolderRestore, setIsFolderRestore] = useState(false);

  useEffect(() => {
    fetchSession();
    fetchUvLvSession();
  }, []);

  useEffect(() => {
    if (!locVarPendingComp) return;
    if (locVarPendingComp && !locVarPendingComp.pendingComps) return;
    const payload = {};
    Object.keys(locVarPendingComp.pendingComps).map(dcId => {
      const container = locVarPendingComp.pendingComps[dcId];

      Object.keys(container).map(deviceKey => {
        if (hidden && hidden[dcId] && hidden[dcId][deviceKey]) {
          if (!payload[dcId]) payload[dcId] = {};
          payload[dcId][deviceKey] = {
            new_id: '',
            clone_type: 'disable',
          };
        }
      });
    });

    setLocVarInfo(payload);
  }, [hidden, locVarPendingComp]);

  useEffect(() => {
    if (!proDevicePendingComp) return;
    if (proDevicePendingComp && !proDevicePendingComp.pendingComps) return;
    const payload = {};
    Object.keys(proDevicePendingComp.pendingComps).map(dcId => {
      const container = proDevicePendingComp.pendingComps[dcId];

      Object.keys(container).map(deviceKey => {
        if (hidden && hidden[dcId] && hidden[dcId][deviceKey]) {
          if (!payload[dcId]) payload[dcId] = {};
          payload[dcId][deviceKey] = {
            new_id: '',
            clone_type: 'disable',
          };
        }
      });
    });

    setProDevInfo(payload);
  }, [hidden, proDevicePendingComp]);

  useEffect(() => {
    if (!airDevicePendingComp) return;
    if (airDevicePendingComp && !airDevicePendingComp.pendingComps) return;
    const payload = {};
    Object.keys(airDevicePendingComp.pendingComps).map(dcId => {
      const container = airDevicePendingComp.pendingComps[dcId];

      Object.keys(container).map(deviceKey => {
        if (hidden && hidden[dcId] && hidden[dcId][deviceKey]) {
          if (!payload[dcId]) payload[dcId] = {};
          payload[dcId][deviceKey] = {
            new_id: '',
            clone_type: 'disable',
          };
        }
      });
    });

    setAirDevInfo(payload);
  }, [hidden, airDevicePendingComp]);

  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const res = await getProjectMembersGraphQL(
          userProfile.apollo_client,
          selectedProject.selected_project.id,
          -1,
          -1
        );
        if (!res.projectListUsers) throw new Error('Failed to get users');
        setAllUsers(res.projectListUsers.users);
        if (isArray(res.projectListUsers.users)) {
          successRatio.dispatch({
            type: 'ALEXA_USER',
            payload: res.projectListUsers.users[0],
          });
        }
      } catch (err: any) {
        enqueueSnackbar(err.message, {variant: 'error'});
      } finally {
        setLoading(false);
      }
    };

    if (
      selectedProject &&
      selectedProject.selected_project &&
      selectedProject.selected_project.id
    ) {
      init();
    } else {
      // TODO: Close the dialog or move backward
    }
  }, []);

  const disableUnUsedDevices = async (type: 'lvar' | 'prod' | 'aird') => {
    try {
      setWorking(type);
      setDisableDevices(null);
      const response = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/backups/v2/restore/clone/task/run`,
        {
          session_id: sessionId,
          type,
          info_json: type === 'lvar' ? locVarInfo : type === 'prod' ? proDevInfo : airDevInfo,
          info_state: JSON.stringify({
            hidden: hiddenDevices,
          }),
        },
        {
          headers: {
            Authorization: `Bearer ${auth.access_token.token}`,
          },
        }
      );
      if (response.data && response.data.result) {
        // console.log(response.data.result, type, response.data.result.log_ref);
        // console.log({
        //   ...logPath,
        //   [type]: response.data.result.log_ref,
        // });
        // console.log(response.data.result.task_ui);
        setLogPath({...logPath, [type]: response.data.result.log_ref});
        setActiveTaskUid(response.data.result.task_uid);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUvLvSession = async () => {
    try {
      const result = await fetchSession('lv');
      dispatch(
        v2InfoUpdate({
          value: result,
          key: 'lv',
          mergeErrorComps: true,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  // eslint-disable-next-line consistent-return
  const fetchSession = async (type = 'uv', populated = true) => {
    const url = '/rest/backups/v2/restore/get-session-progress';
    try {
      const response = await axios.post(
        process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0] + url,
        {
          session_id: sessionId,
          task_type: type,
          with_comp_map: populated,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.access_token.token}`,
          },
        }
      );
      if (response.data && response.data.result) {
        // return response.data.result;
        if (response.data.result.comp_group_info) {
          setIsFolderRestore(true);
        }
        return response.data.result;
      }
    } catch (err: any) {
      // enqueueSnackbar(err.message, { variant: 'error' });
    }
  };
  const location = useLocation();

  const selectAlexaUserChange = event => {
    const user = allUsers.find(item => item.id === event.target.value);
    successRatio.dispatch({
      type: 'ALEXA_USER',
      payload: user,
    });
  };

  return (
    <Box>
      {locVarInfo && Object.keys(locVarInfo).length > 0 && (
        <Box style={{marginBottom: 10}}>
          <Alert
            severity="warning"
            action={
              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={() => setDisableDevices('lvar')}
                disabled={working != null}
              >
                {working === 'lvar' ? 'Processing...' : 'Ok'}
              </Button>
            }
          >
            <AlertTitle>Hold On! Un Used Location Variables</AlertTitle>
            There are some location variables left you did not used in previous steps. If you do not
            wish to use them anymore, please ignore them by clicking this button. Otherwise make
            sure you have configured all the location variables in previous steps.
            <RestoreLogTerminal
              taskId={activeTaskUid}
              logPath={logPath.lvar}
              onEnd={async () => {
                try {
                  const result = await fetchSession('lvar', false);
                  if (result && result.donePercentage === 100) {
                    setFinishedAlerts({
                      ...finishedAlerts,
                      lvar: true,
                    });
                  }
                  const url = findAndReplaceQueryString(
                    location.pathname,
                    location.search,
                    'disabled-lvar',
                    Date.now()
                  );
                  navigate(url);
                } catch (err) {
                  console.log(err);
                } finally {
                  setWorking(null);
                }
              }}
              onError={err => {
                console.log(err);
              }}
              sessionId={sessionId}
            />
          </Alert>
        </Box>
      )}
      {proDevInfo && Object.keys(proDevInfo).length > 0 && (
        <Box style={{marginBottom: 10}}>
          <Alert
            severity="warning"
            action={
              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={() => setDisableDevices('prod')}
                disabled={finishedAlerts.prod || working != null}
              >
                {working === 'prod' ? 'Processing...' : 'Ok'}
              </Button>
            }
          >
            <AlertTitle>Hold On! Un Used Connect Devices</AlertTitle>
            There are some connect devices left you did not used in previous steps. If you do not
            wish to use them anymore, please ignore them by clicking this button. Otherwise make
            sure you have configured all the connect devices in previous steps.
            <RestoreLogTerminal
              taskId={activeTaskUid}
              logPath={logPath.prod}
              onEnd={async () => {
                try {
                  const result = await fetchSession('prod', false);
                  if (result && result.donePercentage === 100) {
                    setFinishedAlerts({
                      ...finishedAlerts,
                      prod: true,
                    });
                  }
                  const url = findAndReplaceQueryString(
                    location.pathname,
                    location.search,
                    'disabled-prod',
                    Date.now()
                  );
                  navigate(url);
                } catch (err) {
                  console.log(err);
                } finally {
                  setWorking(null);
                }
              }}
              onError={err => {
                console.log(err);
              }}
              sessionId={sessionId}
            />
          </Alert>
        </Box>
      )}
      {airDevInfo && Object.keys(airDevInfo).length > 0 && (
        <Box style={{marginBottom: 10}}>
          <Alert
            severity="warning"
            action={
              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={() => setDisableDevices('aird')}
                disabled={finishedAlerts.aird || working != null}
              >
                {working === 'aird' ? 'Procesing...' : 'Ok'}
              </Button>
            }
          >
            <AlertTitle>Hold On! Un Used Air Devices</AlertTitle>
            There are some air devices left you did not use in previous steps. If you do not wish to
            use them anymore, please ignore them by clicking this button. Otherwise, make sure you
            have configured all the air devices in the previous steps.
            <RestoreLogTerminal
              taskId={activeTaskUid}
              logPath={logPath.aird}
              onEnd={async () => {
                try {
                  const result = await fetchSession('aird', false);

                  if (result && result.donePercentage === 100) {
                    setFinishedAlerts({
                      ...finishedAlerts,
                      aird: true,
                    });
                  }
                  const url = findAndReplaceQueryString(
                    location.pathname,
                    location.search,
                    'disabled-aird',
                    Date.now()
                  );
                  navigate(url);
                } catch (err) {
                  console.log(err);
                } finally {
                  setWorking(null);
                }
              }}
              onError={err => {
                console.log(err);
              }}
              sessionId={sessionId}
            />
          </Alert>
        </Box>
      )}
      <Typography>Clone logics and user views and finish up the restoring.</Typography>
      <Box height={50} />
      <Box style={{border: '1px solid gray', borderRadius: 5, padding: 10}}>
        <Typography style={{fontWeight: 700}}>Additional Settings</Typography>
        <Box height={10} />
        <Box style={{display: 'flex', justifyContent: 'flex-start'}}>
          <TextField
            style={{width: '40%'}}
            id="input-with-icon-textfield"
            label="Minimum Logic clone success rate"
            value={successRatio.lgc_rate}
            type="number"
            error={successRatio.lgc_rate < 0}
            onChange={e =>
              successRatio.dispatch({
                type: 'UPDATE',
                payload: {
                  lgc_rate: e.target.value,
                },
              })
            }
            size="small"
            InputProps={{
              inputProps: {min: 0, max: 100},
              endAdornment: (
                <InputAdornment position="end">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
          <Box width={10} />
          <TextField
            id="input-with-icon-textfield"
            label="Minimum user view item clone success rate"
            style={{width: '40%'}}
            value={successRatio.uvi_rate}
            size="small"
            type="number"
            error={successRatio.uvi_rate < 0}
            onChange={e =>
              successRatio.dispatch({
                type: 'UPDATE',
                payload: {
                  uvi_rate: e.target.value,
                },
              })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </Box>
        <Box height={15} />
        <Box style={{display: 'flex', justifyContent: 'flex-start'}}>
          <FormControl style={{width: '40%'}} variant="outlined" size="small">
            <InputLabel>Default Alexa Account</InputLabel>
            <Select
              value={successRatio.default_alexa_user?.id}
              label="Default Alexa Account"
              onChange={selectAlexaUserChange}
              size="small"
              // input={<Input />}
              variant="outlined"
              disableUnderline
            >
              {isLoading ? (
                <Skeleton variant="rectangular" height={50} />
              ) : (
                allUsers.map(uv => (
                  <MenuItem key={uv.id} value={uv.id}>
                    {uv.user_name} -{uv.user_email}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
          <Box width={10} />
          <FormControl style={{width: '40%'}} variant="outlined" size="small">
            <TextField
              value={successRatio.default_email}
              label="Default Email Address"
              onChange={e => {
                successRatio.dispatch({
                  type: 'LOGIC_EMAIL',
                  payload: e.target.value,
                });
              }}
              variant="outlined"
              size="small"
              type="email"
            />
          </FormControl>
        </Box>
        <Box height={10} />
        <ContainerMapper
          sessionId={sessionId}
          onDcMap={dcMap => {
            successRatio.dispatch({
              type: 'DC_MAP_UPDATE',
              payload: dcMap,
            });
          }}
        />
        {/* <NodeRed sessionId={sessionId} /> */}
      </Box>
      <Box height={10} />
      {isFolderRestore && (
        <RestoreUserviewFolderConfig
          onFolderSelect={folder => {
            if (onFolderSelect) {
              onFolderSelect(folder);
            }
          }}
          onUserviewSelect={uv => {
            if (onUserviewSelect) {
              onUserviewSelect(uv);
            }
          }}
        />
      )}
      {/* <Box style={{ border: '1px solid gray', borderRadius: 5, padding: 10 }}>
        <Typography>NodeRed Configration</Typography>
        <NodeRed />
      </Box> */}
      <CofirmDialog
        dialogOpen={disableDevices != null}
        dialogCloseEvent={(e: any) => {
          if (e === true) {
            disableUnUsedDevices(disableDevices);
          } else {
            setDisableDevices(null);
          }
        }}
        title="Confirm to disable devices ?"
        text="It will disable all the unused devices/loc variable. There will be no undo operation!"
      />
    </Box>
  );
};

export default SessionRestoreFinishUp;
